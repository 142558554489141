import {App} from 'template';

import Setting from './view';

const Index = () => {
  return (
    <App>
      <Setting />
    </App>
  );
};

export default Index;
