/**
 * @name Text
 * @description Text
 */
import styled from 'styled-components';
import {TextType} from './type';

const Text: React.FC<TextType> = ({
  mode,
  className,
  children,
  color,
  font,
  size,
  label,
  align,
  weight,
  style
}: TextType): JSX.Element => {
  return (
    <Element
      className={`${className} ${mode}`}
      style={style}
      font={font}
      align={align}
      size={size}
      color={color}
      weight={weight}>
      {!!label && label}
      {children}
    </Element>
  );
};
export default Text;

const Element = styled.span<TextType>`
  font-size: ${({size}) => (typeof size === 'number' ? `${String(size)}px` : size || 'initial')};
  font-weight: ${({weight}) => weight || 'normal'};
  text-align: ${({align}) => align || 'left'};
  color: ${({color}) => color};
`;
/********************************************************
[사용법]

<Text size="30px" label={`width : ${width}`} align="right" />
<Text size="14px" weight={400} color={COLOR.GRAY1} label={`width : ${width}`} align="left" style={{padding: 20}} />

<Text style={{whiteSpace: 'pre-wrap'}} /> \n 줄바꿈가능하게
*********************************************************/
