/**
 * @name 전압현황
 * @description
 */
import {Echarts, Flex} from 'components';
import React, {useEffect, useState} from 'react';
import {Color} from 'styles';
import {Card, ChartLegend, EchartsDefaultOption, ValueUnitList} from '../common';
import {blueGrayRedYellowGreen, grayBlueGreen, blueGreenGrayGreen} from 'styles/color';
import {SeriesOption, YAXisComponentOption} from 'echarts';
import {nanoid} from 'nanoid';
import {TemplateEnum} from 'lib/enum/TemplateEnum';
import {Props} from '../template_factory';
import {useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';
import {Volume} from '../common/volume';
import {useSst, useUserMe} from 'context';
import {
  sstChartSeries1,
  sstChartSeries2,
  sstChartSeries3,
  sstValue1,
  sstValue2,
  sstValue3
} from 'pages/dashboard/constant/temp';

type ColorType = {
  chartColor?: string[];
  labelColor?: string[];
};

const Contents = ({item, chartColor = blueGrayRedYellowGreen, labelColor = blueGreenGrayGreen}: Props & ColorType) => {
  const DEFAULT = 0;
  const {isLoading, data: card, valueUnit, chart} = useCardsCardNoQuery(item?.cardNo);
  const [options, setOptions] = useState<echarts.EChartsOption>(EchartsDefaultOption({}));
  const [labelSize, setLabelSize] = useState<number>(DEFAULT);
  const MAX_PERCENT = 100;
  const mainUnit = item?.items[DEFAULT].unit;
  const STANDARD_MAX = '95';
  const STANDARD_MIN = '90';

  // 임시용
  const [isSst, setIsSst] = useState<boolean>(false);
  const {data} = useUserMe();
  const {sst} = useSst();

  useEffect(() => {
    if (card && !isLoading) {
      // 임시
      data?.siteId === 'SST' ? setIsSst(true) : setIsSst(false);

      setLabelSize(card.filter((c) => !(c?.data[0] as {name: string; data: string[]})?.data).length);
      const series = card
        .map((c, idx) => {
          const data = c?.data[DEFAULT] as {name: string; data: string[]};
          // 차트를 셋하고 라벨을 셋해줌
          if (data?.data) {
            // 차트
            const series = {
              name: c.name,
              color: chartColor[idx] ?? Color.black
            };
            /**
             * 범례
             * zustand에 set을 해야 함
             */
            return {
              ...series,
              data: data.data,
              type: 'line',
              showSymbol: false
            };
          }
          return null;
        })
        .filter((d) => d);
      /**
       * 역률현황 템플릿 하나 때문에 컴포넌트를 따로 만들지 않고 분기처리 했습니디.
       */
      if (item?.templateName === TemplateEnum.BASELINE_1_1_1) {
        const length = (card[0].data[0] as {name: string; data: string[]}).data.length;
        series.push(
          {
            name: '',
            data: Array.from({length}, () => STANDARD_MIN),
            type: 'line',
            color: Color.yellow30,
            showSymbol: false
          },
          {
            name: '',
            data: Array.from({length}, () => STANDARD_MAX),
            type: 'line',
            color: Color.yellow30,
            showSymbol: false
          }
        );
      }
      const yAxis =
        mainUnit === '%'
          ? {
              type: 'value',
              max: MAX_PERCENT, // 최대값 설정
              name: mainUnit
            }
          : mainUnit === 'TAP'
            ? {
                name: mainUnit,
                min: DEFAULT
              }
            : {name: mainUnit};
      setOptions((prevOptions) => ({
        ...prevOptions,
        yAxis: yAxis as YAXisComponentOption,
        series: (isSst
          ? sst === 'SST1'
            ? sstChartSeries1
            : sst === 'SST2'
              ? sstChartSeries2
              : sstChartSeries3
          : series) as SeriesOption
      }));
    }
  }, [card, isLoading, sst, isSst]);

  return !isLoading && card ? (
    <Card
      title={item?.title}
      topRight={<Volume valueUnit={valueUnit?.filter((item) => item.label === '설비용량')[0]} />}>
      {/*======================== 차트영역 */}
      {options && <Echarts options={options} width={'100%'} height={'150px'} />}
      {/*======================== 하단타이틀 */}
      <ValueUnitList
        items={
          isSst
            ? sst === 'SST1'
              ? sstValue1
              : sst === 'SST2'
                ? sstValue2
                : sstValue3
            : valueUnit?.filter((item) => item.label !== '설비용량')
        }
        colors={item?.title.includes('SST') ? grayBlueGreen : labelColor}
      />
      {/*========================  범례 */}
      <Flex.Center>
        {card
          .filter((c) => (c?.data[0] as {name: string; data: string[]})?.data)
          .map((c, idx) => {
            return <ChartLegend key={nanoid()} color={chartColor[idx]} title={c?.name} style={{marginRight: 20}} />;
          })}
      </Flex.Center>
    </Card>
  ) : (
    <React.Fragment />
  );
};

export default Contents;
