/*
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
  날씨
  
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
*/
import 'dayjs/locale/ko';
import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
// index
import {Color} from 'styles';
import {Flex, Text} from 'components';
import {sumiTitle} from 'assets/images';
import {CardShadow} from 'pages/energy/common';
import {WEATHER_IMAGE, WEATHER_TEXT} from './weather-data';
import dayjs from 'lib/events/dayjs';
import {Dayjs} from 'lib/events';
import {useEnergyQuery} from 'pages/energy/hooks';
import {NoData} from 'pages/energy/common/card/noData';

const Weather: React.FC = () => {
  const {weather} = useEnergyQuery();
  Dayjs.locale('ko');
  const format = 'YYYY년 MM월 DD일 (ddd) HH시 mm분';
  const [time, setTime] = useState(dayjs().format(format));

  useEffect(() => {
    // 남은 분 이후로 1분마다 반복
    const timeout = setTimeout(
      () => {
        setInterval(() => {
          setTime(dayjs().format(format));
        }, 60000); // 1분마다 업데이트
      },
      (60 - dayjs().second()) * 1000
    );

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <CardShadow>
      <Flex.Column style={{color: Color.white, backgroundColor: '#273863', padding: 33, height: '100%'}}>
        <img style={{width: 156, marginBottom: 10}} src={sumiTitle} alt="날씨" />
        <Text mode="Title_MB" label={time} />
        {/* 가로바 */}
        <Line />
        {weather?.weather ? (
          <Flex.Center gap={10}>
            <img src={WEATHER_IMAGE[weather.weather[0].icon]} height={48} width={48} />
            <Text label={`${weather.main.temp.toFixed(1)}°`} mode="Headline_LB" />
            <Text label={WEATHER_TEXT[weather.weather[0].icon]} size={22} weight={700} style={{marginLeft: 10}} />
          </Flex.Center>
        ) : (
          <NoData color={Color.white} />
        )}
      </Flex.Column>
    </CardShadow>
  );
};

const Line = styled.div`
  width: 270px;
  height: 1px;
  margin-top: 26px;
  margin-bottom: 26px;
  background: rgba(255, 255, 255, 0.1);
`;

export default Weather;
