/**
 * @name HOME
 * @description
 */

import React, { useEffect } from "react";
import { MENU } from "config";
import { useRoutes } from "lib/hooks";
import { App } from "template";

const Index: React.FC = () => {
  const { navigate: routeNavigate } = useRoutes();

  useEffect(() => {
    routeNavigate(MENU.SIGN_IN);
  }, [routeNavigate]);

  return (
    <App>
      {JSON.stringify(MENU, null, 2)}
      <button
        onClick={() => {
          routeNavigate(MENU.SIGN_IN);
        }}
      >
        test
      </button>
    </App>
  );
};

export default Index;
