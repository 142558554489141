/**
 * @name Button
 * @description 버튼
 * @todo icon은 업데이트필요.
 */
import styled from 'styled-components';
import {ButtonProps} from './type';
// components
import ButtonImage from './button-image';
import {IconImage, sizeStyles, themeStyles} from './style.button';
import {Fonts} from 'styles/color';

const Button = ({
  style,
  className,
  src,
  srcStyle,
  icon,
  label,
  child,
  children,
  width,
  ...props
}: ButtonProps): JSX.Element => {
  return (
    <Element width={width} style={style} className={className} {...props}>
      {/*======================= 왼쪽아이콘일때 */}
      {icon === 'Left' && src && <IconImage style={srcStyle} icon={icon} src={src} />}
      {!icon && src && <img src={src} style={srcStyle} alt="button" />}
      {!icon && child && child}
      {icon !== 'Only' && label && label}
      {/*======================= 왼쪽아이콘일때 */}
      {icon === 'Right' && src && <IconImage style={srcStyle} icon={icon} src={src} />}
      {icon === 'Only' && <IconImage style={srcStyle} icon={icon} src={src} />}
      {children || children}
    </Element>
  );
};

export default Button;
Button.Image = ButtonImage;

const Element = styled.button<ButtonProps>`
  width: ${({width}) => (typeof width === 'number' ? `${width}%` : width || 'auto')};
  border: none;
  border-radius: 6px;
  font-family: ${Fonts.Main};
  ${sizeStyles}
  ${themeStyles}
`;

/********************************************************
[사용법]

  <Button
    child={<SVG src={logo_acelo_cvr} />}
    onClick={() => {
      alert('222');
    }}
  />

  <Button
    style={{width: '100%', marginBottom: 24}}
    theme="Primary"
    type="Text"
    size="Large"
    label="Filled 시작하기"
  />
  <Button style={{width: '100%', marginBottom: 24}} theme="Danger" type="Outline" size="Large" label="Outline" />
  <Button
    disabled
    style={{width: '100%', marginBottom: 24}}
    theme="Danger"
    type="Text"
    size="Large"
    label="disabled"
  />
*********************************************************/
