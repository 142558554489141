/**
 * @name Header
 * @description 로케이션, 유틸리티(날씨,시간)
 */
import styled from 'styled-components';
import {Flex} from 'components';
import {Color} from 'styles';
// contents
import Location from './location';
import Util from './util';
import Time from './util-time';
import Reload from './util-reload';
import Sst from './sst';
import {useAuthStore} from 'context';

const Header: React.FC = () => {
  return (
    <Content>
      <Flex.SpaceBetween width="100%">
        <Flex.Start>
          {/* 로케이션 */}
          <Location />
          {/* 현재시간 */}
          <Time />
          {/* 새로고침 */}
          <Reload />
        </Flex.Start>
        {/* 로케이션 */}
        <Flex.End>
          <Sst />
          <Util />
        </Flex.End>
      </Flex.SpaceBetween>
    </Content>
  );
};
export default Header;

const Content = styled.header`
  display: flex;
  width: 100%;
  height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid ${Color.blue90};
  background-color: ${Color.white};
`;
