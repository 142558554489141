/**
 * @name Chart
 * @description
 */
import {Echarts} from 'components';
import {CardsCardNo, CardsCardNoKeyCard} from 'pages/dashboard/api/type';
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Color} from 'styles';

type Props = {
  info: CardsCardNo;
};
const Contents = ({info}: Props): JSX.Element => {
  const temp: CardsCardNoKeyCard = Object.assign([], info);

  const [options, setOptions] = useState<echarts.EChartsOption>();

  useEffect(() => {
    if (!info) return;
    setOptions({
      legend: {
        data: [info[0].name, info[1].name],
        bottom: 0,
        show: true,
        borderType: 'solid',
        itemWidth: 10, // 범례 마커의 너비
        itemHeight: 10, // 범례 마커의 높이
        icon: 'circle' // Setting the icon to circle
      },
      grid: {top: '20px', left: '30px', right: '20px', bottom: '80px'},
      xAxis: {
        type: 'category',
        data: Array.from({length: temp[0].data[0].data.length}, (_, index) => {
          return `${index.toString().padStart(2, '0')}`;
        }),
        axisLabel: {
          margin: 10
        },
        name: '시',
        nameLocation: 'end',
        nameTextStyle: {
          fontSize: 10,
          padding: [0, 0, 5, -20],
          verticalAlign: 'bottom'
        },
        axisTick: {
          show: true,
          lineStyle: {
            type: 'dashed',
            width: 1
          }
        },
        axisLine: {
          symbol: 'none',
          lineStyle: {
            type: 'dashed'
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value}'
        },
        name: 'kW', // Y축 이름 추가
        nameLocation: 'end', // 이름 위치를 축의 끝에 배치
        nameTextStyle: {
          // align: 'left',
          fontSize: 10,
          padding: [0, 30, -5, 0]
          // verticalAlign: 'bottom'
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow' // 'shadow'는 전체 열의 그림자를 보여주는 효과
        }
      },
      series: [
        {
          name: info[0].name,
          data: temp[0].data[0].data,
          type: 'bar',
          stack: 'total', // 스택 설정
          emphasis: {
            focus: 'series'
          },
          barWidth: 10,
          itemStyle: {
            color: Color.blue50
          }
        },
        {
          name: info[1].name,
          data: temp[1].data[0].data,
          type: 'bar',
          stack: 'total', // 같은 스택 그룹
          emphasis: {
            focus: 'series'
          },
          barWidth: 10,
          itemStyle: {
            color: Color.green50
          }
        }
      ]
    });
  }, [info]);

  return <Content>{options && <Echarts options={options} height={200} />}</Content>;
};

export default Contents;

const Content = styled.div`
  width: 100%;
  padding: 0 16px;
`;
