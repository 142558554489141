/**
 * @name Util
 * @description
 */
import { useEffect, useState } from "react";
import { Dayjs } from "lib/events";
import styled from "styled-components";
import { Text } from "components";
import { Color, desktop_max_width } from "styles";

const UtilTime: React.FC = () => {
  const format = "YY.MM.DD HH:mm";
  const [time, setTime] = useState(Dayjs().format(format));

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Dayjs().format(format));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Content>
      <Text mode="Body_MM" color={Color.blue30} label={time} />
    </Content>
  );
};
export default UtilTime;

const Content = styled.section`
  display: flex;
  width: 100px;
  align-items: center;
  flex-direction: row;
  margin-left: 24px;

  @media all and (max-width: ${desktop_max_width}) {
    display: none;
  }
`;
