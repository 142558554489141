import {
  clearSky,
  clearSkyNight,
  cloudy,
  fewclouds,
  fewcloudsNight,
  heavyrain,
  mist,
  rain,
  rainNight,
  snow,
  thunder
} from 'assets/svg';

export const WEATHER_IMAGE: {[key: string]: string} = {
  '01d': clearSky,
  '01n': clearSkyNight,
  '02d': fewclouds,
  '02n': fewcloudsNight,
  '03d': cloudy,
  '03n': cloudy,
  '04d': cloudy,
  '04n': cloudy,
  '09d': heavyrain,
  '09n': heavyrain,
  '10d': rain,
  '10n': rainNight,
  '11d': thunder,
  '11n': thunder,
  '13d': snow,
  '13n': snow,
  '50d': mist,
  '50n': mist
};

export const WEATHER_TEXT: {[key: string]: string} = {
  '01d': '맑음',
  '01n': '맑음',
  '02d': '흐림',
  '02n': '흐림',
  '03d': '구름많음',
  '03n': '구름많음',
  '04d': '구름많음',
  '04n': '구름많음',
  '09d': '많은비',
  '09n': '많은비',
  '10d': '비',
  '10n': '비',
  '11d': '천둥번개',
  '11n': '천둥번개',
  '13d': '눈',
  '13n': '눈',
  '50d': '안개',
  '50n': '안개'
};
