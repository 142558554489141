/**
 * @name EventEmitter3
 * @refer https://github.com/primus/eventemitter3
 */

import {nanoid} from 'nanoid';

export default nanoid;

/********************************************************
[사용법]
https://www.npmjs.com/package/nanoid


*********************************************************/
