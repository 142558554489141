/**
 * @name Contents
 * @returns
 */
import {IconArrowNext, IconArrowPrev} from 'assets/svg';
import {Flex} from 'components';
import {useGuideStore} from 'pages/_guide/model';
import styled from 'styled-components';
import {Color} from 'styles';

const Contents: React.FC = () => {
  const {currentPage, setCurrentPage} = useGuideStore((state) => state);

  return (
    <Content>
      <Flex.Row justifyContent="center">
        <button
          disabled={currentPage === 1}
          onClick={() => {
            setCurrentPage(currentPage - 1);
          }}>
          <IconArrowPrev />
          <span>이전페이지</span>
        </button>
        <button
          onClick={() => {
            setCurrentPage(currentPage + 1);
          }}>
          <span>다음페이지</span>
          <IconArrowNext />
        </button>
      </Flex.Row>
    </Content>
  );
};
export default Contents;

const Content = styled.footer`
  display: flex;
  width: 100%;
  padding: 20px;
  border-top: 1px dashed ${Color.gray60};
  background-color: ${Color.gray90};
  button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 20px;
    background-color: ${Color.gray80};
    border: none;
    cursor: pointer;
    &:hover {
      background-color: ${Color.gray70};
    }
  }
`;
