/**
 * @name 타이틀
 * @description
 */
import {Text} from 'components';
import {Color} from 'styles';

type Props = {
  title: string;
};
const Contents = ({title}: Props): JSX.Element => {
  return (
    <Text label={title} style={{width: '100%', height: 22, marginBottom: 8}} mode="Title_SB" color={Color.gray50} />
  );
};
export default Contents;
