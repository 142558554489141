/**
 * @name useRoutes
 * @description hooks기반 라우팅정보
 */

// import {useRouter} from 'next/router'
import {NavigateFunction, useNavigate} from 'react-router-dom';

// 반환 객체의 타입을 정의합니다.
interface UseRoutesReturn {
  navigate: NavigateFunction;
}

// useRoutes 훅 정의
export function useRoutes(): UseRoutesReturn {
  const navigate = useNavigate();

  // navigate 함수를 포함하는 객체 반환
  return {navigate};
}

// export const useRoutes: NavigateFunction = () => {
//   // hooks
//   const navigate = useNavigate();
//   return {navigate};
// };
/********************************************************

navigate(path, {state: {title: 'test', num: 0}})
navigate(-1);

*********************************************************/
