import {gnb_full_logo, gnb_logo} from 'assets/images';
import Button from 'components/button/button';
import {MENU} from 'config/routes';
import {motion} from 'framer-motion';
import {useRoutes} from 'lib/hooks/util/useRoutes';
import {useState} from 'react';
import {styled} from 'styled-components';
import {Color} from 'styles/color';
import FooterMenu from './footer';
import MainMenu from './main';

/**
 * @name GNB
 */

export const Gnb: React.FC = () => {
  const {navigate} = useRoutes();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Wrapper
      onHoverStart={(e) => {
        setIsOpen(true);
      }}
      onHoverEnd={(e) => {
        setIsOpen(false);
      }}>
      <Content>
        {/* 로고 */}
        <Logo>
          <Button
            child={<img src={isOpen ? gnb_full_logo : gnb_logo} style={{width: isOpen ? 120 : 28, height: 24}} />}
            onClick={() => {
              navigate(MENU.DASHBOARD);
            }}
          />
        </Logo>
        {/* 메뉴 */}
        <MainMenu isOpen={isOpen} />
      </Content>
      {/* 푸터메뉴 */}
      <FooterMenu isOpen={isOpen} />
    </Wrapper>
  );
};

const Wrapper = styled(motion.div)`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
  padding: 10px 14px;
  width: 70px;
  height: 100%;
  background-color: ${Color.white};
  border-right: 1px solid ${Color.gray80};
  &:hover {
    width: 260px;
  }
`;

const Content = styled.ul`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Logo = styled.div`
  padding: 10px 8px;
  margin-bottom: 22px;
`;
