import {useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';
import {Card} from '../common';
import {Props} from '../template_factory';
import Table from 'components/table/table';
import {sst1Data1, sst1Data2, sst1Data3} from 'pages/dashboard/constant/tableHeader';
import {styled} from 'styled-components';
import {Color} from 'styles';
import {Text} from 'components';
import {useSst} from 'context';

const Contents = ({item, headers}: Props) => {
  // const {data} = useCardsCardNoQuery(item?.cardNo);
  const {sst} = useSst();

  return (
    <Card rowSpan={1} title={item?.title}>
      <Content>
        {
          <Table
            headers={headers!}
            data={(sst === 'SST1' ? sst1Data1 : sst === 'SST2' ? sst1Data2 : sst1Data3) as unknown as string[][]}
          />
        }
      </Content>
    </Card>
  );
};

export default Contents;

const Content = styled.article`
  overflow-y: auto;
  height: 230px;
`;

const TopRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 26px;
  border-radius: 6px;
  background-color: ${Color.gray80};
  margin: 12px 25px;
`;
