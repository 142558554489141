import {Chart} from 'pages/dashboard/hooks/useCard';
import {blueGrayRedYellowGreen} from 'styles/color';

// SST
export const SST1: Chart[] = [
  {
    label: '유효 전력',
    data: [
      '171.08',
      '257.13',
      '257.86',
      '249.29',
      '262.17',
      '223.37',
      '288.28',
      '278.72',
      '281.51',
      '288.16',
      '295.72',
      '309.89',
      '183.21',
      '290.22',
      '294.41',
      '273.63',
      '274.46',
      '187.82',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null'
    ],
    unit: 'kWh'
  },
  {
    label: '절감량',
    data: [
      '5.29',
      '7.95',
      '7.98',
      '7.71',
      '8.11',
      '6.91',
      '8.92',
      '8.62',
      '8.71',
      '8.91',
      '9.15',
      '9.58',
      '5.67',
      '8.98',
      '9.11',
      '8.46',
      '8.49',
      '5.81',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null'
    ],
    unit: 'kWh'
  }
];
export const SST2 = [
  {
    label: '유효 전력',
    data: [
      '185.32',
      '242.56',
      '268.91',
      '255.73',
      '270.45',
      '230.18',
      '295.67',
      '282.39',
      '289.04',
      '292.85',
      '301.23',
      '315.76',
      '190.54',
      '298.67',
      '301.92',
      '280.19',
      '281.05',
      '195.43',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null'
    ],
    unit: 'kWh'
  },
  {
    label: '절감량',
    data: [
      '5.73',
      '7.50',
      '8.32',
      '7.91',
      '8.37',
      '7.12',
      '9.15',
      '8.74',
      '8.94',
      '9.06',
      '9.32',
      '9.77',
      '5.89',
      '9.24',
      '9.34',
      '8.67',
      '8.70',
      '6.05',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null'
    ],
    unit: 'kWh'
  }
];
export const SST3 = [
  {
    label: '유효 전력',
    data: [
      '163.45',
      '250.89',
      '261.73',
      '245.61',
      '258.34',
      '218.92',
      '283.56',
      '275.18',
      '278.97',
      '285.43',
      '292.08',
      '305.64',
      '178.96',
      '286.75',
      '290.83',
      '270.21',
      '271.02',
      '182.57',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null'
    ],
    unit: 'kWh'
  },
  {
    label: '절감량',
    data: [
      '5.06',
      '7.76',
      '8.10',
      '7.60',
      '7.99',
      '6.77',
      '8.77',
      '8.51',
      '8.63',
      '8.83',
      '9.04',
      '9.46',
      '5.54',
      '8.87',
      '9.00',
      '8.36',
      '8.38',
      '5.65',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null'
    ],
    unit: 'kWh'
  }
];

// CVR
export const CVR1: Chart[] = [
  {
    label: '이번달 CVR factor ',
    data: [
      '1.2',
      '2.8',
      '0.9',
      '2.1',
      '1.5',
      '0.7',
      '2.4',
      '1.6',
      '0.3',
      '2.9',
      '1.1',
      '2.2',
      '0.8',
      '1.4',
      '2.7',
      '1.9',
      '0.5',
      '2.6',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null'
    ],
    unit: 'CVRf'
  }
];
export const CVR2: Chart[] = [
  {
    label: '이번달 CVR factor ',
    data: [
      '0.3',
      '2.9',
      '1.7',
      '0.4',
      '2.8',
      '1.0',
      '3.0',
      '0.8',
      '2.5',
      '0.2',
      '2.6',
      '1.2',
      '0.9',
      '2.7',
      '1.3',
      '0.5',
      '2.4',
      '3.0',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null'
    ],
    unit: 'CVRf'
  }
];
export const CVR3: Chart[] = [
  {
    label: '이번달 CVR factor ',
    data: [
      '3.0',
      '2.4',
      '1.8',
      '1.2',
      '0.7',
      '0.3',
      '0.5',
      '1.0',
      '1.3',
      '1.6',
      '1.9',
      '2.1',
      '2.3',
      '2.4',
      '2.5',
      '2.6',
      '2.7',
      '2.9',
      'null',
      'null',
      'null',
      'null',
      'null',
      'null'
    ],
    unit: 'CVRf'
  }
];

// 월 누적 전력 사용량
export const lineArerData11 = [
  '6477.84',
  '12566.32',
  '18366.08',
  '24676.56',
  '30896.64',
  '36725.12',
  '42964.16',
  '49103.84',
  '55067.36',
  '61060.80',
  '67447.04',
  '73580.32',
  '79808.56',
  '85941.04',
  '92514.96',
  '99219.68',
  '106382.32',
  '113673.44',
  '119333.36',
  '125569.36',
  '132368.00',
  '138349.52',
  '144220.40',
  '148998.80',
  '153876.16',
  '155388.56',
  '156838.80',
  '157862.48',
  '158109.84',
  '158212' // 목표값 158212
];
export const lineArerData12 = [
  '7477.84',
  '13666.08',
  '19166.56',
  '25216.64',
  '31375.12',
  '37664.16',
  '43703.84',
  '49267.36',
  '55560.80',
  '61747.04',
  '67480.32',
  '73008.56',
  '78841.04',
  '84414.96',
  '89719.68',
  '95382.32',
  '100973.44',
  '105933.36',
  '110469.36',
  '115268.00',
  '119549.52',
  '123420.40',
  '128798.80',
  '133576.16',
  '138088.56',
  '140338.80',
  '141638.16',
  '142062.48',
  '142329.84',
  '142534' // 목표값 142534
];
export const lineArerData13 = [
  '5077.84',
  '9676.08',
  '13886.56',
  '18316.64',
  '22575.12',
  '26864.16',
  '31403.84',
  '36167.36',
  '40760.80',
  '45447.04',
  '50380.32',
  '55008.56',
  '59841.04',
  '64514.96',
  '69219.68',
  '73982.32',
  '78873.44',
  '83933.36',
  '89169.36',
  '94468.00',
  '99549.52',
  '104620.40',
  '109398.80',
  '113076.16',
  '116888.56',
  '117838.80',
  '119238.16',
  '120462.48',
  '121109.84',
  '122542' // 목표값 112542
];
export const lineArerData21 = [
  '8777.84',
  '17766.08',
  '26356.56',
  '34216.64',
  '41875.12',
  '49964.16',
  '57803.84',
  '65667.36',
  '72560.80',
  '80047.04',
  '87980.32',
  '95208.56',
  '103841.04',
  '110314.96',
  '117519.68',
  '118212' // 목표값 118212
];
export const lineArerData22 = [
  '5077.84',
  '10066.08',
  '14656.56',
  '19816.64',
  '25475.12',
  '30564.16',
  '36103.84',
  '41167.36',
  '45860.80',
  '50447.04',
  '55180.32',
  '59608.56',
  '64141.04',
  '68614.96',
  '73019.68',
  '78082.32',
  '82073.44',
  '88033.36',
  '93069.36',
  '94542' // 목표값 94542
];
export const lineArerData23 = [
  '6777.84',
  '12666.08',
  '17956.56',
  '23016.64',
  '27775.12',
  '32164.16',
  '36403.84',
  '40767.36',
  '45560.80',
  '50047.04',
  '54880.32',
  '59008.56',
  '62841.04',
  '66814.96',
  '71019.68',
  '75882.32',
  '77834' // 목표값 77834
];

export const sstValue1 = [
  {
    label: '제어 OFF시 전압',
    value: 392.76,
    unit: 'V'
  },
  {
    label: '현재 전압',
    value: 382.94,
    unit: 'V'
  },
  {
    label: '전압 제어',
    value: 2.5,
    unit: '%'
  }
];
export const sstValue2 = [
  {
    label: '제어 OFF시 전압',
    value: 345.36,
    unit: 'V'
  },
  {
    label: '현재 전압',
    value: 340.14,
    unit: 'V'
  },
  {
    label: '전압 제어',
    value: 1.5,
    unit: '%'
  }
];
export const sstValue3 = [
  {
    label: '제어 OFF시 전압',
    value: 399.76,
    unit: 'V'
  },
  {
    label: '현재 전압',
    value: 389.94,
    unit: 'V'
  },
  {
    label: '전압 제어',
    value: 3.5,
    unit: '%'
  }
];

export const sstChart1 = [
  {
    label: '전압 제어 ON',
    data: [
      '392.21',
      '392.37',
      '392.29',
      '392.63',
      '392.75',
      '392.56',
      '392.79',
      '392.68',
      '392.70',
      '392.83',
      '392.91',
      '393.06',
      '393.22',
      '393.19',
      '393.42',
      '393.71',
      '393.58',
      '393.23',
      '393.32',
      '393.06',
      '392.93',
      '393.02',
      '393.26',
      '393.33',
      '393.29',
      '393.38',
      '393.41',
      '393.37',
      '393.38',
      '393.20',
      '393.23',
      '393.37',
      '393.74',
      '394.04',
      '394.02',
      '394.01',
      '393.89',
      '393.78',
      '393.83',
      '391.15',
      '390.63',
      '390.58',
      '390.82',
      '391.08',
      '391.11',
      '391.38',
      '391.26',
      '391.50',
      '392.74',
      '395.23',
      '391.37',
      '390.79',
      '390.56',
      '390.69',
      '390.53',
      '390.57',
      '390.60',
      '390.53',
      '390.48',
      '390.29',
      '390.02',
      '389.83',
      '388.75',
      '388.21',
      '387.96',
      '384.49',
      '386.25',
      '385.82',
      '385.59',
      '383.32',
      '385.76',
      '383.79',
      '384.03',
      '383.01',
      '383.63',
      '383.35',
      '383.79',
      '382.49',
      '382.92',
      '382.38',
      '383.49',
      '384.02',
      '384.73',
      '384.40',
      '383.90',
      '384.80',
      '385.64',
      '384.62',
      '383.93',
      '384.64',
      '384.89',
      '384.83',
      '384.79',
      '383.15',
      '384.26',
      '385.81',
      '384.82',
      '383.60',
      '381.56',
      '384.01',
      '384.80',
      '384.27',
      '384.44',
      '384.15',
      '384.35',
      '383.07',
      '383.78',
      '385.54',
      '384.69',
      '384.16',
      '385.22',
      '384.07',
      '384.34',
      '384.24',
      '384.69',
      '384.02',
      '385.95',
      '386.27',
      '385.15',
      '385.59',
      '385.15',
      '384.48',
      '384.90',
      '383.85',
      '385.08',
      '385.85',
      '384.89',
      '384.65',
      '384.99',
      '385.43',
      '384.16',
      '384.01',
      '385.28',
      '384.26',
      '384.78',
      '386.31',
      '384.91',
      '385.08',
      '385.14',
      '386.03',
      '386.96',
      '386.87',
      '387.01',
      '386.58',
      '386.80',
      '386.36',
      '387.23',
      '385.93',
      '386.00',
      '385.22',
      '385.76',
      '385.51',
      '384.62',
      '384.82',
      '385.40',
      '385.39',
      '384.48',
      '384.92',
      '385.19',
      '384.94',
      '384.60',
      '384.80',
      '385.62',
      '384.91',
      '383.81',
      '386.07',
      '383.76',
      '385.92',
      '384.43',
      '384.71',
      '385.38',
      '385.74',
      '386.13',
      '384.29',
      '385.16',
      '384.71',
      '385.25',
      '386.95',
      '388.07',
      '388.55',
      '389.55',
      '389.45',
      '389.49',
      '389.68',
      '389.66',
      '389.84',
      '389.85',
      '389.68',
      '389.58',
      '389.70',
      '389.90',
      '389.52',
      '388.01',
      '387.32',
      '385.88',
      '385.70',
      '386.63',
      '386.89',
      '385.88',
      '386.11',
      '385.15',
      '385.84',
      '384.34',
      '386.65',
      '386.35',
      '386.59',
      '385.84',
      '387.46',
      '386.83',
      '387.09',
      '386.85',
      '387.69',
      '386.90',
      '386.85',
      '387.63',
      '387.47',
      '386.73',
      '386.61',
      '386.56',
      '385.80',
      '386.49',
      '386.44',
      '386.98',
      '386.97',
      '386.31',
      '386.36',
      '387.34',
      '387.21',
      '388.27',
      '387.74',
      '385.37',
      '387.69',
      '386.33',
      '386.07',
      '387.47',
      '387.98',
      '387.45',
      '387.27',
      '388.09',
      '387.38',
      '386.97',
      '386.80',
      '387.19',
      '387.96',
      '386.98',
      '386.72',
      '388.12',
      '387.41',
      '386.97',
      '387.66',
      '386.61',
      '385.07',
      '387.97',
      '384.46',
      '385.74',
      '384.62',
      '387.26',
      '385.39',
      '384.34',
      '385.57',
      '384.80',
      '386.41',
      '385.91',
      '386.63',
      '386.85',
      '388.88',
      '387.93',
      '388.69',
      '389.26',
      '388.69',
      '387.55',
      '388.37',
      '386.43',
      '386.30',
      '386.79',
      '387.42',
      '386.97',
      '386.03',
      '386.30',
      '386.23',
      '386.12',
      '385.92',
      '386.91',
      '386.65',
      '388.56',
      '386.55',
      '388.63',
      '387.46',
      '388.44',
      '387.82',
      '388.36',
      '387.53',
      '388.38',
      '388.62',
      '386.05',
      '387.59',
      '386.34',
      '387.11',
      '389.02',
      '389.61',
      '389.86',
      '389.72',
      '389.90',
      '389.98',
      '389.87',
      '389.58',
      '389.49',
      '389.39',
      '389.44',
      '389.44',
      '389.48',
      '389.50',
      '389.88',
      '389.80',
      '389.92',
      '389.67',
      '389.43',
      '389.46',
      '389.59',
      '389.83',
      '389.72',
      '389.40',
      '389.81',
      '389.75',
      '389.75',
      '389.75',
      '389.90',
      '389.93',
      '389.58',
      '389.44',
      '389.23',
      '389.35',
      '389.08',
      '389.18',
      '388.54',
      '387.17',
      '386.74',
      '385.75',
      '387.14',
      '386.39',
      '386.28',
      '384.90',
      '385.56',
      '385.21',
      '386.56',
      '386.14',
      '386.06',
      '385.96',
      '385.16',
      '386.75',
      '386.93',
      '387.71',
      '386.47',
      '386.43',
      '386.23',
      '385.94',
      '387.54',
      '386.60',
      '386.90',
      '385.89',
      '386.26',
      '386.28',
      '386.78',
      '386.79',
      '386.75',
      '386.63',
      '386.33',
      '386.45',
      '386.46',
      '386.08',
      '386.19',
      '385.78',
      '385.95',
      '386.19',
      '385.16',
      '385.79',
      '386.14',
      '386.16',
      '385.38',
      '385.46',
      '385.81',
      '385.86',
      '385.53',
      '385.58',
      '385.57',
      '385.66',
      '385.50',
      '385.98',
      '385.93',
      '386.16',
      '385.20',
      '386.05',
      '386.14',
      '385.69',
      '385.53',
      '385.89',
      '386.83',
      '387.07',
      '387.51',
      '387.54',
      '387.14',
      '386.32',
      '387.37',
      '387.22',
      '387.32',
      '387.17',
      '387.33',
      '387.50',
      '387.81',
      '387.98',
      '387.99',
      '388.16',
      '387.93',
      '386.97',
      '387.09',
      '387.49',
      '387.37',
      '387.59',
      '387.54',
      '387.53',
      '387.01',
      '387.03',
      '386.54',
      '383.98',
      '386.45',
      '386.52',
      '386.14',
      '385.92',
      '385.78',
      '386.31',
      '388.43',
      '388.87',
      '388.23',
      '388.37',
      '386.98',
      '388.09',
      '387.67',
      '387.61',
      '384.92',
      '387.65',
      '387.89',
      '387.03',
      '385.75',
      '387.65',
      '387.59',
      '387.44',
      '387.15',
      '384.59',
      '387.27',
      '386.97',
      '386.86',
      '386.29',
      '385.58',
      '385.96',
      '386.06',
      '384.74',
      '382.86',
      '384.72',
      '383.60',
      '383.33',
      '383.01',
      '382.44',
      '382.24',
      '380.74',
      '383.29',
      '383.31',
      '380.63',
      '380.74',
      '382.12',
      '382.94',
      '383.89',
      '383.84',
      '383.39',
      '383.39',
      '382.88',
      '382.64',
      '382.37',
      '382.05',
      '386.16',
      '386.23',
      '385.66',
      '385.18',
      '384.97',
      '384.64',
      '384.30',
      '384.06',
      '383.71',
      '383.90',
      '383.46',
      '383.65',
      '383.62',
      '383.66',
      '383.41',
      '383.30',
      '383.17',
      '383.14',
      '383.04',
      '381.59',
      '381.31',
      '378.18',
      '380.68',
      '378.64',
      '380.25',
      '381.06',
      '379.25',
      '377.87',
      '378.60',
      '378.82',
      '378.54',
      '378.73',
      '379.88',
      '381.33',
      '380.94',
      '382.72',
      '380.82',
      '380.42',
      '379.34',
      '378.31',
      '385.20',
      '385.94',
      '385.45',
      '385.84',
      '387.38',
      '387.14',
      '386.24',
      '384.99',
      '387.20',
      '387.33',
      '386.77',
      '386.29',
      '387.43',
      '388.19',
      '387.66',
      '385.94',
      '387.14',
      '388.01',
      '387.70',
      '387.19',
      '387.79',
      '386.46',
      '386.41',
      '388.33',
      '387.54',
      '387.39',
      '386.65',
      '384.05',
      '383.71',
      '387.07',
      '386.80',
      '385.30',
      '385.82',
      '385.27',
      '387.19',
      '386.98',
      '387.28',
      '387.27',
      '387.38',
      '387.60',
      '386.27',
      '385.36',
      '384.25',
      '384.72',
      '386.31',
      '384.75',
      '385.85',
      '384.69',
      '386.01',
      '385.38',
      '385.16',
      '384.39',
      '384.88',
      '385.38',
      '384.12',
      '385.38',
      '384.73',
      '385.06',
      '385.49',
      '384.44',
      '385.27',
      '383.80',
      '382.43',
      '381.45',
      '382.79',
      '382.00',
      '384.43',
      '382.80',
      '382.11',
      '383.30',
      '382.12',
      '381.87',
      '381.04',
      '381.99',
      '380.84',
      '383.11',
      '381.04',
      '381.34',
      '382.09',
      '382.52',
      '382.92',
      '386.64',
      '386.95',
      '386.89',
      '387.30',
      '387.27',
      '387.59',
      '387.46',
      '387.30',
      '387.45',
      '387.31',
      '387.37',
      '387.29',
      '387.06',
      '384.10',
      '379.75',
      '382.43',
      '384.42',
      '383.86',
      '384.33',
      '381.03',
      '381.86',
      '381.32',
      '381.73',
      '381.84',
      '381.88',
      '381.42',
      '383.48',
      '382.71',
      '383.57',
      '385.63',
      '385.37',
      '384.86',
      '386.53',
      '386.11',
      '385.68',
      '386.28',
      '387.22',
      '386.37',
      '386.01',
      '388.51',
      '388.78',
      '388.25',
      '387.65',
      '386.85',
      '387.46',
      '385.93',
      '386.48',
      '386.24',
      '386.32',
      '388.27',
      '388.74',
      '389.13',
      '389.47',
      '389.52',
      '389.00',
      '387.41',
      '390.68',
      '390.20',
      '390.89',
      '390.78',
      '390.99',
      '389.17',
      '389.54',
      '388.95',
      '387.93',
      '390.07',
      '390.61',
      '389.70',
      '390.04',
      '389.79',
      '389.99',
      '388.50',
      '390.17',
      '389.17',
      '389.16',
      '389.96',
      '388.55',
      '389.26',
      '388.23',
      '388.55',
      '389.10',
      '389.35',
      '389.02',
      '389.49',
      '388.88',
      '389.69',
      '390.08',
      '389.88',
      '391.25',
      '389.84',
      '386.78',
      '388.47',
      '386.51',
      '387.62',
      '385.77',
      '387.45',
      '384.87',
      '385.81',
      '385.77',
      '386.31',
      '385.52',
      '385.50',
      '385.81',
      '385.97',
      '385.68',
      '388.57',
      '387.38',
      '387.14',
      '388.98',
      '388.74',
      '388.08',
      '388.31',
      '386.97',
      '381.51',
      '382.43',
      '382.85',
      '382.40',
      '381.91',
      '383.63',
      '386.00',
      '388.07',
      '388.66',
      '388.79',
      '389.13',
      '389.12',
      '389.26',
      '386.34',
      '386.06',
      '386.19',
      '386.39',
      '386.58',
      '386.51',
      '386.52',
      '386.75',
      '386.94',
      '386.93',
      '386.82',
      '387.24',
      '387.25',
      '387.56',
      '387.61',
      '387.90',
      '387.79',
      '387.92',
      '388.02',
      '388.08',
      '388.09',
      '388.05',
      '388.00',
      '388.25',
      '388.16',
      '386.55',
      '386.15',
      '386.01',
      '386.04',
      '385.75',
      '385.36',
      '385.44',
      '385.59',
      '385.46',
      '385.15',
      '384.85',
      '385.05',
      '386.00',
      '389.32',
      '389.13',
      '388.77',
      '389.19',
      '389.47',
      '389.44',
      '389.28',
      '388.89',
      '388.98',
      '388.84',
      '388.88',
      '388.55',
      '388.14',
      '387.72',
      '387.32',
      '386.84',
      '386.05',
      '385.11',
      '385.92',
      '387.14',
      '386.07',
      '385.49',
      '385.13',
      '389.19',
      '388.78',
      '389.64',
      '388.74',
      '388.78',
      '386.50',
      '388.28',
      '387.82',
      '387.56',
      '387.14',
      '387.59',
      '388.12',
      '386.88',
      '385.39',
      '386.07',
      '386.64',
      '385.64',
      '383.71',
      '384.81',
      '386.25',
      '384.31',
      '382.92',
      '383.25',
      '384.38',
      '382.29',
      '385.02',
      '384.20',
      '383.61',
      '384.88',
      '384.33',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    unit: 'V'
  },
  {
    label: '전압 제어 OFF',
    data: [
      '402.27',
      '402.43',
      '402.35',
      '402.70',
      '402.82',
      '402.63',
      '402.86',
      '402.75',
      '402.77',
      '402.90',
      '402.98',
      '403.14',
      '403.30',
      '403.27',
      '403.51',
      '403.81',
      '403.67',
      '403.31',
      '403.41',
      '403.14',
      '403.01',
      '403.10',
      '403.34',
      '403.42',
      '403.37',
      '403.47',
      '403.50',
      '403.46',
      '403.47',
      '403.28',
      '403.31',
      '403.46',
      '403.84',
      '404.14',
      '404.12',
      '404.11',
      '403.99',
      '403.88',
      '403.93',
      '401.18',
      '400.65',
      '400.59',
      '400.84',
      '401.11',
      '401.14',
      '401.42',
      '401.29',
      '401.54',
      '402.81',
      '405.36',
      '401.41',
      '400.81',
      '400.57',
      '400.71',
      '400.54',
      '400.58',
      '400.62',
      '400.54',
      '400.49',
      '400.30',
      '400.02',
      '399.83',
      '398.72',
      '398.16',
      '397.91',
      '394.35',
      '396.15',
      '395.71',
      '395.48',
      '393.15',
      '395.65',
      '393.63',
      '393.88',
      '392.83',
      '393.47',
      '393.18',
      '393.63',
      '392.30',
      '392.74',
      '392.18',
      '393.32',
      '393.87',
      '394.59',
      '394.26',
      '393.74',
      '394.67',
      '395.53',
      '394.48',
      '393.77',
      '394.50',
      '394.76',
      '394.70',
      '394.66',
      '392.97',
      '394.11',
      '395.70',
      '394.69',
      '393.44',
      '391.34',
      '393.86',
      '394.67',
      '394.12',
      '394.30',
      '394.00',
      '394.21',
      '392.89',
      '393.62',
      '395.43',
      '394.55',
      '394.01',
      '395.10',
      '393.92',
      '394.19',
      '394.09',
      '394.55',
      '393.87',
      '395.85',
      '396.17',
      '395.03',
      '395.48',
      '395.03',
      '394.34',
      '394.77',
      '393.69',
      '394.95',
      '395.74',
      '394.76',
      '394.51',
      '394.86',
      '395.31',
      '394.01',
      '393.86',
      '395.16',
      '394.11',
      '394.65',
      '396.22',
      '394.78',
      '394.95',
      '395.02',
      '395.93',
      '396.88',
      '396.79',
      '396.93',
      '396.49',
      '396.72',
      '396.27',
      '397.16',
      '395.83',
      '395.90',
      '395.10',
      '395.65',
      '395.39',
      '394.48',
      '394.69',
      '395.28',
      '395.27',
      '394.34',
      '394.79',
      '395.07',
      '394.81',
      '394.46',
      '394.67',
      '395.51',
      '394.78',
      '393.65',
      '395.97',
      '393.60',
      '395.82',
      '394.29',
      '394.57',
      '395.26',
      '395.63',
      '396.03',
      '394.14',
      '395.04',
      '394.57',
      '395.13',
      '396.87',
      '398.02',
      '398.51',
      '399.54',
      '399.44',
      '399.48',
      '399.67',
      '399.65',
      '399.84',
      '399.85',
      '399.67',
      '399.57',
      '399.69',
      '399.90',
      '399.51',
      '397.96',
      '397.25',
      '395.77',
      '395.59',
      '396.54',
      '396.81',
      '395.77',
      '396.01',
      '395.03',
      '395.73',
      '394.19',
      '396.56',
      '396.26',
      '396.50',
      '395.73',
      '397.39',
      '396.75',
      '397.02',
      '396.77',
      '397.63',
      '396.82',
      '396.77',
      '397.57',
      '397.41',
      '396.65',
      '396.52',
      '396.47',
      '395.69',
      '396.40',
      '396.35',
      '396.90',
      '396.89',
      '396.22',
      '396.27',
      '397.27',
      '397.14',
      '398.23',
      '397.68',
      '395.25',
      '397.63',
      '396.24',
      '395.97',
      '397.41',
      '397.93',
      '397.38',
      '397.20',
      '398.04',
      '397.31',
      '396.89',
      '396.72',
      '397.12',
      '397.91',
      '396.90',
      '396.64',
      '398.07',
      '397.34',
      '396.89',
      '397.60',
      '396.52',
      '394.94',
      '397.92',
      '394.32',
      '395.63',
      '394.48',
      '397.19',
      '395.27',
      '394.19',
      '395.46',
      '394.67',
      '396.32',
      '395.81',
      '396.54',
      '396.77',
      '398.85',
      '397.88',
      '398.66',
      '399.24',
      '398.66',
      '397.49',
      '398.33',
      '396.34',
      '396.21',
      '396.71',
      '397.35',
      '396.89',
      '395.93',
      '396.21',
      '396.13',
      '396.02',
      '395.82',
      '396.83',
      '396.56',
      '398.52',
      '396.46',
      '398.59',
      '397.39',
      '398.40',
      '397.76',
      '398.32',
      '397.47',
      '398.34',
      '398.58',
      '395.95',
      '397.53',
      '396.25',
      '397.04',
      '398.99',
      '399.60',
      '399.86',
      '399.71',
      '399.90',
      '399.98',
      '399.87',
      '399.57',
      '399.48',
      '399.37',
      '399.43',
      '399.43',
      '399.47',
      '399.49',
      '399.88',
      '399.79',
      '399.92',
      '399.66',
      '399.42',
      '399.45',
      '399.58',
      '399.83',
      '399.71',
      '399.38',
      '399.81',
      '399.74',
      '399.74',
      '399.74',
      '399.90',
      '399.93',
      '399.57',
      '399.43',
      '399.21',
      '399.33',
      '399.06',
      '399.16',
      '398.50',
      '397.10',
      '396.66',
      '395.64',
      '397.07',
      '396.30',
      '396.18',
      '394.77',
      '395.45',
      '395.09',
      '396.47',
      '396.04',
      '395.96',
      '395.86',
      '395.04',
      '396.67',
      '396.85',
      '397.65',
      '396.38',
      '396.34',
      '396.13',
      '395.84',
      '397.48',
      '396.51',
      '396.82',
      '395.78',
      '396.16',
      '396.18',
      '396.70',
      '396.71',
      '396.67',
      '396.54',
      '396.24',
      '396.36',
      '396.37',
      '395.98',
      '396.09',
      '395.67',
      '395.85',
      '396.09',
      '395.04',
      '395.68',
      '396.04',
      '396.06',
      '395.26',
      '395.34',
      '395.70',
      '395.75',
      '395.42',
      '395.47',
      '395.46',
      '395.55',
      '395.38',
      '395.88',
      '395.83',
      '396.06',
      '395.08',
      '395.95',
      '396.04',
      '395.58',
      '395.42',
      '395.78',
      '396.75',
      '396.99',
      '397.45',
      '397.48',
      '397.07',
      '396.23',
      '397.30',
      '397.15',
      '397.25',
      '397.10',
      '397.26',
      '397.44',
      '397.75',
      '397.93',
      '397.94',
      '398.11',
      '397.88',
      '396.89',
      '397.02',
      '397.43',
      '397.30',
      '397.53',
      '397.48',
      '397.47',
      '396.93',
      '396.95',
      '396.45',
      '393.83',
      '396.36',
      '396.43',
      '396.04',
      '395.82',
      '395.67',
      '396.22',
      '398.39',
      '398.84',
      '398.18',
      '398.33',
      '396.90',
      '398.04',
      '397.61',
      '397.55',
      '394.79',
      '397.59',
      '397.84',
      '396.95',
      '395.64',
      '397.59',
      '397.53',
      '397.37',
      '397.08',
      '394.45',
      '397.20',
      '396.89',
      '396.78',
      '396.19',
      '395.47',
      '395.86',
      '395.96',
      '394.61',
      '392.68',
      '394.58',
      '393.44',
      '393.16',
      '392.83',
      '392.25',
      '392.04',
      '390.50',
      '393.12',
      '393.14',
      '390.39',
      '390.50',
      '391.92',
      '392.76',
      '393.73',
      '393.68',
      '393.22',
      '393.22',
      '392.70',
      '392.45',
      '392.17',
      '391.85',
      '396.06',
      '396.13',
      '395.55',
      '395.06',
      '394.84',
      '394.50',
      '394.15',
      '393.91',
      '393.55',
      '393.74',
      '393.29',
      '393.49',
      '393.46',
      '393.50',
      '393.24',
      '393.13',
      '392.99',
      '392.96',
      '392.86',
      '391.37',
      '391.09',
      '387.88',
      '390.44',
      '388.35',
      '390.00',
      '390.83',
      '388.97',
      '387.56',
      '388.31',
      '388.53',
      '388.25',
      '388.44',
      '389.62',
      '391.11',
      '390.71',
      '392.53',
      '390.58',
      '390.17',
      '389.07',
      '388.01',
      '395.08',
      '395.84',
      '395.33',
      '395.73',
      '397.31',
      '397.07',
      '396.14',
      '394.86',
      '397.13',
      '397.26',
      '396.69',
      '396.19',
      '397.36',
      '398.14',
      '397.60',
      '395.84',
      '397.07',
      '397.96',
      '397.64',
      '397.12',
      '397.73',
      '396.37',
      '396.32',
      '398.29',
      '397.48',
      '397.32',
      '396.56',
      '393.90',
      '393.55',
      '396.99',
      '396.72',
      '395.18',
      '395.71',
      '395.15',
      '397.12',
      '396.90',
      '397.21',
      '397.20',
      '397.31',
      '397.54',
      '396.17',
      '395.24',
      '394.10',
      '394.58',
      '396.22',
      '394.62',
      '395.74',
      '394.55',
      '395.91',
      '395.26',
      '395.04',
      '394.25',
      '394.75',
      '395.26',
      '393.97',
      '395.26',
      '394.59',
      '394.93',
      '395.37',
      '394.30',
      '395.15',
      '393.64',
      '392.24',
      '391.23',
      '392.61',
      '391.79',
      '394.29',
      '392.62',
      '391.91',
      '393.13',
      '391.92',
      '391.66',
      '390.81',
      '391.78',
      '390.61',
      '392.93',
      '390.81',
      '391.12',
      '391.89',
      '392.33',
      '392.74',
      '396.55',
      '396.87',
      '396.81',
      '397.23',
      '397.20',
      '397.53',
      '397.39',
      '397.23',
      '397.38',
      '397.24',
      '397.30',
      '397.22',
      '396.98',
      '393.95',
      '389.49',
      '392.24',
      '394.28',
      '393.70',
      '394.18',
      '390.80',
      '391.65',
      '391.10',
      '391.52',
      '391.63',
      '391.67',
      '391.20',
      '393.31',
      '392.52',
      '393.41',
      '395.52',
      '395.25',
      '394.73',
      '396.44',
      '396.01',
      '395.57',
      '396.18',
      '397.15',
      '396.28',
      '395.91',
      '398.47',
      '398.75',
      '398.21',
      '397.59',
      '396.77',
      '397.39',
      '395.83',
      '396.39',
      '396.14',
      '396.23',
      '398.23',
      '398.71',
      '399.11',
      '399.46',
      '399.51',
      '398.97',
      '397.34',
      '400.70',
      '400.21',
      '400.91',
      '400.80',
      '401.02',
      '399.15',
      '399.53',
      '398.92',
      '397.88',
      '400.07',
      '400.63',
      '399.69',
      '400.04',
      '399.78',
      '399.99',
      '398.46',
      '400.17',
      '399.15',
      '399.14',
      '399.96',
      '398.51',
      '399.24',
      '398.18',
      '398.51',
      '399.08',
      '399.33',
      '398.99',
      '399.48',
      '398.85',
      '399.68',
      '400.08',
      '399.88',
      '401.28',
      '399.84',
      '396.70',
      '398.43',
      '396.42',
      '397.56',
      '395.66',
      '397.38',
      '394.74',
      '395.70',
      '395.66',
      '396.22',
      '395.41',
      '395.38',
      '395.70',
      '395.87',
      '395.57',
      '398.53',
      '397.31',
      '397.07',
      '398.95',
      '398.71',
      '398.03',
      '398.27',
      '396.89',
      '391.29',
      '392.24',
      '392.67',
      '392.21',
      '391.70',
      '393.47',
      '395.90',
      '398.02',
      '398.63',
      '398.76',
      '399.11',
      '399.10',
      '399.24',
      '396.25',
      '395.96',
      '396.09',
      '396.30',
      '396.49',
      '396.42',
      '396.43',
      '396.67',
      '396.86',
      '396.85',
      '396.74',
      '397.17',
      '397.18',
      '397.50',
      '397.55',
      '397.85',
      '397.73',
      '397.87',
      '397.97',
      '398.03',
      '398.04',
      '398.00',
      '397.95',
      '398.21',
      '398.11',
      '396.46',
      '396.05',
      '395.91',
      '395.94',
      '395.64',
      '395.24',
      '395.32',
      '395.48',
      '395.34',
      '395.03',
      '394.72',
      '394.92',
      '395.90',
      '399.30',
      '399.11',
      '398.74',
      '399.17',
      '399.46',
      '399.43',
      '399.26',
      '398.86',
      '398.95',
      '398.81',
      '398.85',
      '398.51',
      '398.09',
      '397.66',
      '397.25',
      '396.76',
      '395.95',
      '394.98',
      '395.82',
      '397.07',
      '395.97',
      '395.37',
      '395.01',
      '399.17',
      '398.75',
      '399.63',
      '398.71',
      '398.75',
      '396.41',
      '398.24',
      '397.76',
      '397.50',
      '397.07',
      '397.53',
      '398.07',
      '396.80',
      '395.27',
      '395.97',
      '396.55',
      '395.53',
      '393.55',
      '394.68',
      '396.15',
      '394.16',
      '392.74',
      '393.08',
      '394.24',
      '392.09',
      '394.89',
      '394.05',
      '393.45',
      '394.75',
      '394.18',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    unit: 'V'
  }
];

export const sstChart2 = [
  {
    label: '전압 제어 ON',
    data: [
      '389.27',
      '395.84',
      '382.13',
      '399.56',
      '391.02',
      '386.79',
      '393.45',
      '397.18',
      '384.61',
      '388.93',
      '396.70',
      '381.25',
      '390.38',
      '398.42',
      '385.97',
      '392.84',
      '393.32',
      '389.27',
      '395.84',
      '382.13',
      '399.56',
      '391.02',
      '386.79',
      '393.45',
      '397.18',
      '384.61',
      '388.93',
      '396.70',
      '381.25',
      '390.38',
      '398.42',
      '385.97',
      '392.84',
      '394.01',
      '393.89',
      '393.78',
      '393.83',
      '391.15',
      '390.63',
      '390.58',
      '390.82',
      '391.08',
      '391.11',
      '391.38',
      '389.27',
      '395.84',
      '382.13',
      '399.56',
      '391.02',
      '386.79',
      '393.45',
      '397.18',
      '384.61',
      '388.93',
      '396.70',
      '381.25',
      '390.38',
      '398.42',
      '385.97',
      '392.84',
      '390.48',
      '390.29',
      '390.02',
      '389.83',
      '388.75',
      '388.21',
      '387.96',
      '384.49',
      '386.25',
      '389.27',
      '395.84',
      '382.13',
      '399.56',
      '391.02',
      '386.79',
      '393.45',
      '397.18',
      '384.61',
      '388.93',
      '396.70',
      '381.25',
      '390.38',
      '398.42',
      '385.97',
      '392.84',
      '384.02',
      '384.73',
      '384.40',
      '383.90',
      '384.80',
      '385.64',
      '384.62',
      '383.93',
      '384.64',
      '384.89',
      '384.83',
      '384.79',
      '383.15',
      '389.27',
      '395.84',
      '382.13',
      '399.56',
      '391.02',
      '386.79',
      '393.45',
      '397.18',
      '384.61',
      '388.93',
      '396.70',
      '381.25',
      '390.38',
      '398.42',
      '385.97',
      '392.84',
      '383.78',
      '385.54',
      '384.69',
      '384.16',
      '385.22',
      '384.07',
      '384.34',
      '384.24',
      '384.69',
      '384.02',
      '385.95',
      '386.27',
      '385.15',
      '385.59',
      '385.15',
      '384.48',
      '384.90',
      '383.85',
      '385.08',
      '385.85',
      '384.89',
      '384.65',
      '384.99',
      '385.43',
      '384.16',
      '384.01',
      '385.28',
      '384.26',
      '384.78',
      '386.31',
      '384.91',
      '385.08',
      '385.14',
      '386.03',
      '386.96',
      '386.87',
      '387.01',
      '386.58',
      '386.80',
      '386.36',
      '387.23',
      '385.93',
      '386.00',
      '385.22',
      '385.76',
      '385.51',
      '384.62',
      '384.82',
      '385.40',
      '385.39',
      '384.48',
      '384.92',
      '385.19',
      '384.94',
      '384.60',
      '384.80',
      '385.62',
      '384.91',
      '383.81',
      '386.07',
      '383.76',
      '385.92',
      '384.43',
      '384.71',
      '385.38',
      '385.74',
      '386.13',
      '384.29',
      '385.16',
      '384.71',
      '385.25',
      '386.95',
      '388.07',
      '388.55',
      '389.55',
      '389.45',
      '389.49',
      '389.68',
      '389.66',
      '389.84',
      '389.85',
      '389.68',
      '389.58',
      '389.70',
      '389.90',
      '389.52',
      '388.01',
      '387.32',
      '385.88',
      '385.70',
      '386.63',
      '386.89',
      '385.88',
      '386.11',
      '385.15',
      '385.84',
      '384.34',
      '386.65',
      '386.35',
      '386.59',
      '385.84',
      '387.46',
      '386.83',
      '387.09',
      '386.85',
      '387.69',
      '386.90',
      '386.85',
      '387.63',
      '387.47',
      '386.73',
      '386.61',
      '386.56',
      '385.80',
      '386.49',
      '386.44',
      '386.98',
      '386.97',
      '386.31',
      '386.36',
      '387.34',
      '387.21',
      '388.27',
      '387.74',
      '385.37',
      '387.69',
      '386.33',
      '386.07',
      '387.47',
      '387.98',
      '387.45',
      '387.27',
      '388.09',
      '387.38',
      '386.97',
      '386.80',
      '387.19',
      '387.96',
      '386.98',
      '386.72',
      '388.12',
      '387.41',
      '386.97',
      '387.66',
      '386.61',
      '385.07',
      '387.97',
      '384.46',
      '385.74',
      '384.62',
      '387.26',
      '385.39',
      '384.34',
      '385.57',
      '384.80',
      '386.41',
      '385.91',
      '386.63',
      '386.85',
      '388.88',
      '387.93',
      '388.69',
      '389.26',
      '388.69',
      '387.55',
      '388.37',
      '386.43',
      '386.30',
      '386.79',
      '387.42',
      '386.97',
      '386.03',
      '386.30',
      '386.23',
      '386.12',
      '385.92',
      '386.91',
      '386.65',
      '388.56',
      '386.55',
      '388.63',
      '387.46',
      '388.44',
      '387.82',
      '388.36',
      '387.53',
      '388.38',
      '388.62',
      '386.05',
      '387.59',
      '386.34',
      '387.11',
      '389.02',
      '389.61',
      '389.86',
      '389.72',
      '389.90',
      '389.98',
      '389.87',
      '389.58',
      '389.49',
      '389.39',
      '389.44',
      '389.44',
      '389.48',
      '389.50',
      '389.88',
      '389.80',
      '389.92',
      '389.67',
      '389.43',
      '389.46',
      '389.59',
      '389.83',
      '389.72',
      '389.40',
      '389.81',
      '389.75',
      '389.75',
      '389.75',
      '389.90',
      '389.93',
      '389.58',
      '389.44',
      '389.23',
      '389.35',
      '389.08',
      '389.18',
      '388.54',
      '387.17',
      '386.74',
      '385.75',
      '387.14',
      '386.39',
      '386.28',
      '384.90',
      '385.56',
      '385.21',
      '386.56',
      '386.14',
      '386.06',
      '385.96',
      '385.16',
      '386.75',
      '386.93',
      '387.71',
      '386.47',
      '386.43',
      '386.23',
      '385.94',
      '387.54',
      '386.60',
      '386.90',
      '385.89',
      '386.26',
      '386.28',
      '386.78',
      '386.79',
      '386.75',
      '386.63',
      '386.33',
      '386.45',
      '386.46',
      '386.08',
      '386.19',
      '385.78',
      '385.95',
      '386.19',
      '385.16',
      '385.79',
      '386.14',
      '386.16',
      '385.38',
      '385.46',
      '385.81',
      '385.86',
      '385.53',
      '385.58',
      '385.57',
      '385.66',
      '385.50',
      '385.98',
      '385.93',
      '386.16',
      '385.20',
      '386.05',
      '386.14',
      '385.69',
      '385.53',
      '385.89',
      '386.83',
      '387.07',
      '387.51',
      '387.54',
      '387.14',
      '386.32',
      '387.37',
      '387.22',
      '387.32',
      '387.17',
      '387.33',
      '387.50',
      '387.81',
      '387.98',
      '387.99',
      '388.16',
      '387.93',
      '386.97',
      '387.09',
      '387.49',
      '387.37',
      '387.59',
      '387.54',
      '387.53',
      '387.01',
      '387.03',
      '386.54',
      '383.98',
      '386.45',
      '386.52',
      '386.14',
      '385.92',
      '385.78',
      '386.31',
      '388.43',
      '388.87',
      '388.23',
      '388.37',
      '386.98',
      '388.09',
      '387.67',
      '387.61',
      '384.92',
      '387.65',
      '387.89',
      '387.03',
      '385.75',
      '387.65',
      '387.59',
      '387.44',
      '387.15',
      '384.59',
      '387.27',
      '386.97',
      '386.86',
      '386.29',
      '385.58',
      '385.96',
      '386.06',
      '384.74',
      '382.86',
      '384.72',
      '383.60',
      '383.33',
      '383.01',
      '382.44',
      '382.24',
      '380.74',
      '383.29',
      '383.31',
      '380.63',
      '380.74',
      '382.12',
      '382.94',
      '383.89',
      '383.84',
      '383.39',
      '383.39',
      '382.88',
      '382.64',
      '382.37',
      '382.05',
      '386.16',
      '386.23',
      '385.66',
      '385.18',
      '384.97',
      '384.64',
      '384.30',
      '384.06',
      '383.71',
      '383.90',
      '383.46',
      '383.65',
      '383.62',
      '383.66',
      '383.41',
      '383.30',
      '383.17',
      '383.14',
      '383.04',
      '381.59',
      '381.31',
      '378.18',
      '380.68',
      '378.64',
      '380.25',
      '381.06',
      '379.25',
      '377.87',
      '378.60',
      '378.82',
      '378.54',
      '378.73',
      '379.88',
      '381.33',
      '380.94',
      '382.72',
      '380.82',
      '380.42',
      '379.34',
      '378.31',
      '385.20',
      '385.94',
      '385.45',
      '385.84',
      '387.38',
      '387.14',
      '386.24',
      '384.99',
      '387.20',
      '387.33',
      '386.77',
      '386.29',
      '387.43',
      '388.19',
      '387.66',
      '385.94',
      '387.14',
      '388.01',
      '387.70',
      '387.19',
      '387.79',
      '386.46',
      '386.41',
      '388.33',
      '387.54',
      '387.39',
      '386.65',
      '384.05',
      '383.71',
      '387.07',
      '386.80',
      '385.30',
      '385.82',
      '385.27',
      '387.19',
      '386.98',
      '387.28',
      '387.27',
      '387.38',
      '387.60',
      '386.27',
      '385.36',
      '384.25',
      '384.72',
      '386.31',
      '384.75',
      '385.85',
      '384.69',
      '386.01',
      '385.38',
      '385.16',
      '384.39',
      '384.88',
      '385.38',
      '384.12',
      '385.38',
      '384.73',
      '385.06',
      '385.49',
      '384.44',
      '385.27',
      '383.80',
      '382.43',
      '381.45',
      '382.79',
      '382.00',
      '384.43',
      '382.80',
      '382.11',
      '383.30',
      '382.12',
      '381.87',
      '381.04',
      '381.99',
      '380.84',
      '383.11',
      '381.04',
      '381.34',
      '382.09',
      '382.52',
      '382.92',
      '386.64',
      '386.95',
      '386.89',
      '387.30',
      '387.27',
      '387.59',
      '387.46',
      '387.30',
      '387.45',
      '387.31',
      '387.37',
      '387.29',
      '387.06',
      '384.10',
      '379.75',
      '382.43',
      '384.42',
      '383.86',
      '384.33',
      '381.03',
      '381.86',
      '381.32',
      '381.73',
      '381.84',
      '381.88',
      '381.42',
      '383.48',
      '382.71',
      '383.57',
      '385.63',
      '385.37',
      '384.86',
      '386.53',
      '386.11',
      '385.68',
      '386.28',
      '387.22',
      '386.37',
      '386.01',
      '388.51',
      '388.78',
      '388.25',
      '387.65',
      '386.85',
      '387.46',
      '385.93',
      '386.48',
      '386.24',
      '386.32',
      '388.27',
      '388.74',
      '389.13',
      '389.47',
      '389.52',
      '389.00',
      '387.41',
      '390.68',
      '390.20',
      '390.89',
      '390.78',
      '390.99',
      '389.17',
      '389.54',
      '388.95',
      '387.93',
      '390.07',
      '390.61',
      '389.70',
      '390.04',
      '389.79',
      '389.99',
      '388.50',
      '390.17',
      '389.17',
      '389.16',
      '389.96',
      '388.55',
      '389.26',
      '388.23',
      '388.55',
      '389.10',
      '389.35',
      '389.02',
      '389.49',
      '388.88',
      '389.69',
      '390.08',
      '389.88',
      '391.25',
      '389.84',
      '386.78',
      '388.47',
      '386.51',
      '387.62',
      '385.77',
      '387.45',
      '384.87',
      '385.81',
      '385.77',
      '386.31',
      '385.52',
      '385.50',
      '385.81',
      '385.97',
      '385.68',
      '388.57',
      '387.38',
      '387.14',
      '388.98',
      '388.74',
      '388.08',
      '388.31',
      '386.97',
      '381.51',
      '382.43',
      '382.85',
      '382.40',
      '381.91',
      '383.63',
      '386.00',
      '388.07',
      '388.66',
      '388.79',
      '389.13',
      '389.12',
      '389.26',
      '386.34',
      '386.06',
      '386.19',
      '386.39',
      '386.58',
      '386.51',
      '386.52',
      '386.75',
      '386.94',
      '386.93',
      '386.82',
      '387.24',
      '387.25',
      '387.56',
      '387.61',
      '387.90',
      '387.79',
      '387.92',
      '388.02',
      '388.08',
      '388.09',
      '388.05',
      '388.00',
      '388.25',
      '388.16',
      '386.55',
      '386.15',
      '386.01',
      '386.04',
      '385.75',
      '385.36',
      '385.44',
      '385.59',
      '385.46',
      '385.15',
      '384.85',
      '385.05',
      '386.00',
      '389.32',
      '389.13',
      '388.77',
      '389.19',
      '389.47',
      '389.44',
      '389.28',
      '388.89',
      '388.98',
      '388.84',
      '388.88',
      '388.55',
      '388.14',
      '387.72',
      '387.32',
      '386.84',
      '386.05',
      '385.11',
      '385.92',
      '387.14',
      '386.07',
      '385.49',
      '385.13',
      '389.19',
      '388.78',
      '389.64',
      '388.74',
      '388.78',
      '386.50',
      '388.28',
      '387.82',
      '387.56',
      '387.14',
      '387.59',
      '388.12',
      '386.88',
      '385.39',
      '386.07',
      '386.64',
      '385.64',
      '383.71',
      '384.81',
      '386.25',
      '384.31',
      '382.92',
      '383.25',
      '384.38',
      '382.29',
      '385.02',
      '384.20',
      '383.61',
      '384.88',
      '384.33',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    unit: 'V'
  },
  {
    label: '전압 제어 OFF',
    data: [
      '402.27',
      '402.43',
      '402.35',
      '402.70',
      '402.82',
      '402.63',
      '402.86',
      '402.75',
      '402.77',
      '402.90',
      '402.98',
      '403.14',
      '403.30',
      '403.27',
      '403.51',
      '403.81',
      '403.67',
      '403.31',
      '403.41',
      '403.14',
      '403.01',
      '403.10',
      '403.34',
      '403.42',
      '403.37',
      '403.47',
      '403.50',
      '403.46',
      '403.47',
      '403.28',
      '403.31',
      '403.46',
      '403.84',
      '404.14',
      '404.12',
      '404.11',
      '403.99',
      '403.88',
      '403.93',
      '401.18',
      '400.65',
      '400.59',
      '400.84',
      '401.11',
      '401.14',
      '401.42',
      '401.29',
      '401.54',
      '402.81',
      '405.36',
      '401.41',
      '400.81',
      '400.57',
      '400.71',
      '400.54',
      '400.58',
      '400.62',
      '400.54',
      '400.49',
      '400.30',
      '400.02',
      '399.83',
      '398.72',
      '398.16',
      '397.91',
      '394.35',
      '396.15',
      '395.71',
      '395.48',
      '393.15',
      '395.65',
      '393.63',
      '393.88',
      '392.83',
      '393.47',
      '393.18',
      '393.63',
      '392.30',
      '392.74',
      '392.18',
      '393.32',
      '393.87',
      '394.59',
      '394.26',
      '393.74',
      '394.67',
      '395.53',
      '394.48',
      '393.77',
      '394.50',
      '394.76',
      '394.70',
      '394.66',
      '392.97',
      '394.11',
      '395.70',
      '394.69',
      '393.44',
      '391.34',
      '393.86',
      '394.67',
      '394.12',
      '394.30',
      '394.00',
      '394.21',
      '392.89',
      '393.62',
      '395.43',
      '394.55',
      '394.01',
      '395.10',
      '393.92',
      '394.19',
      '394.09',
      '394.55',
      '393.87',
      '395.85',
      '396.17',
      '395.03',
      '395.48',
      '395.03',
      '394.34',
      '394.77',
      '393.69',
      '394.95',
      '395.74',
      '394.76',
      '394.51',
      '394.86',
      '395.31',
      '385.15',
      '384.48',
      '384.90',
      '383.85',
      '385.08',
      '385.85',
      '384.89',
      '384.65',
      '384.99',
      '385.43',
      '384.16',
      '384.01',
      '385.28',
      '384.26',
      '384.78',
      '386.31',
      '384.91',
      '385.08',
      '385.14',
      '386.03',
      '385.15',
      '384.48',
      '384.90',
      '383.85',
      '385.08',
      '385.85',
      '384.89',
      '384.65',
      '384.99',
      '385.43',
      '384.16',
      '384.01',
      '385.28',
      '384.26',
      '384.78',
      '386.31',
      '384.91',
      '385.08',
      '385.14',
      '386.03',
      '386.96',
      '386.87',
      '387.01',
      '386.58',
      '386.80',
      '386.36',
      '387.23',
      '395.26',
      '395.63',
      '396.03',
      '394.14',
      '395.04',
      '394.57',
      '395.13',
      '396.87',
      '398.02',
      '398.51',
      '399.54',
      '399.44',
      '399.48',
      '399.67',
      '399.65',
      '399.84',
      '399.85',
      '399.67',
      '399.57',
      '399.69',
      '399.90',
      '399.51',
      '397.96',
      '397.25',
      '395.77',
      '395.59',
      '396.54',
      '396.81',
      '395.77',
      '396.01',
      '395.03',
      '395.73',
      '394.19',
      '396.56',
      '396.26',
      '396.50',
      '395.73',
      '397.39',
      '396.75',
      '397.02',
      '396.77',
      '397.63',
      '396.82',
      '396.77',
      '397.57',
      '397.41',
      '396.65',
      '396.52',
      '396.47',
      '395.69',
      '396.40',
      '396.35',
      '396.90',
      '396.89',
      '396.22',
      '396.27',
      '397.27',
      '397.14',
      '398.23',
      '397.68',
      '395.25',
      '397.63',
      '396.24',
      '395.97',
      '397.41',
      '397.93',
      '397.38',
      '397.20',
      '398.04',
      '397.31',
      '396.89',
      '396.72',
      '397.12',
      '397.91',
      '396.90',
      '396.64',
      '398.07',
      '397.34',
      '396.89',
      '397.60',
      '396.52',
      '394.94',
      '397.92',
      '394.32',
      '395.63',
      '394.48',
      '397.19',
      '395.27',
      '394.19',
      '395.46',
      '394.67',
      '396.32',
      '395.81',
      '396.54',
      '396.77',
      '398.85',
      '397.88',
      '398.66',
      '399.24',
      '398.66',
      '397.49',
      '398.33',
      '396.34',
      '396.21',
      '396.71',
      '397.35',
      '396.89',
      '395.93',
      '396.21',
      '396.13',
      '396.02',
      '395.82',
      '396.83',
      '396.56',
      '398.52',
      '396.46',
      '398.59',
      '397.39',
      '398.40',
      '397.76',
      '398.32',
      '397.47',
      '398.34',
      '398.58',
      '395.95',
      '397.53',
      '396.25',
      '397.04',
      '398.99',
      '399.60',
      '399.86',
      '399.71',
      '399.90',
      '399.98',
      '399.87',
      '399.57',
      '399.48',
      '399.37',
      '399.43',
      '399.43',
      '399.47',
      '399.49',
      '399.88',
      '399.79',
      '399.92',
      '399.66',
      '399.42',
      '399.45',
      '399.58',
      '399.83',
      '399.71',
      '399.38',
      '399.81',
      '399.74',
      '399.74',
      '399.74',
      '399.90',
      '399.93',
      '399.57',
      '399.43',
      '399.21',
      '399.33',
      '399.06',
      '399.16',
      '398.50',
      '397.10',
      '396.66',
      '395.64',
      '397.07',
      '396.30',
      '396.18',
      '394.77',
      '395.45',
      '395.09',
      '396.47',
      '396.04',
      '395.96',
      '395.86',
      '395.04',
      '396.67',
      '396.85',
      '397.65',
      '396.38',
      '396.34',
      '396.13',
      '395.84',
      '397.48',
      '396.51',
      '396.82',
      '395.78',
      '396.16',
      '396.18',
      '396.70',
      '396.71',
      '396.67',
      '396.54',
      '396.24',
      '396.36',
      '396.37',
      '395.98',
      '396.09',
      '395.67',
      '395.85',
      '396.09',
      '395.04',
      '395.68',
      '396.04',
      '396.06',
      '395.26',
      '395.34',
      '395.70',
      '395.75',
      '395.42',
      '395.47',
      '395.46',
      '395.55',
      '395.38',
      '395.88',
      '395.83',
      '396.06',
      '395.08',
      '395.95',
      '396.04',
      '395.58',
      '395.42',
      '395.78',
      '396.75',
      '396.99',
      '397.45',
      '397.48',
      '397.07',
      '396.23',
      '397.30',
      '397.15',
      '397.25',
      '397.10',
      '397.26',
      '397.44',
      '397.75',
      '397.93',
      '397.94',
      '398.11',
      '397.88',
      '396.89',
      '397.02',
      '397.43',
      '397.30',
      '397.53',
      '397.48',
      '397.47',
      '396.93',
      '396.95',
      '396.45',
      '393.83',
      '396.36',
      '396.43',
      '396.04',
      '395.82',
      '395.67',
      '396.22',
      '398.39',
      '398.84',
      '398.18',
      '398.33',
      '396.90',
      '398.04',
      '397.61',
      '397.55',
      '394.79',
      '397.59',
      '397.84',
      '396.95',
      '395.64',
      '397.59',
      '397.53',
      '397.37',
      '397.08',
      '394.45',
      '397.20',
      '396.89',
      '396.78',
      '396.19',
      '395.47',
      '395.86',
      '395.96',
      '394.61',
      '392.68',
      '394.58',
      '393.44',
      '393.16',
      '392.83',
      '392.25',
      '392.04',
      '390.50',
      '393.12',
      '393.14',
      '390.39',
      '390.50',
      '391.92',
      '392.76',
      '393.73',
      '393.68',
      '393.22',
      '393.22',
      '392.70',
      '392.45',
      '392.17',
      '391.85',
      '396.06',
      '396.13',
      '395.55',
      '395.06',
      '394.84',
      '394.50',
      '394.15',
      '393.91',
      '393.55',
      '393.74',
      '393.29',
      '393.49',
      '393.46',
      '393.50',
      '393.24',
      '393.13',
      '392.99',
      '392.96',
      '392.86',
      '391.37',
      '391.09',
      '387.88',
      '390.44',
      '388.35',
      '390.00',
      '390.83',
      '388.97',
      '387.56',
      '388.31',
      '388.53',
      '388.25',
      '388.44',
      '389.62',
      '391.11',
      '390.71',
      '392.53',
      '390.58',
      '390.17',
      '389.07',
      '388.01',
      '395.08',
      '395.84',
      '395.33',
      '395.73',
      '397.31',
      '397.07',
      '396.14',
      '394.86',
      '397.13',
      '397.26',
      '396.69',
      '396.19',
      '397.36',
      '398.14',
      '397.60',
      '395.84',
      '397.07',
      '397.96',
      '397.64',
      '397.12',
      '397.73',
      '396.37',
      '396.32',
      '398.29',
      '397.48',
      '397.32',
      '396.56',
      '393.90',
      '393.55',
      '396.99',
      '396.72',
      '395.18',
      '395.71',
      '395.15',
      '397.12',
      '396.90',
      '397.21',
      '397.20',
      '397.31',
      '397.54',
      '396.17',
      '395.24',
      '394.10',
      '394.58',
      '396.22',
      '394.62',
      '395.74',
      '394.55',
      '395.91',
      '395.26',
      '395.04',
      '394.25',
      '394.75',
      '395.26',
      '393.97',
      '395.26',
      '394.59',
      '394.93',
      '395.37',
      '394.30',
      '395.15',
      '393.64',
      '392.24',
      '391.23',
      '392.61',
      '391.79',
      '394.29',
      '392.62',
      '391.91',
      '393.13',
      '391.92',
      '391.66',
      '390.81',
      '391.78',
      '390.61',
      '392.93',
      '390.81',
      '391.12',
      '391.89',
      '392.33',
      '392.74',
      '396.55',
      '396.87',
      '396.81',
      '397.23',
      '397.20',
      '397.53',
      '397.39',
      '397.23',
      '397.38',
      '397.24',
      '397.30',
      '397.22',
      '396.98',
      '393.95',
      '389.49',
      '392.24',
      '394.28',
      '393.70',
      '394.18',
      '390.80',
      '391.65',
      '391.10',
      '391.52',
      '391.63',
      '391.67',
      '391.20',
      '393.31',
      '392.52',
      '393.41',
      '395.52',
      '395.25',
      '394.73',
      '396.44',
      '396.01',
      '395.57',
      '396.18',
      '397.15',
      '396.28',
      '395.91',
      '398.47',
      '398.75',
      '398.21',
      '397.59',
      '396.77',
      '397.39',
      '395.83',
      '396.39',
      '396.14',
      '396.23',
      '398.23',
      '398.71',
      '399.11',
      '399.46',
      '399.51',
      '398.97',
      '397.34',
      '400.70',
      '400.21',
      '400.91',
      '400.80',
      '401.02',
      '399.15',
      '399.53',
      '398.92',
      '397.88',
      '400.07',
      '400.63',
      '399.69',
      '400.04',
      '399.78',
      '399.99',
      '398.46',
      '400.17',
      '399.15',
      '399.14',
      '399.96',
      '398.51',
      '399.24',
      '398.18',
      '398.51',
      '399.08',
      '399.33',
      '398.99',
      '399.48',
      '398.85',
      '399.68',
      '400.08',
      '399.88',
      '401.28',
      '399.84',
      '396.70',
      '398.43',
      '396.42',
      '397.56',
      '395.66',
      '397.38',
      '394.74',
      '395.70',
      '395.66',
      '396.22',
      '395.41',
      '395.38',
      '395.70',
      '395.87',
      '395.57',
      '398.53',
      '397.31',
      '397.07',
      '398.95',
      '398.71',
      '398.03',
      '398.27',
      '396.89',
      '391.29',
      '392.24',
      '392.67',
      '392.21',
      '391.70',
      '393.47',
      '395.90',
      '398.02',
      '398.63',
      '398.76',
      '399.11',
      '399.10',
      '399.24',
      '396.25',
      '395.96',
      '396.09',
      '396.30',
      '396.49',
      '396.42',
      '396.43',
      '396.67',
      '396.86',
      '396.85',
      '396.74',
      '397.17',
      '397.18',
      '397.50',
      '397.55',
      '397.85',
      '397.73',
      '397.87',
      '397.97',
      '398.03',
      '398.04',
      '398.00',
      '397.95',
      '398.21',
      '398.11',
      '396.46',
      '396.05',
      '395.91',
      '395.94',
      '395.64',
      '395.24',
      '395.32',
      '395.48',
      '395.34',
      '395.03',
      '394.72',
      '394.92',
      '395.90',
      '399.30',
      '399.11',
      '398.74',
      '399.17',
      '399.46',
      '399.43',
      '399.26',
      '398.86',
      '398.95',
      '398.81',
      '398.85',
      '398.51',
      '398.09',
      '397.66',
      '397.25',
      '396.76',
      '395.95',
      '394.98',
      '395.82',
      '397.07',
      '395.97',
      '395.37',
      '395.01',
      '399.17',
      '398.75',
      '399.63',
      '398.71',
      '398.75',
      '396.41',
      '398.24',
      '397.76',
      '397.50',
      '397.07',
      '397.53',
      '398.07',
      '396.80',
      '395.27',
      '395.97',
      '396.55',
      '395.53',
      '393.55',
      '394.68',
      '396.15',
      '394.16',
      '392.74',
      '393.08',
      '394.24',
      '392.09',
      '394.89',
      '394.05',
      '393.45',
      '394.75',
      '394.18',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    unit: 'V'
  }
];

export const sstChart3 = [
  {
    label: '전압 제어 ON',
    data: [
      '392.21',
      '392.37',
      '392.29',
      '392.63',
      '392.75',
      '392.56',
      '392.79',
      '392.68',
      '392.70',
      '392.83',
      '392.91',
      '393.06',
      '393.22',
      '393.19',
      '393.42',
      '393.71',
      '393.58',
      '393.23',
      '393.32',
      '393.06',
      '392.93',
      '393.02',
      '393.26',
      '393.33',
      '393.29',
      '393.38',
      '393.41',
      '393.37',
      '393.38',
      '393.20',
      '393.23',
      '393.37',
      '393.74',
      '394.04',
      '394.02',
      '394.01',
      '393.89',
      '393.78',
      '393.83',
      '391.15',
      '390.63',
      '390.58',
      '390.82',
      '391.08',
      '391.11',
      '391.38',
      '391.26',
      '391.50',
      '392.74',
      '395.23',
      '391.37',
      '390.79',
      '390.56',
      '390.69',
      '390.53',
      '390.57',
      '390.60',
      '390.53',
      '390.48',
      '390.29',
      '390.02',
      '389.83',
      '388.75',
      '388.21',
      '387.96',
      '384.49',
      '386.25',
      '385.82',
      '385.59',
      '383.32',
      '385.76',
      '383.79',
      '384.03',
      '383.01',
      '383.63',
      '383.35',
      '383.79',
      '382.49',
      '382.92',
      '382.38',
      '383.49',
      '384.02',
      '384.73',
      '384.40',
      '383.90',
      '384.80',
      '385.64',
      '384.62',
      '383.93',
      '384.64',
      '384.89',
      '384.83',
      '384.79',
      '383.15',
      '384.26',
      '385.81',
      '384.82',
      '383.60',
      '381.56',
      '384.01',
      '384.80',
      '384.27',
      '384.44',
      '384.15',
      '384.35',
      '383.07',
      '383.78',
      '385.54',
      '384.69',
      '384.16',
      '385.22',
      '384.07',
      '384.34',
      '384.24',
      '384.69',
      '384.02',
      '385.95',
      '386.27',
      '385.15',
      '385.59',
      '385.15',
      '384.48',
      '384.90',
      '383.85',
      '385.08',
      '385.85',
      '384.89',
      '384.65',
      '384.99',
      '385.43',
      '384.16',
      '384.01',
      '385.28',
      '384.26',
      '384.78',
      '386.31',
      '384.91',
      '385.08',
      '385.14',
      '386.03',
      '386.96',
      '386.87',
      '387.01',
      '386.58',
      '386.80',
      '386.36',
      '387.23',
      '385.93',
      '386.00',
      '385.22',
      '385.76',
      '385.51',
      '384.62',
      '384.82',
      '385.40',
      '385.39',
      '384.48',
      '384.92',
      '385.19',
      '384.94',
      '384.60',
      '384.80',
      '385.62',
      '384.91',
      '383.81',
      '386.07',
      '383.76',
      '385.92',
      '384.43',
      '384.71',
      '385.38',
      '385.74',
      '386.13',
      '384.29',
      '385.16',
      '384.71',
      '385.25',
      '386.95',
      '388.07',
      '388.55',
      '389.55',
      '389.45',
      '389.49',
      '389.68',
      '389.66',
      '389.84',
      '389.85',
      '389.68',
      '389.58',
      '389.70',
      '389.90',
      '389.52',
      '388.01',
      '387.32',
      '385.88',
      '385.70',
      '386.63',
      '386.89',
      '385.88',
      '386.11',
      '385.15',
      '385.84',
      '384.34',
      '386.65',
      '386.35',
      '386.59',
      '385.84',
      '387.46',
      '386.83',
      '387.09',
      '386.85',
      '387.69',
      '386.90',
      '386.85',
      '387.63',
      '387.47',
      '386.73',
      '386.61',
      '386.56',
      '385.80',
      '386.49',
      '386.44',
      '386.98',
      '386.97',
      '386.31',
      '386.36',
      '387.34',
      '387.21',
      '388.27',
      '387.74',
      '385.37',
      '387.69',
      '386.33',
      '386.07',
      '387.47',
      '387.98',
      '387.45',
      '387.27',
      '388.09',
      '387.38',
      '386.97',
      '386.80',
      '387.19',
      '387.96',
      '386.98',
      '386.72',
      '388.12',
      '387.41',
      '386.97',
      '387.66',
      '386.61',
      '385.07',
      '387.97',
      '384.46',
      '385.74',
      '384.62',
      '387.26',
      '385.39',
      '384.34',
      '385.57',
      '384.80',
      '386.41',
      '385.91',
      '386.63',
      '386.85',
      '388.88',
      '387.93',
      '388.69',
      '389.26',
      '388.69',
      '387.55',
      '388.37',
      '386.43',
      '386.30',
      '386.79',
      '387.42',
      '386.97',
      '386.03',
      '386.30',
      '386.23',
      '386.12',
      '385.92',
      '386.91',
      '386.65',
      '388.56',
      '386.55',
      '388.63',
      '387.46',
      '388.44',
      '387.82',
      '388.36',
      '387.53',
      '388.38',
      '388.62',
      '386.05',
      '387.59',
      '386.34',
      '387.11',
      '389.02',
      '389.61',
      '389.86',
      '389.72',
      '389.90',
      '389.98',
      '389.87',
      '389.58',
      '389.49',
      '389.39',
      '389.44',
      '389.44',
      '389.48',
      '389.50',
      '389.88',
      '389.80',
      '389.92',
      '389.67',
      '389.43',
      '389.46',
      '389.59',
      '389.83',
      '389.72',
      '389.40',
      '389.81',
      '389.75',
      '389.75',
      '389.75',
      '389.90',
      '389.93',
      '389.58',
      '389.44',
      '389.23',
      '389.35',
      '389.08',
      '389.18',
      '388.54',
      '387.17',
      '386.74',
      '385.75',
      '387.14',
      '386.39',
      '386.28',
      '384.90',
      '385.56',
      '385.21',
      '386.56',
      '386.14',
      '386.06',
      '385.96',
      '385.16',
      '386.75',
      '386.93',
      '387.71',
      '386.47',
      '386.43',
      '386.23',
      '385.94',
      '387.54',
      '386.60',
      '386.90',
      '385.89',
      '386.26',
      '386.28',
      '386.78',
      '386.79',
      '386.75',
      '386.63',
      '386.33',
      '386.45',
      '386.46',
      '386.08',
      '386.19',
      '385.78',
      '385.95',
      '386.19',
      '385.16',
      '385.79',
      '386.14',
      '386.16',
      '385.38',
      '385.46',
      '385.81',
      '385.86',
      '385.53',
      '385.58',
      '385.57',
      '385.66',
      '385.50',
      '385.98',
      '385.93',
      '386.16',
      '385.20',
      '386.05',
      '386.14',
      '385.69',
      '385.53',
      '385.89',
      '386.83',
      '387.07',
      '387.51',
      '387.54',
      '387.14',
      '386.32',
      '387.37',
      '387.22',
      '387.32',
      '387.17',
      '387.33',
      '387.50',
      '387.81',
      '387.98',
      '387.99',
      '388.16',
      '387.93',
      '386.97',
      '387.09',
      '387.49',
      '387.37',
      '387.59',
      '387.54',
      '387.53',
      '387.01',
      '387.03',
      '386.54',
      '383.98',
      '386.45',
      '386.52',
      '386.14',
      '385.92',
      '385.78',
      '386.31',
      '388.43',
      '388.87',
      '388.23',
      '388.37',
      '386.98',
      '388.09',
      '387.67',
      '387.61',
      '384.92',
      '387.65',
      '387.89',
      '387.03',
      '385.75',
      '387.65',
      '387.59',
      '387.44',
      '387.15',
      '384.59',
      '387.27',
      '386.97',
      '386.86',
      '386.29',
      '385.58',
      '385.96',
      '386.06',
      '384.74',
      '382.86',
      '384.72',
      '383.60',
      '383.33',
      '383.01',
      '382.44',
      '382.24',
      '380.74',
      '383.29',
      '383.31',
      '380.63',
      '380.74',
      '382.12',
      '382.94',
      '383.89',
      '383.84',
      '383.39',
      '383.39',
      '382.88',
      '382.64',
      '382.37',
      '382.05',
      '386.16',
      '386.23',
      '385.66',
      '385.18',
      '384.97',
      '384.64',
      '384.30',
      '384.06',
      '383.71',
      '383.90',
      '383.46',
      '383.65',
      '383.62',
      '383.66',
      '383.41',
      '383.30',
      '383.17',
      '383.14',
      '383.04',
      '381.59',
      '381.31',
      '378.18',
      '380.68',
      '378.64',
      '380.25',
      '381.06',
      '379.25',
      '377.87',
      '378.60',
      '378.82',
      '378.54',
      '378.73',
      '379.88',
      '381.33',
      '380.94',
      '382.72',
      '380.82',
      '380.42',
      '379.34',
      '378.31',
      '385.20',
      '385.94',
      '385.45',
      '385.84',
      '387.38',
      '387.14',
      '386.24',
      '384.99',
      '387.20',
      '387.33',
      '386.77',
      '386.29',
      '387.43',
      '388.19',
      '387.66',
      '385.94',
      '387.14',
      '388.01',
      '387.70',
      '387.19',
      '387.79',
      '386.46',
      '386.41',
      '388.33',
      '387.54',
      '387.39',
      '386.65',
      '384.05',
      '383.71',
      '387.07',
      '386.80',
      '385.30',
      '385.82',
      '385.27',
      '387.19',
      '386.98',
      '387.28',
      '387.27',
      '387.38',
      '387.60',
      '386.27',
      '385.36',
      '384.25',
      '384.72',
      '386.31',
      '384.75',
      '385.85',
      '384.69',
      '386.01',
      '385.38',
      '385.16',
      '384.39',
      '384.88',
      '385.38',
      '384.12',
      '385.38',
      '384.73',
      '385.06',
      '385.49',
      '384.44',
      '385.27',
      '383.80',
      '382.43',
      '381.45',
      '382.79',
      '382.00',
      '384.43',
      '382.80',
      '382.11',
      '383.30',
      '382.12',
      '381.87',
      '381.04',
      '381.99',
      '380.84',
      '383.11',
      '381.04',
      '381.34',
      '382.09',
      '382.52',
      '382.92',
      '386.64',
      '386.95',
      '386.89',
      '387.30',
      '387.27',
      '387.59',
      '387.46',
      '387.30',
      '387.45',
      '387.31',
      '387.37',
      '387.29',
      '387.06',
      '384.10',
      '379.75',
      '382.43',
      '384.42',
      '383.86',
      '384.33',
      '381.03',
      '381.86',
      '381.32',
      '381.73',
      '381.84',
      '381.88',
      '381.42',
      '383.48',
      '382.71',
      '383.57',
      '385.63',
      '385.37',
      '384.86',
      '386.53',
      '386.11',
      '385.68',
      '386.28',
      '387.22',
      '386.37',
      '386.01',
      '388.51',
      '388.78',
      '388.25',
      '387.65',
      '386.85',
      '387.46',
      '385.93',
      '386.48',
      '386.24',
      '386.32',
      '388.27',
      '388.74',
      '389.13',
      '389.47',
      '389.52',
      '389.00',
      '387.41',
      '390.68',
      '390.20',
      '390.89',
      '390.78',
      '390.99',
      '389.17',
      '389.54',
      '388.95',
      '387.93',
      '390.07',
      '390.61',
      '389.70',
      '390.04',
      '389.79',
      '389.99',
      '388.50',
      '390.17',
      '389.17',
      '389.16',
      '389.96',
      '388.55',
      '389.26',
      '388.23',
      '388.55',
      '389.10',
      '389.35',
      '389.02',
      '389.49',
      '388.88',
      '389.69',
      '390.08',
      '389.88',
      '391.25',
      '389.84',
      '386.78',
      '388.47',
      '386.51',
      '387.62',
      '385.77',
      '387.45',
      '384.87',
      '385.81',
      '385.77',
      '386.31',
      '385.52',
      '385.50',
      '385.81',
      '385.97',
      '385.68',
      '388.57',
      '387.38',
      '387.14',
      '388.98',
      '388.74',
      '388.08',
      '388.31',
      '386.97',
      '381.51',
      '382.43',
      '382.85',
      '382.40',
      '381.91',
      '383.63',
      '386.00',
      '388.07',
      '388.66',
      '388.79',
      '389.13',
      '389.12',
      '389.26',
      '386.34',
      '386.06',
      '386.19',
      '386.39',
      '386.58',
      '386.51',
      '386.52',
      '386.75',
      '386.94',
      '386.93',
      '386.82',
      '387.24',
      '387.25',
      '387.56',
      '387.61',
      '387.90',
      '387.79',
      '387.92',
      '388.02',
      '388.08',
      '388.09',
      '388.05',
      '388.00',
      '388.25',
      '388.16',
      '386.55',
      '386.15',
      '386.01',
      '386.04',
      '385.75',
      '385.36',
      '385.44',
      '385.59',
      '385.46',
      '385.15',
      '384.85',
      '385.05',
      '386.00',
      '389.32',
      '389.13',
      '388.77',
      '389.19',
      '389.47',
      '389.44',
      '389.28',
      '388.89',
      '388.98',
      '388.84',
      '388.88',
      '388.55',
      '388.14',
      '387.72',
      '387.32',
      '386.84',
      '386.05',
      '385.11',
      '385.92',
      '387.14',
      '386.07',
      '385.49',
      '385.13',
      '389.19',
      '388.78',
      '389.64',
      '388.74',
      '388.78',
      '386.50',
      '388.28',
      '387.82',
      '387.56',
      '387.14',
      '387.59',
      '388.12',
      '386.88',
      '385.39',
      '386.07',
      '386.64',
      '385.64',
      '383.71',
      '384.81',
      '386.25',
      '384.31',
      '382.92',
      '383.25',
      '384.38',
      '382.29',
      '385.02',
      '384.20',
      '383.61',
      '384.88',
      '384.33',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    unit: 'V'
  },
  {
    label: '전압 제어 OFF',
    data: [
      '402.27',
      '402.43',
      '402.35',
      '402.70',
      '402.82',
      '402.63',
      '402.86',
      '402.75',
      '402.77',
      '402.90',
      '402.98',
      '403.14',
      '403.30',
      '403.27',
      '403.51',
      '403.81',
      '403.67',
      '403.31',
      '403.41',
      '403.14',
      '403.01',
      '403.10',
      '403.34',
      '403.42',
      '403.37',
      '403.47',
      '403.50',
      '403.46',
      '403.47',
      '403.28',
      '403.31',
      '403.46',
      '403.84',
      '404.14',
      '404.12',
      '404.11',
      '403.99',
      '403.88',
      '403.93',
      '401.18',
      '400.65',
      '400.59',
      '400.84',
      '401.11',
      '401.14',
      '401.42',
      '401.29',
      '401.54',
      '402.81',
      '405.36',
      '401.41',
      '400.81',
      '400.57',
      '400.71',
      '400.54',
      '400.58',
      '400.62',
      '400.54',
      '400.49',
      '400.30',
      '400.02',
      '399.83',
      '398.72',
      '398.16',
      '397.91',
      '394.35',
      '396.15',
      '395.71',
      '395.48',
      '393.15',
      '395.65',
      '393.63',
      '393.88',
      '392.83',
      '393.47',
      '393.18',
      '393.63',
      '392.30',
      '392.74',
      '392.18',
      '393.32',
      '393.87',
      '394.59',
      '394.26',
      '393.74',
      '394.67',
      '395.53',
      '394.48',
      '393.77',
      '394.50',
      '394.76',
      '394.70',
      '394.66',
      '392.97',
      '394.11',
      '395.70',
      '394.69',
      '393.44',
      '391.34',
      '393.86',
      '394.67',
      '394.12',
      '394.30',
      '394.00',
      '394.21',
      '392.89',
      '393.62',
      '395.43',
      '394.55',
      '394.01',
      '395.10',
      '393.92',
      '394.19',
      '394.09',
      '394.55',
      '393.87',
      '395.85',
      '396.17',
      '395.03',
      '395.48',
      '395.03',
      '394.34',
      '394.77',
      '393.69',
      '394.95',
      '395.74',
      '394.76',
      '394.51',
      '394.86',
      '395.31',
      '394.01',
      '393.86',
      '395.16',
      '394.11',
      '394.65',
      '396.22',
      '394.78',
      '394.95',
      '395.02',
      '395.93',
      '396.88',
      '396.79',
      '396.93',
      '396.49',
      '396.72',
      '396.27',
      '397.16',
      '395.83',
      '395.90',
      '395.10',
      '395.65',
      '395.39',
      '394.48',
      '394.69',
      '395.28',
      '395.27',
      '394.34',
      '394.79',
      '395.07',
      '394.81',
      '394.46',
      '394.67',
      '395.51',
      '394.78',
      '393.65',
      '395.97',
      '393.60',
      '395.82',
      '394.29',
      '394.57',
      '395.26',
      '395.63',
      '396.03',
      '394.14',
      '395.04',
      '394.57',
      '395.13',
      '396.87',
      '398.02',
      '398.51',
      '399.54',
      '399.44',
      '399.48',
      '399.67',
      '399.65',
      '399.84',
      '399.85',
      '399.67',
      '399.57',
      '399.69',
      '399.90',
      '399.51',
      '397.96',
      '397.25',
      '395.77',
      '395.59',
      '396.54',
      '396.81',
      '395.77',
      '396.01',
      '395.03',
      '395.73',
      '394.19',
      '396.56',
      '396.26',
      '396.50',
      '395.73',
      '397.39',
      '396.75',
      '397.02',
      '396.77',
      '397.63',
      '396.82',
      '396.77',
      '397.57',
      '397.41',
      '396.65',
      '396.52',
      '396.47',
      '395.69',
      '396.40',
      '396.35',
      '396.90',
      '396.89',
      '396.22',
      '396.27',
      '397.27',
      '397.14',
      '398.23',
      '397.68',
      '395.25',
      '397.63',
      '396.24',
      '395.97',
      '397.41',
      '397.93',
      '397.38',
      '397.20',
      '398.04',
      '397.31',
      '396.89',
      '396.72',
      '397.12',
      '397.91',
      '396.90',
      '396.64',
      '398.07',
      '397.34',
      '396.89',
      '397.60',
      '396.52',
      '394.94',
      '397.92',
      '394.32',
      '395.63',
      '394.48',
      '397.19',
      '395.27',
      '394.19',
      '395.46',
      '394.67',
      '396.32',
      '395.81',
      '396.54',
      '396.77',
      '398.85',
      '397.88',
      '398.66',
      '399.24',
      '398.66',
      '397.49',
      '398.33',
      '396.34',
      '396.21',
      '396.71',
      '397.35',
      '396.89',
      '395.93',
      '396.21',
      '396.13',
      '396.02',
      '395.82',
      '396.83',
      '396.56',
      '398.52',
      '396.46',
      '398.59',
      '397.39',
      '398.40',
      '397.76',
      '398.32',
      '397.47',
      '398.34',
      '398.58',
      '395.95',
      '397.53',
      '396.25',
      '397.04',
      '398.99',
      '399.60',
      '399.86',
      '399.71',
      '399.90',
      '399.98',
      '399.87',
      '399.57',
      '399.48',
      '399.37',
      '399.43',
      '399.43',
      '399.47',
      '399.49',
      '399.88',
      '399.79',
      '399.92',
      '399.66',
      '399.42',
      '399.45',
      '399.58',
      '399.83',
      '399.71',
      '399.38',
      '399.81',
      '399.74',
      '399.74',
      '399.74',
      '399.90',
      '399.93',
      '399.57',
      '399.43',
      '399.21',
      '399.33',
      '399.06',
      '399.16',
      '398.50',
      '397.10',
      '396.66',
      '395.64',
      '397.07',
      '396.30',
      '396.18',
      '394.77',
      '395.45',
      '395.09',
      '396.47',
      '396.04',
      '395.96',
      '395.86',
      '395.04',
      '396.67',
      '396.85',
      '397.65',
      '396.38',
      '396.34',
      '396.13',
      '395.84',
      '397.48',
      '396.51',
      '396.82',
      '395.78',
      '396.16',
      '396.18',
      '396.70',
      '396.71',
      '396.67',
      '396.54',
      '396.24',
      '396.36',
      '396.37',
      '395.98',
      '396.09',
      '395.67',
      '395.85',
      '396.09',
      '395.04',
      '395.68',
      '396.04',
      '396.06',
      '395.26',
      '395.34',
      '395.70',
      '395.75',
      '395.42',
      '395.47',
      '395.46',
      '395.55',
      '395.38',
      '395.88',
      '395.83',
      '396.06',
      '395.08',
      '395.95',
      '396.04',
      '395.58',
      '395.42',
      '395.78',
      '396.75',
      '396.99',
      '397.45',
      '397.48',
      '397.07',
      '396.23',
      '397.30',
      '397.15',
      '397.25',
      '397.10',
      '397.26',
      '397.44',
      '397.75',
      '397.93',
      '397.94',
      '398.11',
      '397.88',
      '396.89',
      '397.02',
      '397.43',
      '397.30',
      '397.53',
      '397.48',
      '397.47',
      '396.93',
      '396.95',
      '396.45',
      '393.83',
      '396.36',
      '396.43',
      '396.04',
      '395.82',
      '395.67',
      '396.22',
      '398.39',
      '398.84',
      '398.18',
      '398.33',
      '396.90',
      '398.04',
      '397.61',
      '397.55',
      '394.79',
      '397.59',
      '397.84',
      '396.95',
      '395.64',
      '397.59',
      '397.53',
      '397.37',
      '397.08',
      '394.45',
      '397.20',
      '396.89',
      '396.78',
      '396.19',
      '395.47',
      '395.86',
      '395.96',
      '394.61',
      '392.68',
      '394.58',
      '393.44',
      '393.16',
      '392.83',
      '392.25',
      '392.04',
      '390.50',
      '393.12',
      '393.14',
      '390.39',
      '390.50',
      '391.92',
      '392.76',
      '393.73',
      '393.68',
      '393.22',
      '393.22',
      '392.70',
      '392.45',
      '392.17',
      '391.85',
      '396.06',
      '396.13',
      '395.55',
      '395.06',
      '394.84',
      '394.50',
      '394.15',
      '393.91',
      '393.55',
      '393.74',
      '393.29',
      '393.49',
      '393.46',
      '393.50',
      '393.24',
      '393.13',
      '392.99',
      '392.96',
      '392.86',
      '391.37',
      '391.09',
      '387.88',
      '390.44',
      '388.35',
      '390.00',
      '390.83',
      '388.97',
      '387.56',
      '388.31',
      '388.53',
      '388.25',
      '388.44',
      '389.62',
      '391.11',
      '390.71',
      '392.53',
      '390.58',
      '390.17',
      '389.07',
      '388.01',
      '395.08',
      '395.84',
      '395.33',
      '395.73',
      '397.31',
      '397.07',
      '396.14',
      '394.86',
      '397.13',
      '397.26',
      '396.69',
      '396.19',
      '397.36',
      '398.14',
      '397.60',
      '395.84',
      '397.07',
      '397.96',
      '397.64',
      '397.12',
      '397.73',
      '396.37',
      '396.32',
      '398.29',
      '397.48',
      '397.32',
      '396.56',
      '393.90',
      '393.55',
      '396.99',
      '396.72',
      '395.18',
      '395.71',
      '395.15',
      '397.12',
      '396.90',
      '397.21',
      '397.20',
      '397.31',
      '397.54',
      '396.17',
      '395.24',
      '394.10',
      '394.58',
      '396.22',
      '394.62',
      '395.74',
      '394.55',
      '395.91',
      '395.26',
      '395.04',
      '394.25',
      '394.75',
      '395.26',
      '393.97',
      '395.26',
      '394.59',
      '394.93',
      '395.37',
      '394.30',
      '395.15',
      '393.64',
      '392.24',
      '391.23',
      '392.61',
      '391.79',
      '394.29',
      '392.62',
      '391.91',
      '393.13',
      '391.92',
      '391.66',
      '390.81',
      '391.78',
      '390.61',
      '392.93',
      '390.81',
      '391.12',
      '391.89',
      '392.33',
      '392.74',
      '396.55',
      '396.87',
      '396.81',
      '397.23',
      '397.20',
      '397.53',
      '397.39',
      '397.23',
      '397.38',
      '397.24',
      '397.30',
      '397.22',
      '396.98',
      '393.95',
      '389.49',
      '392.24',
      '394.28',
      '393.70',
      '394.18',
      '390.80',
      '391.65',
      '391.10',
      '391.52',
      '391.63',
      '391.67',
      '391.20',
      '393.31',
      '392.52',
      '393.41',
      '395.52',
      '395.25',
      '394.73',
      '396.44',
      '396.01',
      '395.57',
      '396.18',
      '397.15',
      '396.28',
      '395.91',
      '398.47',
      '398.75',
      '398.21',
      '397.59',
      '396.77',
      '397.39',
      '395.83',
      '396.39',
      '396.14',
      '396.23',
      '398.23',
      '398.71',
      '399.11',
      '399.46',
      '399.51',
      '398.97',
      '397.34',
      '400.70',
      '400.21',
      '400.91',
      '400.80',
      '401.02',
      '399.15',
      '399.53',
      '398.92',
      '397.88',
      '400.07',
      '400.63',
      '399.69',
      '400.04',
      '399.78',
      '399.99',
      '398.46',
      '400.17',
      '399.15',
      '399.14',
      '399.96',
      '398.51',
      '399.24',
      '398.18',
      '398.51',
      '399.08',
      '399.33',
      '398.99',
      '399.48',
      '398.85',
      '399.68',
      '400.08',
      '399.88',
      '401.28',
      '399.84',
      '396.70',
      '398.43',
      '396.42',
      '397.56',
      '395.66',
      '397.38',
      '394.74',
      '395.70',
      '395.66',
      '396.22',
      '395.41',
      '395.38',
      '395.70',
      '395.87',
      '395.57',
      '398.53',
      '397.31',
      '397.07',
      '398.95',
      '398.71',
      '398.03',
      '398.27',
      '396.89',
      '391.29',
      '392.24',
      '392.67',
      '392.21',
      '391.70',
      '393.47',
      '395.90',
      '398.02',
      '398.63',
      '398.76',
      '399.11',
      '399.10',
      '399.24',
      '396.25',
      '395.96',
      '396.09',
      '396.30',
      '396.49',
      '396.42',
      '396.43',
      '396.67',
      '396.86',
      '396.85',
      '396.74',
      '397.17',
      '397.18',
      '397.50',
      '397.55',
      '397.85',
      '397.73',
      '397.87',
      '397.97',
      '398.03',
      '398.04',
      '398.00',
      '397.95',
      '398.21',
      '398.11',
      '396.46',
      '396.05',
      '395.91',
      '395.94',
      '395.64',
      '395.24',
      '395.32',
      '395.48',
      '395.34',
      '395.03',
      '394.72',
      '394.92',
      '395.90',
      '399.30',
      '399.11',
      '398.74',
      '399.17',
      '399.46',
      '399.43',
      '399.26',
      '398.86',
      '398.95',
      '398.81',
      '398.85',
      '398.51',
      '398.09',
      '397.66',
      '397.25',
      '396.76',
      '395.95',
      '394.98',
      '395.82',
      '397.07',
      '395.97',
      '395.37',
      '395.01',
      '399.17',
      '398.75',
      '399.63',
      '398.71',
      '398.75',
      '396.41',
      '398.24',
      '397.76',
      '397.50',
      '397.07',
      '397.53',
      '398.07',
      '396.80',
      '395.27',
      '395.97',
      '396.55',
      '395.53',
      '393.55',
      '394.68',
      '396.15',
      '394.16',
      '392.74',
      '393.08',
      '394.24',
      '392.09',
      '394.89',
      '394.05',
      '393.45',
      '394.75',
      '394.18',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    ],
    unit: 'V'
  }
];

export const sstChartSeries1 = sstChart1.map((item, index) => {
  return {
    type: 'line',
    data: item.data,
    name: item.label,
    showSymbol: false,
    itemStyle: {
      color: blueGrayRedYellowGreen[index] as string
    }
  };
});
export const sstChartSeries2 = sstChart2.map((item, index) => {
  return {
    type: 'line',
    data: item.data,
    name: item.label,
    showSymbol: false,
    itemStyle: {
      color: blueGrayRedYellowGreen[index] as string
    }
  };
});
export const sstChartSeries3 = sstChart3.map((item, index) => {
  return {
    type: 'line',
    data: item.data,
    name: item.label,
    showSymbol: false,
    itemStyle: {
      color: blueGrayRedYellowGreen[index] as string
    }
  };
});
