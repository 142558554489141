/**
 * @name Main
 * @returns
 */
import React from 'react';
import {useProductsListQuery} from 'pages/_guide/hooks';
// contents
import Item from './list';

const Contents: React.FC = () => {
  // query
  const {isLoading: loading, data: lists} = useProductsListQuery();

  return (
    !loading &&
    lists &&
    lists?.products.map((item, index: number) => {
      return <Item key={`${item.id}_${index}`} item={item} index={index} />;
    })
  );
};

export default Contents;
