/**
 * index.tsx
 * ========================================
 * 프로젝트의 진입점인 index.js 파일
 */
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';

// styles
import './styles/scss/root.scss';
import './styles/scss/init.scss';
import './styles/scss/font.scss';
import './styles/scss/text.scss';
import './styles/scss/common.scss';
// import './styles/scss/ui.scss';

// contents
import App from './pages/app';

// queryClient
const queryClient = new QueryClient();
const rootElement = document.getElementById('root');

if (rootElement !== null && rootElement !== undefined) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      <ReactQueryDevtools initialIsOpen />
    </QueryClientProvider>
    // </React.StrictMode>
  );
}
