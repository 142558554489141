/*
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
  카드위젯
  - 그림자 효과
  - 테두리1px
  - borderRadius: 12px
  
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
*/
import {Flex} from 'components';
import React from 'react';
import styled, {css} from 'styled-components';
import {Color} from 'styles';
import {NoData} from './noData';

// store
type Props = {
  title?: React.ReactNode | string;
  topRight?: React.ReactNode;
  background?: string;
  children?: React.ReactNode;
  error?: boolean;
};

const CardShadow = ({title, topRight, background, children, error}: Props) => {
  // 버블의 크기 및 위치 설정
  return (
    <Content $background={error ? undefined : background}>
      <Flex.SpaceBetween style={{alignItems: 'start'}}>
        {title && <Title>{title}</Title>}
        {topRight && topRight}
      </Flex.SpaceBetween>
      <Wrap>{error ? <NoData /> : children}</Wrap>
    </Content>
  );
};

const Content = styled.div<{$background?: string}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  background-color: ${Color.white};
  border: 1px solid #dce4f9;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  ${({$background}) =>
    $background &&
    css`
      background-image: url(${$background});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    `};
`;

const Title = styled.div`
  margin-top: 18px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Pretendard';
`;

const Wrap = styled.div`
  height: 100%;
`;

export default CardShadow;
