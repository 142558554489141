import React from 'react';
import {TemplateEnum} from '../../../../lib/enum/TemplateEnum';
import {CardsLayout} from 'pages/dashboard/api/type';
import LineMin from './card/line_min';
import LineArer from './card/line_arer';
import BarHour from './card/bar_hour';
import Table from './card/table';
import LineHour from './card/line_hour';
import {Header} from 'components/table/table';
import {soomy, sst1} from 'pages/dashboard/constant/tableHeader';
import {blueBlack, greenBlueBlack} from 'styles/color';
import TableSst1 from './card/tableSst1';
import TableSst2 from './card/tableSst2';
import LineArer1 from './card/lineArer1';

export type Props = {
  item?: CardsLayout;
  headers?: Header[];
};

// 템플릿 리스트
export class TemplateFactory {
  static create({item}: Props): React.JSX.Element {
    switch (item?.templateName) {
      case TemplateEnum.LINE_1_1_1: // 율촌 수요현황, 변압기
      case TemplateEnum.LINE_2_2_1: // 율촌 변압기 TAP 위치 카드
      case TemplateEnum.LINE_2_3_1: // 율촌 전압현황
      case TemplateEnum.BASELINE_1_1_1: // 역률현황
        return <LineMin item={item} />;
      case TemplateEnum.LINE_2_4_2: // 수미마을 ESS
      case TemplateEnum.LINE_1_4_2: // 수미마을 풍력
      case TemplateEnum.LINE_1_2_1: // 수미마을 V2G 사용량
        return <LineMin item={item} chartColor={blueBlack} labelColor={greenBlueBlack} />;
      case TemplateEnum.LINE_1_2_2: // 수미마을 태양열 등
        return <LineHour item={item} />;
      case TemplateEnum.AREA_2_2_1: // 월 유효전력 카드
        return <LineArer item={item} />;
      case TemplateEnum.BAR_1_4_1: // 수미마을 태양광
        return <BarHour item={item} />;
      case TemplateEnum.TABLE_4_0_1: // 수미마을 태양광(가구) 등
        return <Table item={item} headers={soomy} />;
      case TemplateEnum.TABLE_5_0_1: // SST 설비목록 현황
        return <TableSst1 item={item} headers={sst1} />;
      case TemplateEnum.TABLE_2_0_1: // SST 상태알림
        return <TableSst2 item={item} />;
      case TemplateEnum.AREA_1_1_1: // SST CVRFactor
        return <LineArer1 item={item} />;
      default:
        return <></>;
    }
  }
}
