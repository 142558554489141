/**
 * @name Contents
 * @returns
 */
import {useGuideStore} from 'pages/_guide/model';
import styled from 'styled-components';
import {Color} from 'styles';

const Contents: React.FC = () => {
  const {currentPage} = useGuideStore((state) => state);
  const {numberA, numberB, increaseNumberA, increaseNumberB} = useGuideStore();
  return (
    <Content>
      <div>
        <h1>currentPage :{currentPage}</h1>
      </div>
      <p>
        <button onClick={increaseNumberA}>{numberA}</button>
        <button onClick={() => increaseNumberB(3)}>{numberB}</button>
      </p>
    </Content>
  );
};
export default Contents;

const Content = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px dashed ${Color.gray50};
  background-color: ${Color.gray70};

  button {
    padding: 10px 20px;
    background-color: ${Color.gray80};
    border: none;
    cursor: pointer;
    &:hover {
      background-color: ${Color.gray60};
    }
  }
`;
