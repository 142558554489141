import {CUSTOM_CARD_TITLE} from 'lib/const';
import {Fragment} from 'react';
import CVR from './cvr';
import Standard from './standard';
import MaxPower from './max_power';
import {Props} from '../../template_factory';
import {TemplateEnum} from 'lib/enum/TemplateEnum';
import Saving from './saving';

const Index = ({item}: Props) => {
  return (
    <Fragment>
      {/* CVR */}
      {item?.templateName === TemplateEnum.CUSTOM_2_7_1 && <CVR cardNo={item?.cardNo} />}

      {/* STANDARD */}
      {item?.templateName === TemplateEnum.CUSTOM_0_7_1 && <Standard cardNo={item?.cardNo} />}

      {/* STANDARD */}
      {item?.templateName === TemplateEnum.CUSTOM_4_0_1 && <MaxPower item={item} />}

      {/* SST */}
      {item?.templateName === TemplateEnum.CUSTOM_2_6_1 && <Saving cardNo={item?.cardNo} />}
    </Fragment>
  );
};

export default Index;
