/**
 * @name Image
 * @description 레티나,디스플레이대응 2배수이미지
 */
import styled from 'styled-components';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  width?: number;
  height?: number;
}

const Image: React.FC<Props> = ({...props}) => {
  return <Element style={{height: 'auto', verticalAlign: 'top'}} {...props} />;
};
export default Image;

const Element = styled.img``;

/********************************************************
[사용법]

<Image src={common_logo} />
<Image src={auth_logo} width={30} />

*********************************************************/
