/**
 * @name 카드
 * @description
 */
import {Flex} from 'components';
import styled, {css} from 'styled-components';
import {Color, desktop_max_width} from 'styles';

type Props = {
  title?: React.ReactNode | string;
  topRight?: React.ReactNode;
  columnSpan?: number;
  rowSpan?: number;
  children?: React.ReactNode;
};

const Contents = ({title, columnSpan = 1, rowSpan = 1, children, topRight}: Props): JSX.Element => {
  return (
    <Content $columnSpan={columnSpan} $rowSpan={rowSpan}>
      <Flex.SpaceBetween style={{alignItems: 'start', borderBottom: `1px solid ${Color.gray70}`}}>
        {title && <Title>{title}</Title>}
        {topRight && topRight}
      </Flex.SpaceBetween>

      <Children>{children}</Children>
    </Content>
  );
};

export default Contents;

const Content = styled.div<{$columnSpan: number; $rowSpan: number}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  background-color: ${Color.white};
  overflow: hidden;

  ${({$columnSpan, $rowSpan}) => css`
    grid-column: span ${$columnSpan};
    grid-row: span ${$rowSpan};
  `};

  @media all and (max-width: ${desktop_max_width}) {
    ${({$columnSpan, $rowSpan}) => css`
      grid-row: span ${Math.max($columnSpan, $rowSpan)};
      grid-column: span 1;
    `};
  }
`;

const Title = styled.div`
  display: flex;
  padding: 15px 16px;
  width: 100%;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Pretendard';
  line-height: 20px;
`;

const Children = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
`;
