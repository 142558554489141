/**
 * @name store
 * @description react-query, zustand
 */
import {create} from 'zustand';
import {GuideStore} from './type';

/**
 * [zustand 사용법] https://github.com/pmndrs/zustand
 */

export const useGuideStore = create<GuideStore>((set) => ({
  numberA: 0, // 초기 상태 값 설정
  numberB: 0, // 초기 상태 값 설정
  increaseNumberA: () => set((state) => ({numberA: state.numberA + 1})),
  increaseNumberB: (value: number) => set((state) => ({numberB: state.numberB + value})),
  currentPage: 1, // 초기 상태 값 설정
  guide: 'This is a guide page',
  setCurrentPage: (num: number) => set({currentPage: num}),
  setGuide: (str: string) => set({guide: str})
}));
