import Text from 'components/text/text';
import {styled} from 'styled-components';
import {Color} from 'styles';

export type Header = {
  label: string;
};

type Props = {
  headers: Header[];
  children: React.ReactNode;
};

const Table = ({headers, children}: Props) => {
  return (
    <TableWrap>
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>
              <Text label={header.label} size={14} weight={400} style={{lineHeight: '16px'}} />
            </th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </TableWrap>
  );
};

export default Table;

const TableWrap = styled.table`
  width: 100%;
  border-collapse: collapse;
  th {
    padding: 18px;
    text-align: center;
    background-color: ${Color.sepia100};
    position: sticky;
    top: 0;
    z-index: 1;
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }

  td {
    padding: 18px;
    text-align: center;
    border: 1px solid ${Color.sepia90};
    &:first-child {
      border-left: none;
    }
    &:last-child {
      border-right: none;
    }
  }
`;
