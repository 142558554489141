/**
 * @name Contents
 * @returns
 */
import {IconArrowNext, IconArrowPrev} from 'assets/svg';
import {Flex} from 'components';
import {Events} from 'lib/events';
import {useToast} from 'lib/hooks/ui/useToast';
import {Command, useGuideStore} from 'pages/_guide/model';
import styled from 'styled-components';
import {Color} from 'styles';

const Contents: React.FC = () => {
  const {currentPage, setCurrentPage} = useGuideStore((state) => state);
  const {ToastComponent, show, hide} = useToast({
    time: 1000,
    message: '테스트 메시지입니다'
  });
  return (
    <Content>
      <Flex.Row justifyContent="center">
        <button
          disabled={currentPage === 1}
          onClick={() => {
            show('테스트 메시지입니다');
            setCurrentPage(currentPage - 1);
            // Events.emit(Command.submit, currentPage);
          }}>
          <IconArrowPrev />
          <span>이전페이지</span>
        </button>
        <button
          onClick={() => {
            show('테스트 메시지입니다');
            setCurrentPage(currentPage + 1);
            // Events.emit(Command.submit, currentPage);
          }}>
          <span>다음페이지</span>
          <IconArrowNext />
        </button>
      </Flex.Row>
      <ToastComponent />
    </Content>
  );
};
export default Contents;

const Content = styled.footer`
  display: flex;
  width: 100%;
  padding: 20px;
  border-top: 1px dashed ${Color.gray60};
  background-color: ${Color.gray90};
  button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 10px 20px;
    background-color: ${Color.gray80};
    border: none;
    cursor: pointer;
    &:hover {
      background-color: ${Color.gray70};
    }
  }
`;
