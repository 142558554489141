/**
 * @name title
 * @description
 */
import {Flex, Text} from 'components';
import styled from 'styled-components';
import {Color} from 'styles';

const Contents = (): JSX.Element => {
  return (
    <Content>
      <Flex.Start>
        <Text label="ACELO CVR" mode="Title_LB" color={Color.gray30} />
        {/* 가동중 */}
        <div className="on">
          <Text label="가동중" mode="Btn_S" color={Color.white} />
        </div>
      </Flex.Start>
      {/* 에너지 절감 */}
      <Flex.Start>
        <Text label="에너지 절감" mode="Title_LB" color={Color.gray30} style={{marginRight: 10}} />
      </Flex.Start>
    </Content>
  );
};

export default Contents;

const Content = styled.header`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  /* 가동중 */
  .on {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 32px;
    margin-left: 8px;
    border-radius: 4px;
    background-color: ${Color.green30};
  }
`;
