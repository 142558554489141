/**
 * @name api
 */

import axios, { AxiosRequestConfig } from "axios";
import { SESSION_STORAGE } from "lib/const";

interface fetchOptionType extends AxiosRequestConfig {
  method?: "GET" | "POST" | "PUT" | "DELETE";
  data?: {};
  params?: {};
}
/**
 * @name fetchData
 * @param {string} url 요청을 보낼 서버의 URL.
 * @param {fetchOptionType} [option] 요청을 커스텀할 수 있는 옵션 객체. (선택적)
 * - `method`: HTTP 요청 메서드 ('GET', 'POST', 'PUT', 'DELETE'). 기본값은 'GET'.
 * - `data`: 요청 본문에 보낼 데이터. 주로 'POST' 또는 'PUT' 요청에 사용.
 * - `params`: URL에 추가할 쿼리 파라미터
 * @description axios를 이용하여 서버로부터 데이터를 가져온다.
 */

export const fetchData = async <T>(
  url: string,
  option?: fetchOptionType
): Promise<T> => {
  const config: AxiosRequestConfig = Object.assign(
    {
      headers: { "Content-Type": "application/json; charset=utf-8" },
      url: url,
      method: option?.method || "GET",
      timeout: 1000 * 10,
      async: true,
      crossDomain: true,
      withCredentials: false,
      validateStatus: function (status: number) {
        // 400번대 에러도 무시
        return status >= 200 && status < 600;
      },
    },
    option as fetchOptionType
  );
  const token = await sessionStorage.getItem(SESSION_STORAGE.TOKEN);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const res = await axios<T>(config);
  return res.data;
};
