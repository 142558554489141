/*
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
  이번달RE50달성률
  
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
*/
import React, {useState} from 'react';
import styled from 'styled-components';
// index
import {Flex, Text} from 'components';
import {Color} from 'styles';
import {re50 as re50Icon, plus} from 'assets/svg';
import {CardShadow} from 'pages/energy/common';
import {addComma, uuid} from 'lib/utils';
import {useEnergyQuery} from 'pages/energy/hooks';

const Re50: React.FC = () => {
  const {re50} = useEnergyQuery();
  const [isTooltip, setIsTooltip] = useState(false);

  return (
    <CardShadow title={'RE50 달성률'} background={re50Icon} error={re50?.type === 'error'}>
      <Flex.End style={{padding: '30px 20px'}}>
        <div
          onMouseEnter={() => {
            setIsTooltip(true);
          }}
          onMouseLeave={() => {
            setIsTooltip(false);
          }}>
          <Text label={re50?.value} color={Color.blue30} size={50} weight={700} />
          <Text label="%" color={Color.gray50} size={30} weight={700} />
          <Plus src={plus} />
          {isTooltip && (
            <Tooltip>
              <Flex.Column alignItems={'flex-start'}>
                <Text label="2021년 전기 사용량 기준" size={12} weight={700} style={{marginBottom: 14}} />
                {re50?.chart?.map((item) => {
                  return (
                    <Flex.SpaceBetween key={uuid()} style={{marginBottom: 4}}>
                      <Text label={item.label} size={12} weight={700} />
                      <Text label={`${addComma({value: item.value as number})}kWh`} size={12} weight={700} />
                    </Flex.SpaceBetween>
                  );
                })}
              </Flex.Column>
            </Tooltip>
          )}
        </div>
      </Flex.End>
    </CardShadow>
  );
};

export default Re50;

const Plus = styled.img`
  margin-left: 10px;
  width: 24px;
  height: 24px;
`;

const Tooltip = styled.div`
  position: absolute;
  width: 186px;
  height: 85px;
  left: 370px;
  top: 450px;
  z-index: 10;
  border-radius: 10px;
  background-color: ${Color.gray40};
  opacity: 0.9;
  color: white;
  font-size: 12px;
  padding: 14px 18px;
`;
