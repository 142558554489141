/*
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
  신재생에너지발전량
  
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
*/

import React, {useEffect, useState} from 'react';
// index
import {Color} from 'styles';
import {Echarts, Flex, Text} from 'components';
import {CardShadow} from 'pages/energy/common';
import {useEnergyQuery} from 'pages/energy/hooks';

const Progress: React.FC = () => {
  const {renewal} = useEnergyQuery();

  const [options, setOption] = useState<echarts.EChartsOption>();

  useEffect(() => {
    if (!renewal) return;

    setOption({
      grid: {
        left: 50,
        right: 0,
        top: 30,
        bottom: 60,
        containLabel: false
      },

      series: [
        {
          type: 'bar',
          data: renewal?.chart?.map((item) => item.value),
          barWidth: 12,
          itemStyle: {
            color: Color.blue30
          }
        }
      ],
      xAxis: {
        type: 'category',
        data: renewal?.chart?.map((item: {label: any}) => `${item.label}월`),
        axisTick: {show: false}
      },
      yAxis: {
        type: 'value',
        name: 'kw',
        nameLocation: 'end', // 단위 표시 위치
        nameTextStyle: {
          padding: [0, 30, -20, 0] // y축 단위 위치 조정
        },
        splitNumber: 3,
        axisLabel: {
          showMaxLabel: false // 최대값 레이블 숨김
        }
      }
    });
  }, [renewal]);

  return (
    <CardShadow title={'신재생 에너지 발전량'} error={renewal?.type === 'error' || !renewal}>
      <Flex.SpaceBetween alignItems={'center'} style={{paddingLeft: 20}}>
        {/* 차트 */}
        <div>{options && <Echarts options={options} width={128} height={90} />}</div>

        {/* kWh */}
        <Flex.Column alignItems={'flex-start'} style={{paddingBottom: 20, paddingLeft: 20}}>
          <Text label="지난달" color={Color.gray10} mode="Body_SM" />
          <Flex.Row alignItems="flex-end" style={{marginTop: 10}}>
            <Text label={renewal?.value} color={Color.blue30} size={30} weight={700} />
            <Text label="kWh" color={Color.gray50} size={16} weight={700} style={{marginLeft: 5}} />
          </Flex.Row>
        </Flex.Column>
      </Flex.SpaceBetween>
    </CardShadow>
  );
};

export default Progress;
