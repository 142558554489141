/**
 * @name 로그인
 * @description
 */
import React from 'react';
import {Container} from 'template';
// views
import Visual from './view/visual';
import Login from './view/login';
import {Flex} from 'components';

const SignIn: React.FC = () => {
  return (
    <Container>
      <Flex.Row style={{height: '100vh'}} alignItems="center" justifyContent="center">
        {/*================== 비쥬얼 ========*/}
        <Visual />
        {/*================== 로그인폼 ========*/}
        <Login />
      </Flex.Row>
    </Container>
  );
};
export default SignIn;
