/**
 * @name Input
 * @description 입력창
 */
import React from 'react';
import {AnyType, CSS} from 'types';

type onChangeProps = {
  event: React.ChangeEvent<HTMLInputElement>;
  name: string;
  value: string;
};
type Props = {
  name?: string; //-------------------------------- Element이름
  className?: string; //--------------------------- ClassName
  style?: React.CSSProperties; //------------------ StyleSheet
  placeholder?: string; //------------------------- placeholder
  defaultValue?: string; //------------------------ 초기값세팅 이후랜더링안됨
  value?: string; //------------------------------- 랜더링됨
  disabled?: boolean; //--------------------------- disabled
  type?: string; //-------------------------------- type
  onEnter?: (str: string) => void; //-------------- "Enter"키를받으면발생하는이벤트
  onSubmit?: (str: string) => void; //------------- "onSubmit" 이벤트
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void; //------------- onFocus
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void; //-------------- onBlur
  onChange?: ({event, name, value}: onChangeProps) => void; //------------------ 값이갱신될때마다 발생 object {event, name, value} 로 반환
};

const Input = ({
  name,
  className,
  style,
  type,
  value,
  defaultValue,
  disabled,
  placeholder,
  onChange,
  onEnter,
  onSubmit,
  onFocus,
  onBlur
}: Props): JSX.Element => {
  // style
  const _style: CSS = Object.assign({}, {outline: 0, fontWeight: 'normal'}, style);

  return (
    <input
      name={name || 'noName'}
      type={type}
      className={className}
      style={_style}
      placeholder={placeholder}
      disabled={disabled}
      defaultValue={defaultValue}
      value={value}
      onFocus={(event: React.FocusEvent<HTMLInputElement>) => onFocus?.(event)}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => onBlur?.(event)}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        const _name = event.target.name || 'noName';
        const _value = event.target.value;
        onChange && onChange({event, name: _name, value: _value});
      }}
      // 한글이벤트중복문제가 있어서 keydown으로 변경
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        // ENTER키적용 (한글입력시 이벤트발생안함)
        if (event.key === 'Enter') {
          onEnter && onEnter(event.currentTarget.value);
          onSubmit && onSubmit(event.currentTarget.value);
        }
      }}
    />
  );
};

export default Input;

// const Element = styled.input<Props>`
//   outline: 0;
//   font-weight: normal;
//   &::placeholder {
//     color: ${Color.gray50};
//   }
// `;
/********************************************************
[사용법]

*********************************************************/
