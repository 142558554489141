import React, {useRef, useEffect} from 'react';
import styled from 'styled-components';

type Props = {
  visible?: boolean;
  setVisible?: (bool: boolean) => void;
  children: React.ReactNode;
};

const Layer = ({children, visible = false, setVisible}: Props): JSX.Element | null => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (setVisible) {
          setVisible(false);
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setVisible]);

  return visible ? <Content ref={ref}>{children}</Content> : null;
};

export default Layer;

const Content = styled.div`
  /* 스타일 설정 */
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

/**
 * [사용법]
 * import Layer from "./Layer";
 *
 * const [visible, setVisible] = React.useState(true);
 *
 * <Layer visible={visible} setVisible={setVisible}>
 *   <div>Content goes here</div>
 * </Layer>
 */
