/**
 * @name Enum
 * @description
 */

export enum TemplateEnum {
  LINE_1_1_1 = 'LINE_1_1_1', // 라인차트 + 데이터 1개 //  수요현황, 변압기 사용량
  LINE_1_2_1 = 'LINE_1_2_1', // 라인차트 + 데이터 1개 //  수요현황, 변압기 사용량
  LINE_1_2_2 = 'LINE_1_2_2', // 하루시간 라인 그래프 // 태양열
  LINE_1_4_2 = 'LINE_1_4_2', // 분시간 라인 그래프 라인 그래프 // 태양광,풍력 등
  LINE_2_2_1 = 'LINE_2_2_1', // 2개의 라인차트에 2가지 값 / 율촌 변압기 TAP 위치 카드
  LINE_2_3_1 = 'LINE_2_3_1', // 2개의 라인차트에 3가지 값 / 율촌 전압현황 등
  LINE_2_4_2 = 'LINE_2_4_2', // 2개의 라인차트에 3가지 값 // 수미마을 ESS
  BASELINE_1_1_1 = 'BASELINE_1_1_1', // 라인차트 + 기준선 // 역률현황 등
  AREA_2_2_1 = 'AREA_2_2_1', // 라인차트 여러개 + 데이터 2개 // 월 유효전력 카드
  AREA_1_1_1 = 'AREA_1_1_1', // SST CVR Factor
  TABLE_4_0_1 = 'TABLE_4_0_1', // 개별 장비에 대한 테이블 차트 // 수미마을 태양광(가구) 등
  TABLE_5_0_1 = 'TABLE_5_0_1', // 설비목록 현황
  TABLE_2_0_1 = 'TABLE_2_0_1', // 상태 알림
  BAR_1_4_1 = 'BAR_1_4_1', // 1개의 바 차트에 3가지 값 / 수미마을 태양광 등
  CUSTOM_2_7_1 = 'CUSTOM_2_7_1', // 에너지절감 CVR
  CUSTOM_0_7_1 = 'CUSTOM_0_7_1', // Standard
  CUSTOM_4_0_1 = 'CUSTOM_4_0_1', // 최대전력 현황
  CUSTOM_2_6_1 = 'CUSTOM_2_6_1' // SST에너지 절감
}

// CVR factor 월누적 전력 사용량 / SST전압현황 제외 템플릿 네임 다르게 요청
