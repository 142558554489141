import {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Text} from 'components';
import {Color} from 'styles';
import {IconArrowDown, IconArrowUp} from 'assets/svg';

type Props = {
  options: string[];
  selectedOption?: string;
  onSelect?: (option: string | undefined) => void;
};

const Dropdown = ({options, selectedOption, onSelect}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  // 외부 클릭 감지
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef} onClick={() => setIsOpen(!isOpen)}>
      <Text label={selectedOption} weight={700} size={14} />
      {isOpen ? <IconArrowUp width="20" height="20" /> : <IconArrowDown width="20" height="20" />}
      {isOpen && (
        <OptionsList>
          {options.map((option, index) => (
            <Option
              key={index}
              style={{
                backgroundColor: option === selectedOption ? Color.blue90 : undefined
              }}
              onClick={() => {
                onSelect && onSelect(option);
                setIsOpen(false);
              }}>
              <Text label={option} weight={700} size={14} />
            </Option>
          ))}
        </OptionsList>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;

const DropdownContainer = styled.div`
  position: relative;
  width: 100px;
  border: 1px solid ${Color.gray70};
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

const OptionsList = styled.ul`
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 100;
`;

const Option = styled.li`
  padding: 12px 12px;
  cursor: pointer;

  &:hover {
    background-color: ${Color.gray90};
  }
`;
