/**
 * @name Gudes
 * @description
 * @todo
 */
import React from 'react';
import {App} from 'template';
// controllers
import Controller from './controller';
// views
import {Footer, Header} from './view';

const Guide: React.FC = () => {
  return (
    <App>
      {/*================== Header ========*/}
      <Header />
      {/*================== Controller ====*/}
      <Controller />
      {/*================== Footer ========*/}
      <Footer />
    </App>
  );
};
export default Guide;
