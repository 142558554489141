/**
 * @name 컬러색상
 * @description
 */

export const Color = {
  transparent: 'transparent',
  /** ------------------------------------[BLACK,WHITE] */
  white: '#ffffff',
  black: '#000000',
  /** ------------------------------------[gray] */
  gray10: '#080808',
  gray20: '#202020',
  gray30: '#373737',
  gray40: '#5F5F5F',
  gray50: '#8A8A8A',
  gray60: '#B2B2B2',
  gray70: '#D8D8D8',
  gray80: '#E9E9E9',
  gray90: '#F4F4F4',
  /** ------------------------------------[blue ] */
  blue10: '#1146CB',
  blue20: '#1850DD',
  blue30: '#2B64F5',
  blue40: '#4073F4',
  blue50: '#6690FB',
  blue60: '#82A5ED',
  blue70: '#BED0F5',
  blue80: '#BED0F5',
  blue90: '#E9F0FE',
  blue100: '#F7FAFF',
  /** ------------------------------------[red ] */
  red10: '#ED3A3C',
  red20: '#F44142',
  red30: '#FF564B',
  red40: '#FF5B5C',
  red50: '#FF6D6E',
  red60: '#FF8686',
  red70: '#FCC4C4',
  red80: '#FBD6D6',
  red90: '#FFEAEA',
  red100: '#FFF6F6',
  /** ------------------------------------[yellow ] */
  yellow10: '#FF7A00',
  yellow20: '#EBA12F',
  yellow30: '#FFBB00',
  yellow40: '#F6AE3F',
  yellow50: '#FDC061',
  yellow60: '#FFCF86',
  yellow70: '#FBE4C1',
  yellow80: '#FBECD6',
  yellow90: '#FFF3E1',
  yellow100: '#FFFCF6',
  /** ------------------------------------[green ] */
  green10: '#13B279',
  green20: '#1EBB82',
  green30: '#20CC86',
  green40: '#40C997',
  green50: '#6CD5AF',
  green60: '#8DE8CC',
  green70: '#BDF0E4',
  green80: '#CEF8F0',
  green90: '#E2FEF6',
  green100: '#F6FFFC',

  /** ------------------------------------[sepia ] */
  sepia90: '#DAE7F3',
  sepia100: '#F5F9FD'
};

// Primary 객체
export const Primary = {
  blue10: Color.blue10,
  blue20: Color.blue20,
  blue30: Color.blue30,
  blue40: Color.blue40,
  blue50: Color.blue50,
  blue60: Color.blue60,
  blue70: Color.blue70,
  blue80: Color.blue80,
  blue90: Color.blue90,
  blue100: Color.blue100
};
// Danger 색상을 red 계열로 설정
export const Danger = {
  red10: Color.red10,
  red20: Color.red20,
  red30: Color.red30,
  red40: Color.red40,
  red50: Color.red50,
  red60: Color.red60,
  red70: Color.red70,
  red80: Color.red80,
  red90: Color.red90,
  red100: Color.red100
};

// Warning 색상을 yellow 계열로 설정
export const Warning = {
  yellow10: Color.yellow10,
  yellow20: Color.yellow20,
  yellow30: Color.yellow30,
  yellow40: Color.yellow40,
  yellow50: Color.yellow50,
  yellow60: Color.yellow60,
  yellow70: Color.yellow70,
  yellow80: Color.yellow80,
  yellow90: Color.yellow90,
  yellow100: Color.yellow100
};

// Success 색상을 green 계열로 설정
export const Success = {
  green10: Color.green10,
  green20: Color.green20,
  green30: Color.green30,
  green40: Color.green40,
  green50: Color.green50,
  green60: Color.green60,
  green70: Color.green70,
  green80: Color.green80,
  green90: Color.green90,
  green100: Color.green100
};

export const blueGrayRedYellowGreen = [Color.blue30, Color.gray60, Color.red30, Color.yellow30, Color.green30];
export const blueGreenGrayGreen = [Color.blue30, Color.green30, Color.gray60, Color.green30];
export const greenBlueBlack = [Color.green30, Color.blue30, Color.gray10];
export const blueBlack = [Color.blue30, Color.gray10];
export const backBlueGreen = [Color.black, Color.blue30, Color.green30];
export const grayBlueGreen = [Color.gray30, Color.blue30, Color.green30];
export const blueGrayYellow30 = [Color.blue30, Color.gray30, Color.yellow30];
export const blueGrayYellow60 = [Color.blue60, Color.gray60, Color.yellow60];

/** ------------------------------------[Font ] */

export const Fonts = {
  Main: 'Pretendard',
  Sub: 'Spoqa Han Sans Neo'
};
