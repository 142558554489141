/**
 * @name 메인메뉴
 * @description
 */
import styled from 'styled-components';
import Box from './box';
import {dashboard, chart, ev} from 'assets/svg';
import uuid from 'lib/utils/uuid';
import {getGnb} from '../api';
import {GNB} from '../api/querykey';
import {useQuery} from '@tanstack/react-query';
import {useEffect, useMemo} from 'react';
import {useAuthStore} from 'context';

type Props = {
  isOpen: boolean;
};

const Contents = ({isOpen}: Props) => {
  // 메뉴 API
  const {data: gnbMenu} = useQuery({
    queryKey: [GNB.GNB],
    queryFn: () => getGnb()
  });

  // Icon 가져오기
  const getIcon: {[key: string]: string} = {
    '/energy': chart,
    '/dashboard': dashboard,
    '/ev': ev
  };

  // 아이콘 추가된 메뉴 리스트
  const menuList = useMemo(() => {
    if (!gnbMenu) return;
    return gnbMenu.map((item) => {
      return {...item, icon: getIcon[item.route]};
    });
  }, [gnbMenu]);

  return (
    <Content>
      {menuList?.map((item) => {
        return <Box key={uuid()} item={{...item, isOpen, isRoute: location.pathname === item.route}} />;
      })}
    </Content>
  );
};

export default Contents;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;
