/**
 * @name 콤마찍기
 * @returns string
 */
type AddCommaType = {
  value: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};
export const addComma = ({
  value,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2,
}: AddCommaType): string => {
  try {
    const formatted = Intl.NumberFormat("en-US", {
      minimumFractionDigits: minimumFractionDigits, // 최소 소수점 자릿수
      maximumFractionDigits: maximumFractionDigits, // 최대 소수점 자릿수
    }).format(value);

    // 정수인 경우에도 소수점 첫째 자리에 0을 표시하지 않도록 조건 변경
    if (Math.floor(value) === value && minimumFractionDigits === 0) {
      return formatted;
    }
    return formatted;
  } catch (e) {
    return String(value);
  }
};
