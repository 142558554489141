/**
 * Echarts 기본설정
 */

type Props = {
  total?: number;
  intervalX?: number;
  unit?: string;
};
export const EchartsDefaultOption = ({total = 1440, intervalX = 2, unit = 'kW'}: Props): echarts.EChartsOption => {
  return {
    // tooltip: { trigger: "axis", axisPointer: { type: "cross" } },
    tooltip: {
      trigger: 'axis',
      axisPointer: {type: 'line'},
      formatter: function (params: any) {
        const validData = params.filter((serie: any) => serie.value !== undefined && serie.value !== null);
        // 유효한 데이터가 없으면 툴팁을 표시하지 않음
        if (validData.length === 0) {
          return '';
        }
        return params
          .map((serie: any) => {
            return (
              serie.seriesName &&
              serie.value &&
              `
            <div>
              <span style="margin-right: 3px;">${serie.marker}</span>
              <span style="margin-right: 3px;">${serie.seriesName}</span>
              <b>${Number(serie.value).toFixed(1)}</b>
            </div>
            `
            );
          })
          .join('');
      }
    },
    grid: {top: '40px', left: '40px', right: '20px', bottom: '30px'},
    xAxis: {
      type: 'category',
      data: Array.from({length: total}, (_, index) => {
        const hour = Math.floor(index / 60);
        return `${hour.toString().padStart(2, '0')}`;
      }),
      name: '시',
      nameLocation: 'end',
      nameTextStyle: {
        // align: 'left',
        fontSize: 10,
        padding: [0, 0, 5, -20],
        verticalAlign: 'bottom'
      },
      axisLabel: {
        formatter: function (value: string) {
          const hour = parseInt(value, 10);
          if (hour % intervalX === 0) {
            return `${value}`;
          }
          return ``;
        },
        interval: total / 12 - 1, // 데이터 포인트 수에 따라 조정
        margin: 10
      },
      axisTick: {
        show: true,
        lineStyle: {
          type: 'dashed',
          width: 1
        }
      },
      axisLine: {
        symbol: 'none',
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    yAxis: [
      {
        scale: true,
        // boundaryGap: ["10%", "10%"],
        splitNumber: 3,
        type: 'value',
        position: 'left',
        axisTick: {lineStyle: {type: 'dashed'}},
        name: unit, // Y축 이름 추가
        nameLocation: 'end', // 이름 위치를 축의 끝에 배치
        nameTextStyle: {
          // align: 'left',
          fontSize: 10,
          padding: [0, 40, 0, 0]
          // verticalAlign: 'bottom'
        }
      }
    ],
    series: []
  };
};
