import {Text} from 'components';
import {styled} from 'styled-components';
import {Color} from 'styles';
import {ValueUnit} from './value-unit-list';

type Props = {
  valueUnit?: ValueUnit;
};

export const Volume = ({valueUnit}: Props) => {
  return (
    valueUnit && (
      <TopRight>
        <Text label={'설비용량'} size={10} weight={600} color={Color.gray40} />
        <Text label={`${valueUnit?.value} ${valueUnit?.unit}`} size={10} weight={600} color={Color.gray40} />
      </TopRight>
    )
  );
};

const TopRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 50px;
  height: 100%;
  margin-right: 20px;
`;
