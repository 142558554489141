/**
 * @name Presenter
 * @returns
 */
import {IconMore} from 'assets/svg';
import {Flex, Text} from 'components';
import styled from 'styled-components';
import {IoMdMore} from 'react-icons/io';
import {BiSolidSquareRounded} from 'react-icons/bi';

// types
import {ProductsListItem} from 'pages/_guide/api/type';
import {Color} from 'styles';
// contents

type Props = {
  item: ProductsListItem;
  index?: number;
};
const Contents = ({item}: Props): JSX.Element => {
  const clickHandler = (): void => {
    //
  };
  return (
    <Content>
      {/* {debugPanel(item)} */}
      <Flex.SpaceBetween>
        <button
          className="item"
          onClick={() => {
            // console.log(index);
          }}>
          <BiSolidSquareRounded style={{marginRight: 8, color: Color.gray40}} size={4} />
          <Text label={item?.title} size={12} />
        </button>
        <button onClick={() => clickHandler()}>
          <IoMdMore />
        </button>
      </Flex.SpaceBetween>
    </Content>
  );
};

export default Contents;

const Content = styled.main`
  display: flex;
  /*-- item */
  .item {
    display: flex;
    margin: 5px 10px;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    :hover {
      text-decoration: underline;
    }
  }
`;
