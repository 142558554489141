import {Card, ChartLegend, EchartsDefaultOption, ValueUnitList} from '../common';
import {Props} from '../template_factory';
import {useEffect, useState} from 'react';
import {Echarts, Flex} from 'components';
import {useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';
import {uuid} from 'lib/utils';
import {greenBlueBlack} from 'styles/color';
import {SeriesOption, TooltipComponentOption} from 'echarts';
import {Volume} from '../common/volume';

type ColorType = {
  chartColor?: string[];
  labelColor?: string[];
};

const Contents = ({item, chartColor = greenBlueBlack, labelColor = greenBlueBlack}: Props & ColorType) => {
  const {chart, valueUnit} = useCardsCardNoQuery(item?.cardNo);
  const [options, setOption] = useState<echarts.EChartsOption>();

  // 차트 세팅
  useEffect(() => {
    if (!chart) return;
    setOption({
      series: chart.map((item, index) => {
        return {
          type: 'line',
          data: item.data,
          name: item.label,
          showSymbol: false,
          itemStyle: {
            color: greenBlueBlack[index + 1] as string
          }
        };
      }),
      grid: {top: 40, left: 30, right: 20, bottom: 30},
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: (params: any) => {
          return params
            .map((item: any) => {
              return (
                item.data &&
                `
              <div>
                <span style="margin-right: 3px;">${item.marker}</span>
                <span style="margin-right: 3px;">${item.seriesName}</span>
                <b>${Number(item.value).toFixed(1)}</b>
              </div>
              `
              );
            })
            .join('');
        }
      },
      xAxis: {
        type: 'category',
        data: Array.from({length: 24}, (_, index) => {
          return index.toString().padStart(2, '0');
        }),
        name: '시',
        nameLocation: 'end',
        nameTextStyle: {
          fontSize: 10,
          padding: [0, 0, 5, -20],
          verticalAlign: 'bottom'
        },
        axisLabel: {
          interval: 1
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          lineStyle: {
            type: 'dashed',
            width: 1
          }
        },
        axisLine: {
          symbol: 'none',
          lineStyle: {
            type: 'dashed'
          }
        }
      },

      yAxis: {
        type: 'value',
        splitNumber: 3,
        name: chart[0].unit,
        nameLocation: 'end',
        nameTextStyle: {
          fontSize: 10,
          padding: [0, 40, 0, 0]
        }
      }
    });
  }, [chart]);

  return (
    <Card
      title={item?.title}
      topRight={<Volume valueUnit={valueUnit?.filter((item) => item.label === '설비용량')[0]} />}>
      {/* 차트 */}
      <div>{options && <Echarts options={options} width={'100%'} height={150} />}</div>

      {/* 값 */}
      <ValueUnitList items={valueUnit?.filter((item) => item.label !== '설비용량')} colors={greenBlueBlack} />

      {/* 범례 */}
      <Flex.Center style={{gap: 20}}>
        {chart?.map((item, index) => {
          return <ChartLegend key={uuid()} color={greenBlueBlack[index + 1] as string} title={item.label} />;
        })}
      </Flex.Center>
    </Card>
  );
};

export default Contents;
