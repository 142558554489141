/**
 * @name 대시보드,아이템,리스트
 * @description
 */
import {desktop_max_width} from 'styles';
import styled from 'styled-components';
import {useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';
// contents
import {Card} from '../../../common';
import Demand from './demand';
import Voltage from './voltage';
import Current from './current';
import Thd from './thd';
import {Flex} from 'components';

type Props = {
  cardNo: number;
};
const Contents = ({cardNo}: Props): JSX.Element => {
  const {data: info} = useCardsCardNoQuery(cardNo);

  return (
    <Card columnSpan={3}>
      {info && (
        <Content>
          {/* 현재수요 */}
          <Demand info={info} />
          {/* 현재전압 */}
          <Voltage info={info} />
          {/* 고조파왜형률 */}
          <Thd info={info} />
          {/* 현재전류 */}
          <Current info={info} />
        </Content>
      )}
    </Card>
  );
};

export default Contents;

const Content = styled.article`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 50px;

  @media all and (max-width: ${desktop_max_width}) {
    flex-direction: column;
    gap: 50px;
    padding: 10px;
  }
`;
