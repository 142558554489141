/*
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
  RIGHT SECTION

  
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
*/

import React from 'react';
import styled from 'styled-components';
import {EnergyType} from './energy_type';
import {Cart} from './cart';

const Index: React.FC = () => {
  // 버블의 크기 및 위치 설정
  return (
    <Content>
      {/* 에너지 별 발전량 */}
      <EnergyType />
      {/* 전기카트 */}
      <Cart />
    </Content>
  );
};

const Content = styled.div`
  display: grid;
  width: 100%;
  height: 730px;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px;
`;

export default Index;
