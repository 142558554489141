/**
 * @name 현재수요
 * @description
 */
import {Card, ValueUnit} from '../../../common';
import {Flex, Text} from 'components';
import {CardsCardNo} from 'pages/dashboard/api/type';
import {Color} from 'styles';

type Props = {
  info: CardsCardNo;
};
const Contents = ({info}: Props): JSX.Element => {
  return (
    <Flex.Column>
      <Text className="title" label="현재 수요" mode={'Title_LB'} color={Color.gray30} />
      {/* 타이틀,설명,자세히보기 */}
      <Flex.Column className="content" style={{paddingTop: 30}} gap={10}>
        <ValueUnit
          value={Number(info[0].data)}
          unit={info[0].unit}
          valueColor={Color.gray10}
          minimumFractionDigits={1}
          maximumFractionDigits={1}
          style={{marginBottom: 4}}
          titleStyle={{width: 40, margin: 0}}
          valueStyle={{width: 50, marginRight: 10}}
          unitStyle={{width: 5}}
        />
        <ValueUnit
          title={info[1].name}
          value={Number(info[1].data)}
          valueColor={Color.gray10}
          unit={info[1].unit}
          minimumFractionDigits={1}
          maximumFractionDigits={1}
          titleStyle={{width: 25, margin: 0}}
          valueStyle={{width: 45}}
          unitStyle={{width: 5}}
        />
      </Flex.Column>
    </Flex.Column>
  );
};

export default Contents;
