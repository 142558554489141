/**
 * @name 전류
 * @description
 */
import {useEffect, useState} from 'react';
import {Card} from '../../../common';
import {Flex, Text} from 'components';
import {Color} from 'styles';
import {Echarts} from 'components';
import {CardsCardNo} from 'pages/dashboard/api/type';

type Props = {
  info: CardsCardNo;
};

const Contents = ({info}: Props): JSX.Element => {
  // const { isLoading: loading, data } = useCardsCardNoQuery(info[0].cardNo);
  const min = 0;
  const max = 1000;
  const [options, setOptions] = useState<echarts.EChartsOption>();

  useEffect(() => {
    if (!info) return;
    setOptions({
      series: [
        {
          type: 'gauge',
          center: ['50%', '65%'],
          startAngle: 180,
          endAngle: 0,
          min: min,
          max: max,
          axisTick: {show: false},
          axisLabel: {show: false},
          splitLine: {show: false},
          pointer: {show: false}, // 포인터 숨기기
          itemStyle: {
            color: Color.gray10
          },
          axisLine: {
            lineStyle: {
              width: 16 // 너비 설정
            }
          }
        },
        // 게이지차트
        {
          type: 'gauge',
          center: ['50%', '65%'],
          startAngle: 180,
          endAngle: 0,
          min: min,
          max: max,
          axisTick: {show: false},
          axisLabel: {show: false},
          splitLine: {show: false},
          pointer: {show: false}, // 포인터 숨기기
          itemStyle: {
            color: Color.blue30
          },
          progress: {
            show: true,
            width: 16
          },
          detail: {
            valueAnimation: true,
            width: '20%',
            lineHeight: 40,
            borderRadius: 8,
            offsetCenter: [0, '-5%'],
            fontSize: 20,
            fontFamily: 'Pretendard',
            fontWeight: 700,
            formatter: function (value) {
              const roundedValue = value.toFixed(1); // 값을 소수점 한 자리까지 반올림
              return `{a|${roundedValue}} {b|${info[7].unit}}`;
            },
            rich: {
              a: {
                color: Color.gray10,
                fontSize: 24,
                fontFamily: 'Pretendard',
                fontWeight: 700,
                lineHeight: 28
              },
              b: {
                color: Color.gray50,
                fontSize: 12,
                fontFamily: 'Pretendard',
                fontWeight: 700,
                lineHeight: 30
              }
            },
            color: Color.gray10
          },
          data: [{value: Number(info[7]?.data)}]
        }
      ]
    });
  }, [info]);

  return (
    <Flex.Column alignItems="center">
      <Text className="title" label="전류" mode={'Title_LB'} color={Color.gray30} />
      {/* 타이틀,설명,자세히보기 */}
      <Flex.Column className="content" flexDirection="column">
        <div>{options && <Echarts options={options} width={200} height={200} />}</div>
      </Flex.Column>
    </Flex.Column>
  );
};

export default Contents;
