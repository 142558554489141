/**
 * @name summary
 * @description
 */
import {Flex, Text} from 'components';
import {addComma} from 'lib/utils';
import styled from 'styled-components';
import {Color} from 'styles';
// contents
import {ValueUnit} from '../../../common';
import {CardsCardNo} from 'pages/dashboard/api/type';
import {IconTime} from 'assets/svg';

type Props = {
  info?: CardsCardNo;
};
const Contents = ({info}: Props): JSX.Element => {
  return !!info ? (
    <Content>
      <Flex.Center style={{marginBottom: 30}}>
        <IconTime />
        <Text
          style={{marginLeft: 5}}
          mode="Title_SB"
          label={`00시 부터 ~ ${new Date().getHours()}시 까지`}
          color={Color.gray50}
        />
      </Flex.Center>
      <Flex.SpaceBetween>
        {/*========================= 예상 */}
        <Flex.Center flexDirection="column">
          <Text label={info[3]?.name} mode="Title_SB" color={Color.gray30} style={{marginBottom: 15}} />
          <ValueUnit
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            value={Number(info[3]?.data)}
            unit={info[3]?.unit}
            valueColor={Color.gray40}
            style={{marginBottom: 12}}
          />
          {/* 현재전력량 */}
          <ValueUnit
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            valueColor={Color.gray40}
            value={Number(info[5]?.data)}
            unit={info[5]?.unit}
            style={{marginBottom: 12}}
          />
          {/* "현재 전기료" */}
          <ValueUnit
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            valueColor={Color.gray40}
            value={Number(info[7]?.data)}
            unit={info[7]?.unit}
          />
        </Flex.Center>
        {/*========================= 현재 */}
        <Flex.Center flexDirection="column">
          <Text label={info[2]?.name} mode="Title_SB" color={Color.blue30} style={{marginBottom: 15}} />
          <ValueUnit
            value={Number(info[2]?.data)}
            unit={info[2]?.unit}
            valueColor={Color.gray40}
            style={{marginBottom: 12}}
          />
          {/* 현재전력량 */}
          <ValueUnit
            valueColor={Color.blue30}
            value={Number(info[4]?.data)}
            unit={info[4]?.unit}
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            style={{marginBottom: 12}}
          />
          {/* "현재 전기료" */}
          <ValueUnit
            valueColor={Color.blue30}
            value={Number(info[6]?.data)}
            maximumFractionDigits={0}
            minimumFractionDigits={0}
            unit={info[6]?.unit}
          />
        </Flex.Center>
      </Flex.SpaceBetween>
      {/* 절감효과 */}
      <Flex.End style={{marginTop: 30}}>
        <Text label={info[8]?.name} mode="Title_SB" color={Color.gray40} style={{marginRight: 8}} />
        <Text
          label={
            addComma({
              value: Number(info[8]?.data),
              minimumFractionDigits: 0,
              maximumFractionDigits: 0
            }) + info[8]?.unit.toString()
          }
          mode="Headline_SB"
          color={Color.green30}
          style={{marginRight: 4}}
        />
        <Text label="절감" mode="Body_MM" color={Color.green30} />
      </Flex.End>
    </Content>
  ) : (
    <span />
  );
};

export default Contents;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 10px;
  padding: 0 27px;
  /* padding-right: 27px; */
`;
