/**
 * @name 로그관련유틸함수
 * @param message
 */
export const log = (message: string | number | {} | [], css?: string): void => {
  if (process.env.NODE_ENV !== 'development') return;
  const styles = {
    base: ['color: #888', 'font-size:12px', 'padding: 0 4px'],
    string: ['padding: 2px 4px', 'color: #111', 'background-color: orange'],
    object: ['padding: 0', 'font-size:11px', 'font-weight:normal'],
    number: ['background-color: green']
  };
  // 스타일링있을때
  if (css) {
    console.log(`%c${JSON.stringify(message)}`, css);
    return;
  }
  let style = styles.base.join(';') + ';';
  // 문자열
  if (typeof message === 'string') {
    style += styles.string.join(';') + ';';
    console.log(`%c${message}`, style);
  } else {
    style += styles.object.join(';') + ';';
    console.log(`%c${JSON.stringify(message, null, 2)}`, style);
  }
};
// 로그색상사용
log.red = (message: string | number | {} | [], css?: string) => {
  log(message, 'color: red;');
};
log.blue = (message: string | number | {} | [], css?: string) => {
  log(message, 'color: blue;');
};
log.green = (message: string | number | {} | [], css?: string) => {
  log(message, 'color: teal;');
};
