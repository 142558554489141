import {IconTime} from 'assets/svg';
import {Flex, Text} from 'components';
import {Dayjs} from 'lib/events';
import dayjs from 'lib/events/dayjs';
import {Color} from 'styles';

type Props = {
  fomat: string;
};

const Date = ({fomat}: Props): JSX.Element => {
  Dayjs.locale('ko');
  return (
    <Flex.Center>
      <IconTime />
      <Text style={{marginLeft: 5}} size={12} weight={500} label={dayjs().format(fomat)} color={Color.gray50} />
    </Flex.Center>
  );
};

export default Date;
