/**
 * @name Auth
 * @description
 */
import { create } from "zustand";

type AuthType = {
  // token
  accessToken: string | null;
  set_accessToken: (str: string) => void;
  // siteID
  siteId: string;
  set_siteId: (str: string) => void;
};

export const useAuthStore = create<AuthType>((set) => ({
  //-------------------------------- [AccessToken]
  accessToken: null,
  set_accessToken: (token: string) => set({ accessToken: token }),
  //-------------------------------- [siteId]
  siteId: "",
  set_siteId: (siteId: string) => set({ siteId: siteId }),
}));
