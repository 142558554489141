/**
 * Routes.tsx (src/config/routes.tsx)
 * ========================================
 * (1) 메뉴생성
 * (2) 라우터설정
 * (3) GNB메뉴설정
 */

import * as Page from 'pages';
import {MenuType, RoutesType, GnbMenuType} from 'types';

/**
 * @name RoutesConfig
 * @description 라우터설정
 */
export const RoutesConfig: RoutesType[] = [
  /** ------------------------------------------------------- @name General */
  {key: 'HOME', path: '/', component: <Page.Home />},

  /** ------------------------------------------------------- @name Auth */
  {key: 'SIGN_IN', path: '/auth/sign-in', component: <Page.SignIn />},
  {key: 'SIGN_OUT', path: '/auth/sign-out', component: <Page.SignOut />},

  /** ------------------------------------------------------- @name Pages */
  {key: 'DASHBOARD', path: '/dashboard', component: <Page.Dashboard />},
  {key: 'ENERGY', path: '/energy', component: <Page.Energy />},
  {key: 'SETTING', path: '/setting', component: <Page.Setting />},

  /** ------------------------------------------------------- @name Demo */
  {key: 'DEMO_STYIE_GUIDE', path: '/demo/style-guide', component: <Page.StyleGuide />},
  {key: 'DEMO_TODOS', path: '/demo/todos', component: <Page.Todos />},
  {key: 'DEMO_ECHART', path: '/demo/echart', component: <Page.Echart />},

  /** ------------------------------------------------------- @name Etc */
  {key: 'GUIDE', path: '/guide', component: <Page.Guide />},
  {key: 'TEST', path: '/test', component: <Page.Test />}
];

/**
 * @name MENU
 * @description 메뉴타입
 * @example 
  { "HOME": "/", "GUIDE": "/guide" }
 */

export const MENU = RoutesConfig.reduce((acc: MenuType, current: RoutesType) => {
  acc[current.key] = current.path;
  return acc;
}, {});

/**
 * @name GNB메뉴
 * @description
 */
export const GNB_MENU: GnbMenuType[] = [
  {name: '대시보드', path: MENU.DASHBOARD},
  {
    name: '스타일컴포넌트',
    path: `${MENU.DEMO_STYIE_GUIDE}`,
    children: [
      {name: '스타일가이드', path: MENU.DEMO_STYIE_GUIDE},
      {name: 'TEST', path: MENU.TEST},
      {name: 'Echarts', path: MENU.DEMO_ECHART},
      {name: 'Todos', path: MENU.DEMO_TODOS}

      // {name: 'Drag Drop', path: MENU.DRAG_DROP},
      // {name: 'Arrows', path: MENU.ARROWS},
      // {name: 'map', path: MENU.MAP},
      // {name: '[map] Google', path: MENU.MAP_GOOGLE},
      // {name: '[map] KaKao', path: MENU.MAP_KAKAO}
    ]
  },
  {name: 'TEST', path: MENU.TEST},
  {name: '가이드', path: MENU.GUIDE},
  {name: '에너지현황', path: MENU.ENERGE}
];
