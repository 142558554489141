/**
 * @name ACELO_CVR
 * @description
 */
// types
import {useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';
// contents
import {Card} from '../../../common';
import Header from './title';
import Chart from './chart';
import Summary from './summary';

type Props = {
  cardNo: number;
};
const Contents = ({cardNo}: Props): JSX.Element => {
  const {data: info} = useCardsCardNoQuery(cardNo);

  return (
    <Card rowSpan={2} title={<Header />}>
      {/* 메인 */}
      {info && (
        <>
          {/* 차트 */}
          <Chart info={info} />
          {/* 레이블,하단 */}
          <Summary info={info} />
        </>
      )}
    </Card>
  );
};

export default Contents;
