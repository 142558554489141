import {Button, Flex, Text} from 'components';
import {styled} from 'styled-components';
import {Color} from 'styles';
import Table from '../common/table';

const Index = () => {
  const headers = [{label: 'NO'}, {label: '설비 모델명'}, {label: '최소운전전압'}];

  return (
    <Flex.Center flexDirection={'column'} style={{paddingTop: 20, gap: 20}}>
      <Flex.Start width={'1000px'}>
        <Text label={'설정'} weight={700} size={32} />
      </Flex.Start>
      <Card>
        {/* 타이틀 */}
        <Flex.SpaceBetween style={{padding: '0px 26px', height: 50}}>
          <Text label={'SST'} weight={700} size={16} />
          <div>버튼</div>
        </Flex.SpaceBetween>
        <Line />

        {/* 전압 */}
        <Flex.SpaceBetween>
          <Box>
            <Text label={'전격 전압'} weight={700} size={16} />
            <BoxText>
              <Text label={'380'} color={Color.blue30} weight={700} size={30} />
              <Text label={'V'} weight={700} size={20} style={{marginLeft: 12}} />
            </BoxText>
          </Box>
          <Box>
            <Text label={'목표 전압'} weight={700} size={16} />
            <BoxText>
              {/* <Text label={'380'} color={Color.blue30} weight={700} size={30} /> */}
              <input style={{fontSize: 18, width: 100, height: 50}} placeholder="전압 입력" />
              <Text label={'V'} weight={700} size={20} style={{marginLeft: 12}} />
            </BoxText>
          </Box>
          <Box $last={true}>
            <Text label={'최소 운전 전압'} weight={700} size={16} />
            <BoxText>
              <Text label={'380'} color={Color.blue30} weight={700} size={30} />
              <Text label={'V'} weight={700} size={20} style={{marginLeft: 12}} />
            </BoxText>
          </Box>
        </Flex.SpaceBetween>
        <Line />

        {/* 설비 리스트 */}
        <ListWrap>
          <Text label={'설비 리스트'} weight={700} size={16} />
          <TableWrap>
            <Table headers={headers}>
              <tr>
                <td>1</td>
                <td>Model_name_01</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Model_name_02</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Model_name_03</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Model_name_01</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Model_name_02</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Model_name_03</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Model_name_01</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Model_name_02</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Model_name_03</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Model_name_01</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Model_name_02</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Model_name_03</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>1</td>
                <td>Model_name_01</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>2</td>
                <td>Model_name_02</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
              <tr>
                <td>3</td>
                <td>Model_name_03</td>
                <td>
                  <div>
                    <input style={{fontSize: 12, width: 100, height: 30}} placeholder="전압 입력" /> v
                  </div>
                </td>
              </tr>
            </Table>
          </TableWrap>
        </ListWrap>
        <Line />

        {/* 버튼 */}
        <ButtonWrap>
          <Button label={'취소'} style={{backgroundColor: Color.gray80, height: 40, width: 90, color: Color.gray60}} />
          <Button label={'저장'} style={{backgroundColor: Color.blue30, height: 40, width: 90, color: Color.white}} />
        </ButtonWrap>
      </Card>
    </Flex.Center>
  );
};

export default Index;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
  overflow: hidden;
  border-radius: 10px;
  background-color: ${Color.white};
  border: 1px solid ${Color.gray70};
  box-shadow: 3px 3px 50px 0px rgba(0, 0, 0, 0.1);
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${Color.gray70};
`;

const Box = styled.div<{$last?: boolean}>`
  display: flex;
  padding: 20px 26px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
  border-right: ${(props) => (props.$last ? undefined : `1px solid ${Color.gray70}`)};
`;

const BoxText = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100px;
  height: 100%;
`;

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  height: 460px;
  padding: 20px 26px;
`;

const TableWrap = styled.div`
  width: 100%;
  height: 380px;
  overflow-y: auto;
  border-top: 1px solid ${Color.blue50};
`;

const ButtonWrap = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: end;
  padding: 20px 26px;
  gap: 10px;
`;
