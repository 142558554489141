/**
 * @name ImageButton
 * @description 버튼
 */
import {logo_typescript} from 'assets/svg';
// contents
import Button from './button';
import {ButtonProps} from './type';

type Props = {
  isFit?: boolean; //------------------------------------------------이미지가 버튼에 맞게 꽉차게 할지 여부
  width?: string | number; //----------------------------------------이미지넓이
  height?: string | number; //---------------------------------------이미지높이
  src?: React.ImgHTMLAttributes<HTMLImageElement>['src']; //---------이미지속성
};
type ButtonImageType = ButtonProps & Props;

const ButtonImage = (props: ButtonImageType): JSX.Element => {
  return (
    <Button style={{fontSize: 0}} {...props}>
      <img
        style={{width: props?.width, height: props?.height, objectFit: props?.isFit ? 'cover' : 'initial'}}
        src={props?.src || logo_typescript}
        alt={props?.alt || 'imagebutton'}
      />
      {props?.children}
    </Button>
  );
};

export default ButtonImage;
/********************************************************
[사용법]
  <Button.Image
    width={200}
    height={70}
    src="https://source.unsplash.com/random/?"
    hover_src=" https://source.unsplash.com/random/?seoul"
    onClick={event => {
      console.log(event)
    }}
  />
*********************************************************/
