/**
 * @name Controller
 * @description
 */
import {Events} from 'lib/events';
import React, {Suspense, useEffect} from 'react';
// model
import {Command as Cmd} from '../model';
import {AnyType} from 'types';

// Lazy load
const Main = React.lazy(() => import('../view/main'));

const Controllers: React.FC = () => {
  function onSubmit(payload: AnyType): void {
    alert(payload);
  }
  useEffect(() => {
    Events.on(Cmd.submit, onSubmit);
    return () => {
      Events.off(Cmd.submit, onSubmit);
    };
  }, []);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {/* ==Main== */}
      <Main />
    </Suspense>
  );
};

export default Controllers;
