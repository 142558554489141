/**
 * @name 현재전압
 * @description
 */
import {Card, ValueUnit} from '../../../common';
import {Flex, Text} from 'components';
import {CardsCardNo} from 'pages/dashboard/api/type';
import {Color} from 'styles';

type Props = {
  info: CardsCardNo;
};
const Contents = ({info}: Props): JSX.Element => {
  return (
    <Flex.Column>
      <Text className="title" label="현재 전압" mode={'Title_LB'} color={Color.gray30} />
      {/* 타이틀,설명,자세히보기 */}
      <Flex.Column
        className="content"
        alignItems="flex-end"
        flexDirection="column"
        style={{paddingRight: '35%', paddingTop: 30}}>
        <ValueUnit
          value={Number(info[2].data)}
          unit={info[2].unit || 'V'}
          valueColor={Color.gray10}
          style={{marginBottom: 4}}
          minimumFractionDigits={1}
          maximumFractionDigits={1}
          titleStyle={{width: 40, margin: 0}}
          valueStyle={{width: 50, marginRight: 10}}
          unitStyle={{width: 5}}
        />
        <ValueUnit
          title={info[3].name}
          value={Number(info[3]?.data)}
          valueColor={Color.gray10}
          unit={info[3]?.unit || 'V'}
          style={{marginBottom: 4}}
          minimumFractionDigits={1}
          maximumFractionDigits={1}
          titleStyle={{width: 40, margin: 0}}
          valueStyle={{width: 50, marginRight: 10}}
          unitStyle={{width: 5}}
        />
        <ValueUnit
          title={info[4].name}
          value={Number(info[4].data)}
          valueColor={Color.gray10}
          unit={info[4].unit || 'V'}
          minimumFractionDigits={1}
          maximumFractionDigits={1}
          titleStyle={{width: 40, margin: 0}}
          valueStyle={{width: 50, marginRight: 10}}
          unitStyle={{width: 5}}
        />
      </Flex.Column>
    </Flex.Column>
  );
};

export default Contents;
