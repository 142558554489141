/**
 * @name 고조파왜형률
 * @description
 */
import {Card, ValueUnit} from '../../../common';
import {Flex, Text} from 'components';
import {addComma} from 'lib/utils';
import {CardsCardNo} from 'pages/dashboard/api/type';
import {Color} from 'styles';

type Props = {
  info: CardsCardNo;
};
const Contents = ({info}: Props): JSX.Element => {
  return (
    <Flex.Column>
      <Text className="title" label="고조파 왜형률" mode={'Title_LB'} color={Color.gray30} />
      <Flex.Row className="content" style={{width: 250, paddingTop: 30}} gap={10}>
        <Flex.Column>
          {/* 타이틀 */}
          <Text label="전압" mode="Title_MB" color={Color.gray50} style={{marginBottom: 10}}>
            <Text label=" (" mode="Title_SB" color={Color.gray50} />
            <Text label="V" mode="Title_LB" color={Color.gray50} />
            <Text label="THD)" mode="Title_SB" color={Color.gray50} />
          </Text>
          <ValueUnit
            title={info[5].fieldNames[0]}
            value={Number(info[5]?.data[0])}
            unit={info[5]?.unit}
            valueColor={Color.gray10}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            style={{marginBottom: 4}}
            titleStyle={{width: 40, margin: 0}}
            valueStyle={{width: 50, margin: 0}}
            unitStyle={{width: 5}}
          />
          <ValueUnit
            title={info[5].fieldNames[1]}
            value={Number(info[5]?.data[1])}
            unit={info[5]?.unit}
            valueColor={Color.gray10}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            style={{marginBottom: 4}}
            titleStyle={{width: 40, margin: 0}}
            valueStyle={{width: 50, margin: 0}}
            unitStyle={{width: 5}}
          />
          <ValueUnit
            title={info[5].fieldNames[2]}
            value={Number(info[5]?.data[2])}
            unit={info[5]?.unit}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            valueColor={Color.gray10}
            titleStyle={{width: 40, margin: 0}}
            valueStyle={{width: 50, margin: 0}}
            unitStyle={{width: 5}}
          />
        </Flex.Column>
        <Flex.Center alignItems="flex-end" flexDirection="column">
          {/* 타이틀 */}
          <Text label="전류" mode="Title_MB" color={Color.gray50} style={{marginBottom: 10}}>
            <Text label=" (" mode="Title_SB" color={Color.gray50} />
            <Text label="I" mode="Title_LB" color={Color.gray50} />
            <Text label="TDD)" mode="Title_SB" color={Color.gray50} />
          </Text>
          <ValueUnit
            title={info[6].fieldNames[0]}
            value={Number(info[6].data[0])}
            unit={info[6].unit}
            valueColor={Color.gray10}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            style={{marginBottom: 4}}
            titleStyle={{width: 40, margin: 0}}
            valueStyle={{width: 50, margin: 0}}
            unitStyle={{width: 5}}
          />
          <ValueUnit
            title={info[6].fieldNames[1]}
            value={Number(info[6].data[1])}
            unit={info[6].unit}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            valueColor={Color.gray10}
            style={{marginBottom: 4}}
            titleStyle={{width: 40, margin: 0}}
            valueStyle={{width: 50, margin: 0}}
            unitStyle={{width: 5}}
          />
          <ValueUnit
            title={info[6].fieldNames[2]}
            value={Number(info[6].data[2])}
            unit={info[6].unit}
            minimumFractionDigits={2}
            maximumFractionDigits={2}
            valueColor={Color.gray10}
            titleStyle={{width: 40, margin: 0}}
            valueStyle={{width: 50, margin: 0}}
            unitStyle={{width: 5}}
          />
        </Flex.Center>
      </Flex.Row>
    </Flex.Column>
  );
};

export default Contents;
