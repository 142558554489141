/** ---------------------------------- @name API */
// export const API = "https://api-dev.acelogrid.com";
export const API = process.env.REACT_APP_API;

/** ---------------------------------- @name API_KEY */
export const GOOGLE_MAP_KEY = 'AIzaSyArzPXNGE4Xwx7mpmSkjIQNIbhHZv0yK_A';

/** ---------------------------------- @name KAKAO_API_KEY */
export const KAKAO_REST_API = '9c97e8aaa10542d168be1260e03d063d';

/********************************************************
[사용법]
- 환경 변수 설정 파일(.env.development, .env.production)에 API 주소와 키 값을 정의합니다.
- React 애플리케이션에서 process.env를 통해 해당 값들을 안전하게 사용합니다.
*********************************************************/
