/**
 * @name Container
 * @description width 100%, height 100vh, flex-direction column
 */

import {AppType} from 'types';
import {Color} from 'styles';
import styled from 'styled-components';
// contents

const Container = ({children}: AppType): JSX.Element => {
  return (
    <Content>
      <div className="children">{children}</div>
    </Content>
  );
};

export default Container;

const Content = styled.article`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  .children {
    flex: 1;
    display: flex;
    background-color: ${Color.white};
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
  }
`;
