import {Dropdown} from 'components';
import {useSst, useUserMe} from 'context';

import {styled} from 'styled-components';

const Sst = () => {
  const {data} = useUserMe();
  const {sst, setSst} = useSst();
  const options = ['SST1', 'SST2', 'SST3'];

  return (
    <Content>{data?.siteId === 'SST' && <Dropdown options={options} selectedOption={sst} onSelect={setSst} />}</Content>
  );
};
export default Sst;

const Content = styled.div`
  margin-right: 30px;
`;
