import {Flex, Text} from 'components';
import {Color} from 'styles';
import Date from '../../../common/date';
import SavingValue, {Values} from '../../../common/savingValue';
import {ValueUnit} from '../../../common/value-unit-list';
import {useEffect, useState} from 'react';

type Props = {
  valueUnit: ValueUnit[];
};

const Contents = ({valueUnit}: Props) => {
  const [data, setData] = useState<Values[]>();
  useEffect(() => {
    if (valueUnit?.length < 5) return;
    setData(
      [
        [1, 2], // SST OFF 관련 데이터
        [3, 4] // SST ON 관련 데이터
      ].map(([index1, index2]) => ({
        name: valueUnit[index1].label,
        data: [valueUnit[index1], valueUnit[index2]]
      }))
    );
  }, [valueUnit]);

  return (
    valueUnit && (
      <Flex.Column gap={20}>
        {/* 날짜 */}
        <Date fomat={'YYYY/MM/DD 00시부터 ~ HH시 까지'} />
        {/* 값 */}
        <SavingValue data={data} />
        <Flex
          style={{
            width: '100%',
            height: 60,
            backgroundColor: '#F5F9FD',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <Text label={'SST OFF 대비'} size={16} weight={700} />
          <Text
            label={`${valueUnit[5].value}% 절감`}
            color={Color.green30}
            size={16}
            weight={700}
            style={{marginLeft: 10}}
          />
        </Flex>
      </Flex.Column>
    )
  );
};
export default Contents;
