import {Energy} from './type';
import {ResponseType} from 'types';
import {fetchData} from 'api/fetch';
import {API} from 'config';

/**
 * @name 에너지현황 데이터
 * @description 에너지현황 가져오기
 */

export const getEnergy = async (): Promise<{data: Energy[]}> => {
  const res: ResponseType<{data: Energy[]}> = await fetchData(`${API}/data/soomy`, {method: 'GET'});
  return res.data;
};
