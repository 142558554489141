import {nonChargingCart, chargingCart} from 'assets/images';
import {Flex, Text} from 'components';
import uuid from 'lib/utils/uuid';
import {CardShadow} from 'pages/energy/common';
import {useEnergyQuery} from 'pages/energy/hooks';
import {css, styled} from 'styled-components';
import {Color} from 'styles';

export const Cart = () => {
  const {cart} = useEnergyQuery();

  return (
    <CardShadow
      title={'E-Mobility'}
      topRight={
        cart &&
        cart?.type !== 'error' && (
          <TopRight $isOn={cart?.value || 'false'}>{cart?.value === 'false' ? '대기중' : '충전중'}</TopRight>
        )
      }
      error={cart?.type === 'error' || !cart}>
      <Flex.Center>
        <img src={cart?.value === 'false' ? nonChargingCart : chargingCart} style={{height: 275, marginTop: 15}} />
      </Flex.Center>
    </CardShadow>
  );
};

const TopRight = styled.div<{$isOn: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 55px;
  font-size: 14px;
  font-family: 'Pretendard';
  font-weight: 700;
  color: ${Color.white};
  letter-spacing: 0.2px;
  background-color: #a2a2a2;
  border-radius: 0 12px 0 12px;
  ${({$isOn}) =>
    $isOn === 'false' &&
    css`
      background-color: ${Color.blue30};
    `};
`;
