import {useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';
import {Card} from '../common';
import {Props} from '../template_factory';
import Table from 'components/table/table';

const Contents = ({item, headers}: Props) => {
  const {data} = useCardsCardNoQuery(item?.cardNo);

  return (
    <Card rowSpan={2} title={item?.title}>
      {data && <Table headers={headers!} data={data as unknown as string[][]} />}
    </Card>
  );
};

export default Contents;
