/**
 * @name store
 * @description react-query, zustand, types
 */

export * from './store';

export const Command = {
  submit: 'guide/submit'
};
