/**
 * @name useObject
 * @description Object불변성유지
 */
import {useCallback, useState} from 'react';

// Hook
type KeyType<T> = {
  [P in keyof T]?: T[P];
};
export function useObject<T extends object>(initialState: T): {state: T; update: (data: KeyType<T>) => void} {
  // 초기값세팅
  const [state, setState] = useState<T>(initialState);

  const update = useCallback(
    (data: KeyType<T>): void =>
      setState((prevState) => {
        return {...prevState, ...data};
      }),
    []
  );
  return {state, update};
}

/********************************************************
[사용법]
import { useObject } from 'lib/hooks';
import {Input} from 'components';
import {Debug} from 'lib/utils';

const ExampleComponent: React.FC = () => {
  const {state: loginState, update} = useObject({email: '', password: ''});

  return (
    <>
      {Debug(loginState)}
      <Input
        name="email"
        className="input"
        onChange={({event, name, value}) => update({[name]: value})}
        style={{marginBottom: 16}}
        placeholder="아이디를 입력해 주세요."
      />

      <Input
        name="password"
        className="input"
        onChange={({name, value}) => update({[name]: value})}
        style={{marginBottom: 24}}
        placeholder="비밀번호를 입력해 주세요."
      />
    </>
  );
};
*********************************************************/
