import {boardTile, ess, map1, map2, map3, map4, solarHeat, solarLight, wind} from 'assets/svg';
import {Flex, Text} from 'components';
import {uuid} from 'lib/utils';
import {Item} from 'pages/energy/api/type';

import {CardShadow} from 'pages/energy/common';
import {NoData} from 'pages/energy/common/card/noData';
import {useEnergyQuery} from 'pages/energy/hooks';

import React, {Fragment, useEffect, useState} from 'react';
import styled from 'styled-components';
import {Color} from 'styles';

const Index: React.FC = () => {
  const {liveGeneration} = useEnergyQuery();
  const images = [map1, map2, map3, map4];
  const [currentIndex, setCurrentIndex] = useState(0); // 현재 보여줄 항목의 인덱스 상태

  // 1초마다 currentIndex를 업데이트하는 interval 설정
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  //  아이콘 값
  const getIcon = (label: string) => {
    switch (label) {
      case '풍력':
        return wind;
      case '태양열':
        return solarHeat;
      case 'ESS':
        return ess;
      default:
        return solarLight;
    }
  };

  return (
    <CardShadow>
      <Flex.Column style={{height: 730, padding: 23}}>
        <img src={images[currentIndex]} style={{height: 514}} />
        <BoardWrapper>
          <Flex.SpaceBetween
            style={{
              height: 180,
              width: '100%',
              backgroundColor: Color.gray90,
              borderRadius: 16,
              padding: '20px 10px 0'
            }}>
            {liveGeneration?.type !== 'error' && liveGeneration ? (
              liveGeneration.chart[currentIndex].map((item: {label: string; value: string[]}, index: number) => {
                return (
                  <Fragment key={uuid()}>
                    <Flex.SpaceBetween style={{padding: 20, gap: 15}} alignItems={'flex-end'}>
                      <Flex.Column alignItems="flex-start">
                        <Text label={item?.label} mode="Title_LB" style={{marginBottom: 16}} />
                        <Flex.SpaceBetween style={{marginBottom: 5}}>
                          <Flex alignItems={'flex-end'}>
                            <Text
                              label={item.label !== 'ESS' ? '누적발전량(일)' : '충전량'}
                              color={Color.gray40}
                              size={12}
                              weight={600}
                            />
                          </Flex>
                          <Flex alignItems={'flex-end'} justifyContent={'flex-end'} style={{gap: 4}}>
                            <Text label={`${item?.value[0]}`} color={Color.blue30} size={22} weight={700} />
                            <Text label={'kWh'} color={Color.gray40} size={14} weight={700} />
                          </Flex>
                        </Flex.SpaceBetween>
                        <Flex.SpaceBetween>
                          <Flex alignItems={'flex-end'}>
                            <Text
                              label={item.label !== 'ESS' ? '발전시간' : '방전량'}
                              color={Color.gray40}
                              size={12}
                              weight={600}
                            />
                          </Flex>
                          <Flex alignItems={'flex-end'} justifyContent={'flex-end'} style={{gap: 4}}>
                            <Text label={`${item?.value[1]}`} color={Color.yellow10} size={22} weight={700} />
                            <Text
                              label={item.label !== 'ESS' ? 'h' : 'kWh'}
                              color={Color.gray40}
                              size={14}
                              weight={700}
                            />
                          </Flex>
                        </Flex.SpaceBetween>
                      </Flex.Column>
                      <img src={getIcon(item?.label)} width={50} height={80} />
                    </Flex.SpaceBetween>
                    {/* 구분선 */}
                    {index === 0 && <Line />}
                  </Fragment>
                );
              })
            ) : (
              <NoData />
            )}
          </Flex.SpaceBetween>
          <BoardTitle src={boardTile} />
        </BoardWrapper>
      </Flex.Column>
    </CardShadow>
  );
};

const BoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 230px;
`;

const BoardTitle = styled.img`
  position: relative;
  top: -205px;
  z-index: 10;
`;

const Line = styled.div`
  margin-top: 25px;
  width: 5px;
  height: 100px;
  background: ${Color.white};
`;

export default Index;
