/*
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
  Energe

  
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
*/
import React from 'react';
import styled from 'styled-components';
import {App} from 'template';
// contents
import Left from './view/left';
import Right from './view/right';
import Center from './view/center';

const Energy: React.FC = () => {
  return (
    <App>
      <Content>
        {/* 왼쪽 영역 */}
        <Left />
        {/* 중앙영역 */}
        <Center />
        {/* 오른쪽영역 */}
        <Right />
      </Content>
    </App>
  );
};

export default Energy;

const Content = styled.div`
  display: grid;
  width: 100%;
  padding: 20px 10px;
  grid-template-columns: 1fr 2fr 1fr; /* 1:2:1 비율로 설정 */
  grid-gap: 20px;

  /* <!--media query --> */
  @media all and (min-width: 0) and (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
