import {useQuery} from '@tanstack/react-query';
import {fetchData} from 'api';
// types
import {ResponseType} from 'types';
import {API} from 'config';
import {CardsCardNo, Data} from '../api/type';
import {dashboardQueryKey} from '../api/querykey';
import {useEffect, useState} from 'react';
import {ValueUnit} from '../view/main/common/value-unit-list';

/**
 * @name  카드데이터조회
 * @description 사이트별 사이트별 Card ID로 카드 데이터를 조회합니다.
 */

export type Chart = {label: string; data: string[]; unit: string};

export const useCardsCardNoQuery = (cardNo?: number) => {
  const [valueUnit, setValueUnit] = useState<ValueUnit[]>();
  const [chart, setChart] = useState<Chart[]>();
  const {isLoading, data} = useQuery({
    enabled: !!cardNo,
    queryKey: [dashboardQueryKey.CARD_NO, cardNo],
    queryFn: async (): Promise<CardsCardNo> => {
      const res: ResponseType<CardsCardNo> = await fetchData(`${API}/cards/${cardNo}`, {method: 'GET'});
      return res?.data;
    }
    // refetchInterval: 1000 // 1시간
  });

  useEffect(() => {
    // 데이터가 없거나, 데이블데이터일 경우 리턴
    if (!data || (data && !data[0].data)) return;

    const {value, chart} = data?.reduce(
      (acc: {value: ValueUnit[]; chart: Chart[]}, item) => {
        // 값 데이터
        if (!item.data[0]?.name) {
          acc.value.push({
            label: item.name,
            value: Number(item.data[0]),
            unit: item.unit
          });
        }
        // 차트 데이터
        else {
          acc.chart.push({
            label: item.name,
            data: item.data[0].data,
            unit: item.unit
          });
        }
        return acc;
      },
      {value: [], chart: []}
    ) || {value: [], chart: []};

    setValueUnit(value);
    setChart(chart);
  }, [data]);

  return {
    isLoading: isLoading,
    data: data,
    valueUnit: valueUnit,
    chart: chart
  };
};
