import React, {useState} from 'react';
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import styled from 'styled-components';

interface ITodo {
  id: number;
  task: string;
}

let todos: ITodo[] = [
  {id: 1, task: 'Learn React'},
  {id: 2, task: 'Build a Todo App'}
];

const fetchTodos = async (): Promise<ITodo[]> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve([...todos]), 1000);
  });
};

const addTodo = async (newTask: string): Promise<ITodo> => {
  const newTodo: ITodo = {id: todos.length + 1, task: newTask};
  todos = [...todos, newTodo];
  return new Promise((resolve) => {
    setTimeout(() => resolve(newTodo), 500);
  });
};

const deleteTodo = async (todoId: number): Promise<void> => {
  todos = todos.filter((todo) => todo.id !== todoId);
  return new Promise((resolve) => {
    setTimeout(() => resolve(), 500);
  });
};
const updateTodo = async (updatedTodo: ITodo): Promise<ITodo> => {
  const index = todos.findIndex((todo) => todo.id === updatedTodo.id);
  if (index !== -1) {
    todos[index] = updatedTodo; // 업데이트 로직
    return new Promise((resolve) => {
      setTimeout(() => resolve(updatedTodo), 100);
    });
  }
  throw new Error('Todo not found');
};

const Contents: React.FC = () => {
  const queryClient = useQueryClient();
  const {data: todoList, isLoading} = useQuery<ITodo[], Error>({
    queryKey: ['todos'],
    queryFn: fetchTodos
  });

  const [newTask, setNewTask] = useState('');

  const todoAddMutation = useMutation<ITodo, Error, string, unknown>({
    mutationFn: addTodo,
    onSuccess: () => {
      // 쿼리를 올바르게 무효화하여 React Query v5에서 작동하도록 함
      void queryClient.invalidateQueries({
        queryKey: ['todos']
      });
    }
  });
  const todoUpdateMutation = useMutation<ITodo, Error, ITodo, unknown>({
    mutationFn: updateTodo,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['todos']
      });
    }
  });

  const todoDeleteMutation = useMutation<void, Error, number, unknown>({
    mutationFn: deleteTodo,
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey: ['todos']
      });
    }
  });

  if (isLoading) return <div>Loading...</div>;

  const handleAddTodo = (): void => {
    if (!newTask.trim()) return;
    void todoAddMutation.mutate(newTask);
    setNewTask('');
  };

  const handleDeleteTodo = (todoId: number): void => {
    void todoDeleteMutation.mutate(todoId);
  };
  const handleUpdateTodo = (todo: ITodo): void => {
    // 여기서 사용자로부터 새 task 문자열을 받아야 할 수도 있습니다.
    // 예를 들어, prompt 사용 또는 별도의 입력 필드를 사용할 수 있습니다.
    const newTask = prompt('Update the task:', todo.task);
    if (newTask !== null && newTask.trim() !== '') {
      const updatedTodo = {...todo, task: newTask};
      void todoUpdateMutation.mutate(updatedTodo);
    }
  };

  return (
    <Content>
      <h1>Todo List</h1>
      <input type="text" value={newTask} onChange={(e) => setNewTask(e.target.value)} placeholder="Add a new task" />
      <button onClick={handleAddTodo}>Add Todo</button>
      {todoList?.map((todo) => (
        <li key={todo.id}>
          {todo.task}
          <button onClick={() => handleDeleteTodo(todo.id)}>Delete</button>
          <button onClick={() => handleUpdateTodo(todo)}>Update</button>
        </li>
      ))}
    </Content>
  );
};

export default Contents;

const Content = styled.main`
  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin: 10px 0;
  }

  input,
  button {
    margin-right: 5px;
  }
`;
