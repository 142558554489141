/**
 * @name 변압기사용량
 * @id
 */
import React, {useEffect, useState} from 'react';
import {Echarts, Flex, Text} from 'components';
import styled from 'styled-components';
import {Color} from 'styles';
import {Card, ChartLegend, EchartsDefaultOption} from '../../../common';
import {IconTime} from 'assets/svg';
import dayjs from 'dayjs';
import {blueGrayRedYellowGreen} from 'styles/color';
import {Props} from '../../../template_factory';
import {useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';

const Contents = ({item}: Props): JSX.Element => {
  const {isLoading, data: card} = useCardsCardNoQuery(item?.cardNo);
  const [options, setOptions] = useState<echarts.EChartsOption>(EchartsDefaultOption({}));

  useEffect(() => {
    if (card && !isLoading) {
      // card.map((item: any) => {
      //   const temp = item?.data[0]?.data;
      //   console.log(item);
      // });
      setOptions((prevOptions) => ({
        ...prevOptions,
        xAxis: {
          type: 'category',
          data: Array.from({length: 901}, (_, index) => index.toString()),
          axisLabel: {
            formatter: function (value: string) {
              const hour = parseInt(value, 10);
              if (hour !== 0 && hour % 2 === 0) {
                return `${value}`;
              }
              return ``;
            },
            interval: 900 / 9 - 1, // 데이터 포인트 수에 따라 조정
            margin: 15
          },
          name: '초',
          axisTick: {
            show: true,
            lineStyle: {
              type: 'dashed',
              width: 1
            }
          },
          axisLine: {
            symbol: 'none',
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: card.map((item: any, index: number) => ({
          name: item.name,
          data: item?.data[0]?.data,
          type: 'line',
          lineStyle: {
            type: index === 0 ? 'solid' : 'solid', // 'solid', 'dotted', 'dashed' 중 선택
            width: 2, // 선의 두께
            color: blueGrayRedYellowGreen[index] // 선의 색상
          },
          color: blueGrayRedYellowGreen[index],
          showSymbol: false
        }))
      }));
    }
  }, [card, isLoading]);

  return !isLoading && card ? (
    <Card title={item?.title}>
      {/* {debug(card)} */}
      {/*======================== 차트영역 */}
      {options && <Echarts options={options} width={'100%'} height={130} />}
      {/*======================== 하단타이틀 */}
      <Flex.Center style={{marginTop: 20}}>
        <IconTime />
        <Text
          mode="Title_SB"
          style={{marginLeft: 5}}
          label={`${dayjs(card[3].startDate).format('HH:mm')} ~ ${dayjs(card[3].endDate).format('HH:mm')}`}
          color={Color.gray50}
        />
      </Flex.Center>
      {/*========================  범례 */}
      <Flex.Center style={{marginTop: 20}}>
        {card.map((item: any, index: number) => (
          <ChartLegend
            key={`${item.name}_${index}`}
            color={blueGrayRedYellowGreen[index]}
            title={item.name}
            style={{marginLeft: 10, marginRight: 10}}
          />
        ))}
      </Flex.Center>
    </Card>
  ) : (
    <React.Fragment />
  );
};

export default Contents;
