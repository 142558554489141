/**
 * @name Presenter
 * @returns
 */
// query
import styled from 'styled-components';
import {useEffect, useRef} from 'react';
import * as echarts from 'echarts';

const Contents: React.FC = () => {
  const chartRef = useRef<HTMLDivElement>(null);
  const data = [
    {x: 1, y: 10},
    {x: 2, y: 20},
    {x: 3, y: 30},
    {x: 4, y: 40},
    {x: 5, y: 50}
  ];
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);
      const option = {
        xAxis: {
          type: 'category',
          data: data.map((item) => item.x)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: data.map((item) => item.y),
            type: 'line'
          }
        ]
      };
      chart.setOption(option);

      return () => {
        chart.dispose(); // 컴포넌트가 unmount되면 ECharts 인스턴스를 해제합니다.
      };
    }
  }, [data]);
  const clickHandler = (): void => {
    //
  };
  return (
    <Content>
      <div ref={chartRef} style={{width: '100%', height: '400px'}} />
    </Content>
  );
};

export default Contents;

const Content = styled.div`
  width: 100%;
`;
