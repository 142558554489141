export const soomy = [
  {label: '구분'},
  {label: '설비용량', unit: 'kW'},
  {label: '누적발전량(일)', unit: 'kWh'},
  {label: '발전시간', unit: 'h'}
];

export const sst1 = [
  {label: '설비'},
  {label: '정격전압', unit: 'V'},
  {label: '최소운전전압', unit: 'V'},
  {label: '현재계량', unit: 'V'},
  {label: '변동', unit: 'V'}
];

export const sst1Data1 = [
  ['1', '380', '370', '390', '20'],
  ['2', '380', '370', '390', '20'],
  ['3', '380', '370', '390', '20'],
  ['4', '380', '370', '390', '20'],
  ['5', '380', '370', '390', '20'],
  ['6', '380', '370', '390', '20'],
  ['7', '380', '370', '390', '20'],
  ['8', '380', '370', '390', '20'],
  ['9', '380', '370', '390', '20'],
  ['10', '380', '370', '390', '20'],
  ['11', '380', '370', '390', '20'],
  ['12', '380', '370', '390', '20'],
  ['13', '380', '370', '390', '20'],
  ['14', '380', '370', '390', '20'],
  ['15', '380', '370', '390', '20'],
  ['16', '380', '370', '390', '20'],
  ['17', '380', '370', '390', '20'],
  ['18', '380', '370', '390', '20'],
  ['19', '380', '370', '390', '20'],
  ['20', '380', '370', '390', '20']
];

export const sst1Data2 = [
  ['1', '380', '310', '340', '30'],
  ['2', '380', '310', '340', '30'],
  ['3', '380', '310', '340', '30'],
  ['4', '380', '310', '340', '30'],
  ['5', '380', '310', '340', '30'],
  ['6', '380', '310', '340', '30'],
  ['7', '380', '310', '340', '30'],
  ['8', '380', '310', '340', '30'],
  ['9', '380', '310', '340', '30'],
  ['10', '380', '310', '340', '30'],
  ['11', '380', '310', '340', '30'],
  ['12', '380', '310', '340', '30'],
  ['13', '380', '310', '340', '30'],
  ['14', '380', '310', '340', '30'],
  ['15', '380', '310', '340', '30'],
  ['16', '380', '310', '340', '30'],
  ['17', '380', '310', '340', '30'],
  ['18', '380', '310', '340', '30'],
  ['19', '380', '310', '340', '30'],
  ['20', '380', '310', '340', '30']
];

export const sst1Data3 = [
  ['1', '380', '370', '380', '10'],
  ['2', '380', '370', '390', '20'],
  ['3', '380', '370', '390', '20'],
  ['4', '380', '370', '390', '20'],
  ['5', '380', '370', '390', '20'],
  ['6', '380', '370', '380', '10'],
  ['7', '380', '370', '380', '10'],
  ['8', '380', '370', '380', '10'],
  ['9', '380', '370', '380', '10'],
  ['10', '380', '370', '380', '10'],
  ['11', '380', '370', '380', '10'],
  ['12', '380', '370', '380', '10'],
  ['13', '380', '370', '380', '10'],
  ['14', '380', '370', '380', '10'],
  ['15', '380', '370', '380', '10'],
  ['16', '380', '370', '380', '10'],
  ['17', '380', '370', '380', '10'],
  ['18', '380', '370', '380', '10'],
  ['19', '380', '370', '380', '10'],
  ['20', '380', '370', '380', '10']
];

export const sst2Data1 = [
  ['2024-10-31 14:13:20', '정상운전'],
  ['2024-10-30 09:45:32', '히트싱크 과열'],
  ['2024-10-29 18:22:05', '저전압(정전)'],
  ['2024-10-28 11:37:41', '정상운전'],
  ['2024-10-27 15:59:13', '히트싱크 과열'],
  ['2024-10-26 07:03:28', '저전압(정전)'],
  ['2024-10-25 20:41:56', '정상운전'],
  ['2024-10-24 13:18:39', '히트싱크 과열'],
  ['2024-10-23 05:55:02', '저전압(정전)'],
  ['2024-10-22 22:30:47', '정상운전'],
  ['2024-10-21 16:07:10', '히트싱크 과열'],
  ['2024-10-20 08:44:33', '저전압(정전)'],
  ['2024-10-19 01:21:16', '정상운전'],
  ['2024-10-18 17:58:59', '히트싱크 과열'],
  ['2024-10-17 10:35:42', '저전압(정전)']
];

export const sst2Data2 = [
  ['2024-10-31 15:31:34', '정상운전'],
  ['2024-10-31 15:30:45', '히트싱크 과열'],
  ['2024-10-30 08:17:23', '정상운전'],
  ['2024-10-29 21:04:01', '저전압(정전)'],
  ['2024-10-28 13:50:39', '히트싱크 과열'],
  ['2024-10-27 06:37:17', '정상운전'],
  ['2024-10-26 23:23:55', '저전압(정전)'],
  ['2024-10-25 16:10:33', '히트싱크 과열'],
  ['2024-10-24 08:57:11', '정상운전'],
  ['2024-10-23 01:43:49', '저전압(정전)'],
  ['2024-10-22 18:30:27', '히트싱크 과열'],
  ['2024-10-21 11:17:05', '정상운전'],
  ['2024-10-20 04:03:43', '저전압(정전)'],
  ['2024-10-19 20:50:21', '히트싱크 과열'],
  ['2024-10-18 13:36:59', '정상운전'],
  ['2024-10-17 06:23:37', '저전압(정전)']
];

export const sst2Data3 = [
  ['2024-10-31 15:46:22', '정상운전'],
  ['2024-10-31 12:45:30', '저전압(정전)'],
  ['2024-10-30 05:32:08', '정상운전'],
  ['2024-10-29 22:18:46', '히트싱크 과열'],
  ['2024-10-28 15:05:24', '저전압(정전)'],
  ['2024-10-27 07:52:02', '정상운전'],
  ['2024-10-26 00:38:40', '히트싱크 과열'],
  ['2024-10-25 17:25:18', '저전압(정전)'],
  ['2024-10-24 10:11:56', '정상운전'],
  ['2024-10-23 02:58:34', '히트싱크 과열'],
  ['2024-10-22 19:45:12', '저전압(정전)'],
  ['2024-10-21 12:31:50', '정상운전'],
  ['2024-10-20 05:18:28', '히트싱크 과열'],
  ['2024-10-19 22:05:06', '저전압(정전)'],
  ['2024-10-18 14:51:44', '정상운전'],
  ['2024-10-17 07:38:22', '히트싱크 과열']
];
