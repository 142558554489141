/*
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
  이번 달 탄소 배출량
  
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
*/

import React from 'react';
import {co2} from 'assets/svg';
// index
import {Flex, Text} from 'components';
import {Color} from 'styles';
import {CardShadow} from 'pages/energy/common';
import {useEnergyQuery} from 'pages/energy/hooks';
import {addComma} from 'lib/utils/number';

const Co2: React.FC = () => {
  const {carbon} = useEnergyQuery();

  return (
    <CardShadow
      title={
        carbon?.type === 'error' || !carbon ? (
          '이번 달 탄소배출량'
        ) : (
          <Flex.Column alignItems={'flex-start'}>
            <Text label={`이번 달 탄소 배출량을`} mode="Title_MB" color={Color.gray10} />
            <Flex alignItems={'center'}>
              <Text label={addComma({value: carbon?.value ?? 0})} mode="Title_MB" color={Color.blue30} />
              <Text label=" tCO2" size={12} weight={600} color={Color.blue30} />
              <Text label={`이나 줄였어요.`} mode="Title_MB" color={Color.gray10} />
            </Flex>
          </Flex.Column>
        )
      }
      error={carbon?.type === 'error' || !carbon}
      background={co2}
    />
  );
};

export default Co2;
