import {Echarts} from 'components';
import {Chart} from 'pages/dashboard/hooks/useCard';
import {useEffect, useState} from 'react';
import {styled} from 'styled-components';
import {blueGreenGrayGreen} from 'styles/color';

type Props = {
  chart: Chart[];
};

const Contents = ({chart}: Props) => {
  const [options, setOptions] = useState<echarts.EChartsOption>();

  useEffect(() => {
    if (!chart) return;
    setOptions({
      legend: {
        data: chart.map((item) => item.label),
        bottom: 0,
        show: true,
        borderType: 'solid',
        icon: 'circle'
      },
      grid: {top: '40px', left: '30px', right: '20px', bottom: '65px'},
      xAxis: {
        type: 'category',
        data: Array.from({length: 24}, (_, index) => {
          return index.toString().padStart(2, '0');
        }),
        axisLabel: {
          margin: 10
        },
        name: '시',
        nameLocation: 'end',
        nameTextStyle: {
          fontSize: 10,
          padding: [0, 0, 5, -20],
          verticalAlign: 'bottom'
        },
        axisTick: {
          show: true,
          lineStyle: {
            type: 'dashed',
            width: 1
          }
        },
        axisLine: {
          symbol: 'none',
          lineStyle: {
            type: 'dashed'
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value}'
        },
        name: 'kW',
        nameLocation: 'end',
        nameTextStyle: {
          fontSize: 10,
          padding: [0, 30, 0, 0]
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      series: chart.map((item, index) => {
        return {
          type: 'bar',
          data: item.data,
          name: item.label,
          showSymbol: false,
          stack: 'total',
          emphasis: {
            focus: 'series'
          },
          barWidth: 10,
          itemStyle: {
            color: blueGreenGrayGreen[index] as string
          }
        };
      })
    });
  }, [chart]);

  return <Content>{options && <Echarts options={options} height={260} />}</Content>;
};

export default Contents;

const Content = styled.div`
  width: 100%;
  padding: 0 16px;
`;
