import {useQuery} from '@tanstack/react-query';
import {getEnergy} from '../api';
import {ENERGE_QUERY_KEY} from '../api/querykey';

/**
 * @name 에너지현황
 * @description 에너지현황 가져오기
 */
export const useEnergyQuery = () => {
  const {data} = useQuery({
    queryKey: [ENERGE_QUERY_KEY.ENERGY],
    queryFn: () => getEnergy(),
    refetchInterval: 3600000 // 1시간
  });

  const energy = data?.data[0];

  return {
    re50: energy?.re50,
    carbon: energy?.carbon,
    renewal: energy?.renewal,
    energyTypeGeneration: energy?.energyTypeGeneration,
    cart: energy?.cart,
    liveGeneration: energy?.liveGeneration,
    weather: energy?.weather
  };
};
