/**
 * @name Gudes
 * @description
 * @todo
 */
import React from 'react';
import {App} from 'template';
// controllers
// import Controller from './controller';
// views
import {Main} from './view';

const DashBoard: React.FC = () => {
  return (
    <App>
      <Main />
    </App>
  );
};
export default DashBoard;
