/**
 * @name 푸터메뉴
 * @description
 */
import styled from 'styled-components';
import Box, {MenuProps} from './box';
import {logout, tel} from 'assets/svg';
import uuid from 'lib/utils/uuid';

type Props = {
  isOpen: boolean;
};

const Contents = ({isOpen}: Props) => {
  const FOOTER_LIST: MenuProps[] = [
    {name: '02-6951-2493', icon: tel},
    {name: '로그아웃', icon: logout, route: '/auth/sign-out'}
  ];

  return (
    <Content>
      {FOOTER_LIST.map((item) => {
        return <Box key={uuid()} item={{...item, isOpen, isMenu: false}} />;
      })}
    </Content>
  );
};

export default Contents;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
