import React from 'react';
import {SVGProps} from '../type';

// 화살표다음
export const IconArrowNext: React.FC<SVGProps> = ({width, height, fill}: SVGProps) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox={'0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 6L15 12L9 18"
        stroke={fill || '#373737'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// 화살표이전
export const IconArrowPrev: React.FC<SVGProps> = ({width, height, fill}: SVGProps) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15 6L9 12L15 18"
        stroke={fill || '#373737'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// 화살표위
export const IconArrowUp: React.FC<SVGProps> = ({width, height, fill}: SVGProps) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 15L12 9L18 15"
        stroke={fill || '#373737'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// 화살표아래
export const IconArrowDown: React.FC<SVGProps> = ({width, height, fill}: SVGProps) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 9L12 15L18 9"
        stroke={fill || '#373737'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
/***
[사용법]
    <IconArrowNext />
    <IconArrowDown fill={Color.red10} />
    <IconArrowPrev fill={Color.blue10} />
    <IconArrowUp />
 */
