/**
 * @name 로그인(비쥬얼)
 * @description
 */
import {sign_in} from 'assets/images';
import styled from 'styled-components';
import {Color} from 'styles';

const Contents: React.FC = () => {
  return (
    <Content>
      <img src={sign_in} alt="signin_img" />
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  flex: 1.5;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${Color.blue100};
  img {
    width: 700px;
    height: 505px;
  }
  /*===================================================================[media-query] */
  @media all and (min-width: 0) and (max-width: 1000px) {
    display: none;
  }
`;
