/**
 * @name Contents
 * @returns
 */
import {useGuideStore} from 'pages/_guide/model';
import styled from 'styled-components';
import {Color} from 'styles';

const Contents: React.FC = () => {
  const {currentPage} = useGuideStore((state) => state);
  return (
    <Content>
      <h1>currentPage :{currentPage}</h1>
    </Content>
  );
};
export default Contents;

const Content = styled.header`
  display: flex;
  width: 100%;
  padding: 10px 20px;
  border-bottom: 1px dashed ${Color.gray50};
  background-color: ${Color.gray70};
`;
