/**
 * @name Const
 * @description
 */

// 세션스토리지
export const SESSION_STORAGE = {
  TOKEN: 'CROCUS_ACELO_GRID_TOKEN',
  USER_ID: 'CROCUS_ACELO_GRID_USER_ID',
  PASSWORD: 'CROCUS_ACELO_GRID_PASSWORD',
  FACTORY_ID: 'CROCUS_ACELO_GRID_FACTORY_ID' //------------------비지니스시퀀스
};

// 커스텀카드 title 분류
export const CUSTOM_CARD_TITLE = {
  SAVING_ENERGY: '에너지 절감',
  STANDARD: 'STANDARD',
  MAX_POWER: '최대전력 현황'
};

/********************************************************


*********************************************************/
