import {Flex, Text} from 'components';
import {Color} from 'styles';
import {Fragment} from 'react';
import {addComma, uuid} from 'lib/utils';
import {backBlueGreen} from 'styles/color';
import {ValueUnit} from './value-unit-list';

export type Values = {
  name: string;
  data: ValueUnit[];
};

type Props = {
  data?: Values[];
};

const SavingValue = ({data}: Props): JSX.Element => {
  return (
    <Fragment>
      {data?.map((item: any, index: number) => {
        return (
          <Flex.Column alignItems={'flex-start'} key={uuid()} style={{padding: 10}}>
            <Text label={item.name} color={backBlueGreen[index]} size={16} weight={700} style={{marginBottom: 10}} />
            <Flex.SpaceBetween>
              {item.data.map((data: any) => {
                return (
                  <div key={uuid()} style={{width: '100%'}}>
                    <Text
                      label={addComma({value: data.value || 0})}
                      color={backBlueGreen[index]}
                      size={20}
                      weight={700}
                    />
                    <Text label={data.unit} color={Color.gray30} size={10} weight={400} style={{marginLeft: 4}} />
                  </div>
                );
              })}
            </Flex.SpaceBetween>
            <Flex.Row key={uuid()}></Flex.Row>
          </Flex.Column>
        );
      })}
    </Fragment>
  );
};

export default SavingValue;
