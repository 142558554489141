/*
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
  LEFT SECTION
  - 시계,날씨연동
  - 
  
◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤◢◤
*/

import React from 'react';
import styled from 'styled-components';
// contents
import Weather from './weather';
import Re50 from './re50';
import Co2 from './co2';
import Progress from './progress';

const Index: React.FC = () => {
  return (
    <Content>
      {/*---------------------- 날씨 */}
      <Weather />
      {/*---------------------- RE50달성률 */}
      <Re50 />
      {/*---------------------- 탄소배출량 */}
      <Co2 />
      {/*---------------------- 신재생에너지 */}
      <Progress />
    </Content>
  );
};

const Content = styled.div`
  display: grid;
  width: 100%;
  height: 730px;
  grid-template-rows: 1.5fr 1fr 1fr 1fr;
  grid-gap: 20px;
`;

export default Index;
