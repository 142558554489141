/**
 * @name Presenter
 * @returns
 */
// query
import {useQuery} from '@tanstack/react-query';
import styled from 'styled-components';
import {Color} from 'styles';
import {IconMore} from 'assets/svg';
import {Flex} from 'components';
import {fetchData} from 'api';

interface Todo {
  id: string;
  title: string;
  done: boolean;
}

interface Comment {
  id: string;
  text: string;
  postId: string;
}

interface Profile {
  name: string;
}
const Contents: React.FC = () => {
  const {isLoading, data: todos} = useQuery<Todo[]>({
    queryKey: ['todos'],
    queryFn: () => fetchData(`http://localhost:3001/todos`)
  });

  return (
    <Content>
      {isLoading + ''}
      {todos &&
        todos.map((item: Todo, index: number) => {
          return (
            <Flex.SpaceBetween className="item" key={`${JSON.stringify(item)}_${index}`}>
              <button
                onClick={() => {
                  console.log(index);
                }}>
                {item.title}
              </button>
              <button>
                <IconMore />
              </button>
            </Flex.SpaceBetween>
          );
        })}
    </Content>
  );
};

export default Contents;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  width: 50%;
  /* button */
  button.item {
    padding: 5px 20px;
    border-bottom: 1px solid ${Color.gray50};
    font-size: 12px;
    background-color: ${Color.gray70};
    cursor: pointer;
    &:hover {
      background-color: ${Color.gray80};
    }
  }
`;
