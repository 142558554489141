/**
 * @name API
 * @description
 */

import {fetchData} from 'api';
import {ProductsList} from './type';

// 모의 API 호출: 카운터 값을 가져옵니다.
export const fetchCounterValue = async (): Promise<number> => {
  await new Promise((resolve) => setTimeout(resolve, 1000)); // 네트워크 지연 흉내
  return 10; // 가정된 초기 카운터 값
};

// 모의 API 호출: 카운터 값을 업데이트합니다.
export const updateCounterValue = async (newValue: number): Promise<number> => {
  await new Promise((resolve) => setTimeout(resolve, 1000)); // 네트워크 지연 흉내
  return newValue; // 새 카운터 값
};

//== [API] Products List
export const productsList = async (): Promise<ProductsList> => {
  const api = 'https://dummyjson.com/products';
  const res: ProductsList = await fetchData(api, {method: 'GET'});
  return res;
};
