/**
 * @name 값단위
 * @description
 */
import {Flex, Text} from 'components';
import {addComma, uuid} from 'lib/utils';
// types
import {Color} from 'styles';
import {styled} from 'styled-components';
// contents

export type ValueUnit = {
  label: string;
  value: number;
  unit: string;
};

type Props = {
  items?: ValueUnit[];
  colors: string[];
};

const Contents = ({items, colors}: Props) => {
  return (
    <Content>
      {/* 1개 우측정렬 */}
      {items && items.length === 1 && (
        <Flex.End style={{paddingBottom: 13}}>
          <Text label={String(items[0].label)} color={Color.gray50} size={10} weight={600} style={{marginRight: 10}} />
          <Text
            label={addComma({value: items[0].value || 0})}
            color={Color.blue30}
            size={20}
            weight={700}
            style={{alignSelf: 'end'}}
          />
          <Text
            label={items[0].unit}
            color={Color.gray50}
            size={10}
            weight={400}
            style={{marginLeft: 4, alignSelf: 'end'}}
          />
        </Flex.End>
      )}

      {/* 2개이상 // 2개 중앙 정렬, 3개 좌측정렬 */}
      {items && items.length > 1 && (
        <Flex.SpaceBetween gap={10}>
          {items.map((item: ValueUnit, index) => {
            return (
              <Flex.Column style={{alignItems: items.length === 2 ? 'center' : 'start', gap: 6}} key={uuid()}>
                <Text label={String(item.label)} color={Color.gray50} size={10} weight={600} />
                <Flex style={{justifyContent: items.length === 2 ? 'center' : 'start', alignItems: 'flex-end'}}>
                  <Text label={addComma({value: item?.value || 0})} color={colors[index]} size={20} weight={700} />
                  <Text label={item.unit} color={Color.gray50} size={10} weight={400} style={{marginLeft: 4}} />
                </Flex>
              </Flex.Column>
            );
          })}
        </Flex.SpaceBetween>
      )}
    </Content>
  );
};

export default Contents;

const Content = styled.div`
  display: flex;
  padding: 15px 10px;
  align-items: 'flex-end';
  height: 66px;
`;
