/**
 * @name UtilWeather(주소,날씨)
 * @todo 날씨삭제
 * @description
 */
import styled from "styled-components";
import { Text } from "components";
import { Color } from "styles";
import { useUserMe } from "context";

const UtilWeather: React.FC = () => {
  const { isLoading: loading, data: me } = useUserMe();

  return (
    <Content>
      {!loading && me && (
        <Text
          className="weather-panel"
          mode="Body_MM"
          label={me.siteAddress}
          color={Color.gray30}
        />
      )}
    </Content>
  );
};
export default UtilWeather;

const Content = styled.section`
  margin-right: 16px;
`;
