/**
 * @name 값단위
 * @description
 */
import {Text} from 'components';
import styled from 'styled-components';
// types
import {Color} from 'styles';
import {CSS} from 'types';
// contents

type Props = {
  style?: CSS;
  color?: string;
  title: string;
};
const Contents = ({style, color, title}: Props): JSX.Element => {
  return (
    <Content style={style}>
      {/* value */}
      <div className="icon" style={{background: color}} />
      <Text label={title} mode="Btn_S" color={Color.gray40} />
    </Content>
  );
};

export default Contents;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  .icon {
    width: 10px;
    height: 10px;
    margin-right: 4px;
    border-radius: 50%;
  }
`;
