/**
 * @name Location
 * @description GNB위치
 */
import styled from 'styled-components';
import {gnb_toggle_open, top_sitemap, top_sitemap_arrow} from 'assets/svg';
import {Button, Flex, Image, Text} from 'components';
import {useGnbStore} from 'context';
import {useEffect, useState} from 'react';
import {DepthInfo, findMenuDepth} from 'lib/utils';
import {GNB_MENU} from 'config';
import {Color} from 'styles';

type LocationType = {
  depth1: string;
  depth2: string;
};
const Location: React.FC = () => {
  const [location, setLocation] = useState<LocationType | null>(null);
  useEffect(() => {
    const depth = findMenuDepth() as DepthInfo;
    if (GNB_MENU && !!depth) {
      const temp = {
        depth1: GNB_MENU?.[depth?.depth1].name,
        depth2: depth?.depth2 && GNB_MENU?.[depth?.depth1]?.children?.[depth?.depth2]?.name
      } as LocationType;
      setLocation(temp);
    }
  }, []);
  return (
    <Content>
      <Flex.SpaceBetween>
        <Flex.Row>
          <Button
            src={top_sitemap}
            style={{
              marginRight: 8,
              width: 44,
              height: 44,
              borderRadius: 8,
              backgroundColor: Color.blue100
            }}
          />
          {/* {print(location)} */}
          <Text mode="Btn_M" label={location?.depth1 || 'HOME'} />
          {!!location?.depth2 && (
            <Flex.Row alignItems="center" style={{flex: 1}}>
              <Image
                src={top_sitemap_arrow}
                width={24}
                height={24}
                color={Color.gray30}
                style={{marginLeft: 4, marginRight: 4}}
              />
              <Text mode="Btn_M" label={location?.depth2} />
            </Flex.Row>
          )}
        </Flex.Row>
      </Flex.SpaceBetween>
    </Content>
  );
};
export default Location;

const Content = styled.div`
  min-width: 150px;
`;
