import {Text} from 'components';
import {useState} from 'react';
import {styled} from 'styled-components';
import {Color} from 'styles';

type Props = {
  state: boolean;
  trueText?: string;
  falseText?: string;
};

const Switch = ({state, trueText, falseText}: Props) => {
  const [isOn, setIsOn] = useState(state);

  return (
    <Container>
      <input type="checkbox" checked={isOn} onChange={() => setIsOn(!isOn)} style={{display: 'none'}} />
      <Toggle $isOn={isOn}>
        <Knob $isOn={isOn} />
      </Toggle>
      <Text label={isOn ? trueText : falseText} weight={700} size={14} />
    </Container>
  );
};
export default Switch;

const Container = styled.label`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`;

const Toggle = styled.div<{$isOn: boolean}>`
  width: 36px;
  height: 22px;
  background-color: ${(props) => (props.$isOn ? Color.blue30 : Color.gray60)};
  border-radius: 15px;
  position: relative;
  transition: background-color 0.2s;
`;

const Knob = styled.div<{$isOn: boolean}>`
  width: 14px;
  height: 14px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: ${(props) => (props.$isOn ? '18px' : '4px')};
  transition: left 0.2s;
`;
