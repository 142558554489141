import {noDataGray, noDataWhite} from 'assets/svg';
import {Flex, Text} from 'components';
import {Color} from 'styles';

type Props = {
  color?: string;
};
export const NoData = ({color = Color.gray40}: Props) => {
  return (
    <Flex.Center flexDirection={'column'} style={{height: '100%', gap: 5, paddingBottom: 20}}>
      <img src={color === Color.gray40 ? noDataGray : noDataWhite} style={{marginBottom: 5}} />
      <Text label={'데이터를 불러오는 데 실패했습니다.'} size={12} weight={600} color={color} />
      <Text label={'다시 시도해주세요.'} size={12} weight={600} color={color} />
    </Flex.Center>
  );
};
