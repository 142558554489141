/**
 * @name Util
 * @description
 */
import styled from "styled-components";
import { Flex, Image, Text } from "components";
import { Color } from "styles";
import { useRoutes } from "lib/hooks";
import { MENU } from "config";
import { useUserMe } from "context";

const UtilProfile: React.FC = () => {
  const { navigate } = useRoutes();
  const { isLoading: loading, data: me } = useUserMe();
  return (
    <Content
      onClick={() => {
        navigate(MENU.SIGN_OUT);
      }}
    >
      {!loading && me && (
        <Flex.Row alignItems="center">
          <Text
            mode={"Btn_M"}
            label={me?.siteName}
            style={{ marginRight: 10 }}
            color={Color.gray30}
          />
          <Image
            src={me.siteCiFile}
            width={36}
            height={36}
            style={{ borderRadius: "50%" }}
          />
          {/* <IconProfile /> */}
        </Flex.Row>
      )}
    </Content>
  );
};
export default UtilProfile;

const Content = styled.button``;
