import {create} from 'zustand';

type UseSstType = {
  sst: string | undefined;
  setSst: (name: string | undefined) => void;
};

export const useSst = create<UseSstType>((set) => ({
  sst: 'SST1',
  setSst: (name: string | undefined) => set({sst: name})
}));
