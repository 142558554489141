/**
 * @name 프린트디버그패널화면
 */
import styled from "styled-components";
import { AnyType } from "types";

// AnyType 대신 any 사용하거나, 보다 구체적인 타입을 정의할 수 있습니다.
interface DebugProps {
  data: AnyType;
}
export const debug = (data: AnyType): JSX.Element => {
  return <Debug data={data} />;
};
// 로그색상사용
const Debug: React.FC<DebugProps> = ({ data }) => {
  return (
    <Content>
      <pre className="wrapper">
        <p className="title">
          typeof1: <span>{typeof data}</span>
        </p>
        <div className="message">{JSON.stringify(data, null, 1)}</div>
      </pre>
    </Content>
  );
};
export default Debug;

//* --------------------------------------------------*
const Content = styled.section`
  margin: 10px auto;
  padding: 0 10px;
  border: 2px dotted #000;
  font-size: 14px;
  background: olive;
  box-shadow: 2px 3px 5px 1px rgba(0, 0, 0, 0.3);
  p.title {
    // margin-bottom: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }
  p.title span {
    color: #111;
    font-weight: bold;
    text-decoration: underline;
  }
  .wrapper {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    letter-spacing: -0.2px;
    .message {
      padding: 5px;
      font-size: 12px;
      line-height: 14px;
    }
    /* .message:hover {
      background: #5c5c01;
    } */
  }
  div.row {
    margin: 0;
    padding: 4px 20px;
    font-size: 12px;
    border-bottom: 1px solid #aaa;
    &:nth-child(2n-1) {
      background: #ccc;
    }
    &:nth-child(2n) {
      background: #eee;
    }
  }
  &.trace {
    padding: 0;
    border: 1px solid #111;
    background-color: #fff;
    box-shadow: none;
  }
`;
/**
  [시영밥]
  
      {debug(todoList)}
      <Debug data={todoList} />
 */
