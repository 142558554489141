/**
 * @name 버튼스타일
 * @description
 */
import styled, {css} from 'styled-components';
import {ButtonProps} from './type';
import {Color} from 'styles';

type ThemesType = {
  [key: string]: {
    //===배경
    bg?: string;
    hover_bg?: string;
    active_bg?: string;
    disabled_bg?: string;
    //===테두리
    border?: string;
    hover_border?: string;
    active_border?: string;
    disabled_border?: string;
    //===텍스트
    color?: string;
    hoverColor?: string;
    activeColor?: string;
    disabledColor?: string;
  };
};
/*============ [컬러값매핑] ======*/
const themeMapping = {Primary: 'blue', Danger: 'red', Warning: 'yellow', Success: 'green', Secondary: 'gray'};
/*============ [Filled] ======*/
export const typeFilled = (): ThemesType => {
  return Object.entries(themeMapping).reduce<ThemesType>((acc, [key, value]) => {
    acc[key] = {
      //bg
      bg: Color[`${value}30` as keyof typeof Color],
      hover_bg: Color[`${value}10` as keyof typeof Color],
      active_bg: Color[`${value}50` as keyof typeof Color],
      disabled_bg: Color[`${value}90` as keyof typeof Color],
      // color
      color: Color.white,
      hoverColor: Color.white,
      activeColor: Color.white,
      disabledColor: Color[`${value}70` as keyof typeof Color]
    };
    return acc;
  }, {});
};
/*============ [Outline] ======*/
export const typeOutline = (): ThemesType => {
  return Object.entries(themeMapping).reduce<ThemesType>((acc, [key, value]) => {
    acc[key] = {
      //bg
      bg: Color.transparent,
      hover_bg: Color[`${value}10` as keyof typeof Color],
      active_bg: Color[`${value}60` as keyof typeof Color],
      disabled_bg: Color[`${value}90` as keyof typeof Color],
      // color
      color: Color[`${value}30` as keyof typeof Color],
      hoverColor: Color.white,
      disabledColor: Color[`${value}70` as keyof typeof Color],
      // border
      border: Color[`${value}30` as keyof typeof Color],
      hover_border: Color.transparent,
      active_border: Color.transparent,
      disabled_border: Color[`${value}90` as keyof typeof Color]
    };
    return acc;
  }, {});
};
/*============ [Label] ======*/
export const typeLabel = (): ThemesType => {
  return Object.entries(themeMapping).reduce<ThemesType>((acc, [key, value]) => {
    acc[key] = {
      //bg
      bg: Color[`${value}70` as keyof typeof Color],
      hover_bg: Color[`${value}60` as keyof typeof Color],
      active_bg: Color[`${value}50` as keyof typeof Color],
      disabled_bg: Color[`${value}90` as keyof typeof Color],
      // color
      color: Color[`${value}30` as keyof typeof Color],
      hoverColor: Color[`${value}20` as keyof typeof Color],
      activeColor: Color[`${value}10` as keyof typeof Color],
      disabledColor: Color[`${value}70` as keyof typeof Color]
    };
    return acc;
  }, {});
};
/*============ [Text] ======*/
export const typeText = (): ThemesType => {
  return Object.entries(themeMapping).reduce<ThemesType>((acc, [key, value]) => {
    acc[key] = {
      //bg
      bg: Color.transparent,
      hover_bg: Color.transparent,
      active_bg: Color.transparent,
      disabled_bg: Color.transparent,
      // color
      color: Color[`${value}30` as keyof typeof Color],
      hoverColor: Color[`${value}10` as keyof typeof Color],
      activeColor: Color[`${value}50` as keyof typeof Color],
      disabledColor: Color[`${value}70` as keyof typeof Color]
    };
    return acc;
  }, {});
};
// 버튼 사이즈에 따른 스타일
export const sizeStyles = css<{size?: ButtonProps['size']}>`
  ${(props) => {
    switch (props.size) {
      case 'Large':
        return 'padding: 14px 26px; border-radius:8px; font-size: 16px;';
      case 'Default':
        return 'padding: 10px 20px; border-radius:6px; font-size: 14px;';
      case 'Small':
        return 'padding: 6px 14px; border-radius:4px; font-size: 12px;';
      default:
        return;
    }
  }}
`;
// 아이콘에 따른 스타일
export const IconImage = styled.img<{icon?: ButtonProps['icon']}>`
  ${(props) => {
    switch (props.icon) {
      case 'Left':
        return `
          padding-left: 10px; // 예시 값
          padding-right: 20px;
          border-radius: 8px;
        `;
      case 'Right':
        return `
          padding-left: 20px;
          padding-right: 10px;
          border-radius: 8px;
        `;
      case 'Only':
        return `
          display: block;
          margin: auto;
        `;
      default:
        return '';
    }
  }}
`;
// 버튼 타입에 따른 스타일
export const themeStyles = css<{type?: ButtonProps['type']; theme?: ButtonProps['theme']}>`
  ${(props) => {
    /* ============ Theme가없을때 ======* */
    if (Object.keys(props.theme).length === 0) return;
    //
    let thmes: ThemesType;
    if (props.type === 'Filled') thmes = typeFilled();
    else if (props.type === 'Outline') thmes = typeOutline();
    else if (props.type === 'Label') thmes = typeLabel();
    else if (props.type === 'Text') thmes = typeText();
    else return;
    const {
      bg,
      hover_bg,
      active_bg,
      disabled_bg,
      color,
      hoverColor,
      activeColor,
      border,
      hover_border,
      disabled_border,
      disabledColor
    } = thmes[props.theme];

    return css`
      color: ${color};
      /* border설정 */
      ${border && `border: 1px solid ${border};`}
      background-color: ${bg};
      transition: all 0.2s ease-in-out;

      /*-------------------------------------------------- MouseOver */
      &:hover {
        color: ${hoverColor};
        background-color: ${hover_bg};
        ${hover_border && `border: 1px solid ${hover_border};`}
      }
      /*-------------------------------------------------- MouseDown순간 */
      &:active {
        color: ${activeColor};
        background-color: ${active_bg};
      }
      /*-------------------------------------------------- 비활성화 */
      &:disabled {
        color: ${disabledColor};
        background-color: ${disabled_bg};
        cursor: not-allowed;
        ${disabled_border && `border: 1px solid ${disabled_border};`}
      }
    `;
  }}
`;
