/**
 * @name Gnb
 * @description
 */
import { create } from "zustand";

type GnbType = {
  isExpanded: boolean;
  setExpanded: (bool: boolean) => void;
};

export const useGnbStore = create<GnbType>((set) => ({
  //-------------------------------- GNB확장축소
  isExpanded: false,
  setExpanded: (bool: boolean) => set({ isExpanded: bool }),
}));
