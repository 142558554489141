/**
 * @name 값단위
 * @description
 */
import {Text} from 'components';
import {TextModeType} from 'components/text/type';
import {addComma} from 'lib/utils';
import styled from 'styled-components';
// types
import {Color} from 'styles';
import {CSS} from 'types';
// contents

type Props = {
  title?: string;
  value: number;
  valueMode?: TextModeType;
  valueColor?: string;
  unit?: string;
  style?: CSS;
  titleStyle?: CSS;
  valueStyle?: CSS;
  unitStyle?: CSS;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
};
const Contents = ({
  style,
  value,
  valueMode,
  title,
  valueColor,
  unit,
  titleStyle,
  valueStyle,
  unitStyle,
  minimumFractionDigits,
  maximumFractionDigits
}: Props): JSX.Element => {
  return (
    <Content style={style}>
      {/* 타이틀 */}
      {title && (
        <Text
          label={title}
          mode="Caption_SB"
          color={Color.gray50}
          style={Object.assign({}, {marginRight: 4}, titleStyle)}
        />
      )}
      {/* value */}
      {/* {value.toString()} */}
      <Text
        label={addComma({
          value: value,
          minimumFractionDigits: minimumFractionDigits,
          maximumFractionDigits: maximumFractionDigits
        })}
        style={Object.assign({}, {marginRight: 4}, valueStyle)}
        mode={valueMode || 'Headline_SB'}
        color={valueColor || Color.gray40}
      />
      {/* 단위 */}
      {unit && <Text label={unit} mode="Caption_SB" color={Color.gray50} style={Object.assign({}, unitStyle)} />}
    </Content>
  );
};

export default Contents;

const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: row;
  border-radius: 8px;
  background-color: ${Color.white};
`;
