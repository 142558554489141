/**
 * @name store
 * @description react-query, zustand
 */
import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {fetchData} from 'api';
import {productsList} from '../api';
// types
import {ProductsList} from '../api/type';
import {PostType} from '../model/type';

/**
 * @name react-query
 * @param currentPage
 * @returns
 */
export const useProductsListQuery = (): UseQueryResult<ProductsList> => {
  return useQuery<ProductsList>({
    queryKey: [guideQueryKey.productsList],
    queryFn: productsList
  });
};
export const usePostQuery = (currentPage: number): UseQueryResult<PostType[]> => {
  return useQuery({
    queryKey: [guideQueryKey.post, currentPage],
    queryFn: async (): Promise<PostType[]> => {
      const res: PostType[] = await fetchData<PostType[]>(
        `https://jsonplaceholder.typicode.com/posts?_page=${currentPage}&_limit=20`,
        {method: 'GET'}
      );
      return res;
    }
  });
};

export const usePostDetailQuery = (postId: number): UseQueryResult<PostType> => {
  return useQuery<PostType>({
    queryKey: [guideQueryKey.postDetail, postId],
    queryFn: () =>
      fetchData<PostType>(`https://jsonplaceholder.typicode.com/posts/${postId}`, {
        method: 'GET'
      })
  });
};

/**
 * @name react-query-key
 */
//---------------------------------------------
export const guideQueryKey = {
  productsList: 'guide/productsList',
  post: 'guide/useGuidePosts',
  postDetail: 'guide/useGuidePostDetail'
};
