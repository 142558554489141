import React from "react";
import { SVGProps } from "../type";

// 화살표다음
export const IconReload: React.FC<SVGProps> = ({
  width,
  height,
  fill,
}: SVGProps) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // eslint-disable-next-line max-len
        d="M19.2571 11.1043C19.3142 11.5146 19.693 11.8009 20.1032 11.7439C20.5135 11.6869 20.7999 11.3081 20.7429 10.8978L19.2571 11.1043ZM3.80768 8.71263C3.64838 9.09499 3.82922 9.53409 4.21158 9.69338C4.59394 9.85267 5.03303 9.67184 5.19232 9.28948L3.80768 8.71263ZM4.75 5C4.75 4.58579 4.41421 4.25 4 4.25C3.58579 4.25 3.25 4.58579 3.25 5H4.75ZM4 9H3.25C3.25 9.41421 3.58579 9.75 4 9.75V9ZM8 9.75C8.41421 9.75 8.75 9.41421 8.75 9C8.75 8.58579 8.41421 8.25 8 8.25V9.75ZM20.7429 10.8978C20.1936 6.94541 17.0672 3.84943 13.1096 3.33877L12.9177 4.82644C16.2045 5.25055 18.801 7.82178 19.2571 11.1043L20.7429 10.8978ZM13.1096 3.33877C9.15206 2.82812 5.34223 5.0291 3.80768 8.71263L5.19232 9.28948C6.46679 6.23027 9.63088 4.40234 12.9177 4.82644L13.1096 3.33877ZM3.25 5V9H4.75V5H3.25ZM4 9.75H8V8.25H4V9.75Z"
        fill={fill || "#373737"}
      />
      <path
        // eslint-disable-next-line max-len
        d="M4.74286 12.8968C4.68584 12.4865 4.30703 12.2001 3.89676 12.2571C3.48649 12.3142 3.20012 12.693 3.25714 13.1032L4.74286 12.8968ZM20.1923 15.2884C20.3516 14.9061 20.1708 14.467 19.7884 14.3077C19.4061 14.1484 18.967 14.3292 18.8077 14.7116L20.1923 15.2884ZM19.25 19C19.25 19.4142 19.5858 19.75 20 19.75C20.4142 19.75 20.75 19.4142 20.75 19H19.25ZM20 15H20.75C20.75 14.5858 20.4142 14.25 20 14.25V15ZM16 14.25C15.5858 14.25 15.25 14.5858 15.25 15C15.25 15.4142 15.5858 15.75 16 15.75V14.25ZM3.25714 13.1032C3.80641 17.0556 6.93276 20.1516 10.8904 20.6623L11.0823 19.1746C7.7955 18.7505 5.19904 16.1793 4.74286 12.8968L3.25714 13.1032ZM10.8904 20.6623C14.8479 21.1729 18.6578 18.972 20.1923 15.2884L18.8077 14.7116C17.5332 17.7708 14.3691 19.5987 11.0823 19.1746L10.8904 20.6623ZM20.75 19V15H19.25V19H20.75ZM20 14.25H16V15.75H20V14.25Z"
        fill={fill || "#373737"}
      />
    </svg>
  );
};
// 시간
export const IconTime: React.FC<SVGProps> = ({
  width,
  height,
  fill,
}: SVGProps) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Ic">
        <circle
          id="Oval"
          cx="8.5"
          cy="8"
          r="6"
          stroke="#8A8A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Path"
          d="M8.5 4.5V8.66699C8.5 8.66699 10.4793 8.66699 11 8.66699"
          stroke="#8A8A8A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
