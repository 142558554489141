import {useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';
import {Card} from '../common';
import {Props} from '../template_factory';
import Table from 'components/table/table';
import {sst2Data1, sst2Data2, sst2Data3} from 'pages/dashboard/constant/tableHeader';
import {styled} from 'styled-components';
import {Color} from 'styles';
import {Flex, Text} from 'components';
import {useSst} from 'context';

const Contents = ({item}: Props) => {
  // const {data} = useCardsCardNoQuery(item?.cardNo);
  const {sst} = useSst();

  const getColor: {[key: string]: string} = {
    정상운전: Color.green30,
    '히트싱크 과열': Color.red30,
    '저전압(정전)': Color.yellow30
  };

  const headers = [
    {label: '날짜'},
    {
      label: '상태',
      fn: (value: string) => {
        return (
          <Flex style={{paddingLeft: 30}} gap={10}>
            <div style={{color: getColor[value]}}>●</div>
            <Text label={value} size={12} weight={400} style={{lineHeight: '16px'}} />
          </Flex>
        );
      }
    }
  ];

  return (
    <Card rowSpan={1} title={item?.title}>
      <Content>
        {
          <Table
            headers={headers}
            data={(sst === 'SST1' ? sst2Data1 : sst === 'SST2' ? sst2Data2 : sst2Data3) as unknown as string[][]}
          />
        }
      </Content>
    </Card>
  );
};

export default Contents;

const Content = styled.article`
  overflow-y: auto;
  height: 230px;
`;
