import {Chart as ChartType, useCardsCardNoQuery} from 'pages/dashboard/hooks/useCard';
import {Card} from '../../../common';
import Summary from './summary';
import {Switch, Text} from 'components';
import {styled} from 'styled-components';
import {Color} from 'styles';
import {useSst} from 'context';
import {useEffect, useState} from 'react';
import {ValueUnit} from '../../../common/value-unit-list';
import {SST1, SST2, SST3} from '../../../../../constant/temp';
import Chart from './chart';

type Props = {
  cardNo: number;
};
const Contents = ({cardNo}: Props): JSX.Element => {
  // const {chart, valueUnit} = useCardsCardNoQuery(cardNo);
  const {sst} = useSst();
  const [valueUnit, setValueUnit] = useState<ValueUnit[]>();
  const [chartData, setChartData] = useState<ChartType[]>(SST1);

  useEffect(() => {
    setValueUnit([
      {
        label: 'SST',
        value: 0,
        unit: 'kWh'
      },
      {
        label: `${sst} OFF`,
        value: sst === 'SST1' ? 1979 : sst === 'SST2' ? 1231 : 3313,
        unit: 'kWh'
      },
      {
        label: 'SST OFF 전기료',
        value: sst === 'SST1' ? 275308 : sst === 'SST2' ? 154531 : 321674,
        unit: '원'
      },
      {
        label: `${sst} ON`,
        value: sst === 'SST1' ? 1919 : sst === 'SST2' ? 1156 : 3013,
        unit: 'kWh'
      },
      {
        label: 'SST ON 전기료',
        value: sst === 'SST1' ? 252281 : sst === 'SST2' ? 124253 : 303567,
        unit: '원'
      },
      {
        label: 'SST OFF 대비',
        value: sst === 'SST1' ? 1 : sst === 'SST2' ? 2 : 3,
        unit: '%'
      }
    ]);
    setChartData(sst === 'SST1' ? SST1 : sst === 'SST2' ? SST2 : SST3);
  }, [sst]);

  return (
    <Card
      rowSpan={2}
      title={'에너지 절감'}
      topRight={
        <TopRight>
          <Switch state={true} trueText={'SST ON'} falseText={'SST OFF'} />
        </TopRight>
      }>
      {/* 차트 */}
      {chartData && <Chart chart={chartData} />}
      <Line />
      {/* 레이블,하단 */}
      {valueUnit && <Summary valueUnit={valueUnit} />}
    </Card>
  );
};

export default Contents;

const TopRight = styled.div`
  display: flex;
  align-items: center;
  width: 160px;
  height: 100%;
  margin-right: 25px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: ${Color.gray70};
`;
