import {useEffect, useState} from 'react';
import {CardShadow} from 'pages/energy/common';
import {Echarts, Flex} from 'components';
import {ChartLegend} from 'pages/dashboard/view/main/common';
import {uuid} from 'lib/utils';
import {useEnergyQuery} from 'pages/energy/hooks';

export const EnergyType = () => {
  // 데이터 정보
  const {energyTypeGeneration} = useEnergyQuery();

  // 스타일 정보
  const style = [
    [3, 65, '#5D77FF', 130],
    [8.1, 56, '#00C978', 90],
    [5.75, 26.5, '#FF6644', 70]
  ];

  const [options, setOptions] = useState<echarts.EChartsOption>();

  useEffect(() => {
    if (energyTypeGeneration?.chart)
      setOptions({
        dataset: {
          source: [
            ['name', 'value', 'x', 'y', 'color', 'size'],
            ...style.map((item, i) => [
              energyTypeGeneration.chart[i].label,
              energyTypeGeneration.chart[i].value,
              ...item
            ])
          ]
        },
        grid: {top: 80, left: 20, right: 20, bottom: 20},
        series: [
          {
            type: 'scatter',
            encode: {
              x: 'x',
              y: 'y',
              label: 'name'
            },
            symbolSize: (data: any) => data[5],
            itemStyle: {
              color: (params: any) => params.value[4],
              opacity: 1
            },
            label: {
              show: true,
              formatter: (params: any) => {
                let richName;
                switch (params.dataIndex) {
                  case 0:
                    richName = 'large';
                    break;
                  case 1:
                    richName = 'medium';
                    break;
                  case 2:
                    richName = 'small';
                    break;
                  default:
                    richName = 'small'; // 기본 템플릿
                }
                return `{${richName}|${params.value[1]}}\n {unit|kWh} `;
              },
              position: 'inside',
              color: '#fff',
              rich: {
                large: {
                  fontSize: 28,
                  fontWeight: 700,
                  align: 'center'
                },
                medium: {
                  fontSize: 24,
                  fontWeight: 700,
                  align: 'center'
                },
                small: {
                  fontSize: 14,
                  fontWeight: 700,
                  align: 'center'
                },
                unit: {
                  align: 'center'
                }
              }
            }
          }
        ],
        xAxis: {
          show: false,
          max: 10
        },
        yAxis: {
          show: false,
          max: 70
        }
      });
  }, [energyTypeGeneration]);

  return (
    <CardShadow title={'에너지 별 발전량'} error={energyTypeGeneration?.type === 'error' || !energyTypeGeneration}>
      {options && (
        <Flex.Column>
          {/* 그래프 */}
          <Echarts options={options} style={{padding: '24px 24px 0'}} height={270} width={300} />

          {/* 범례 */}
          <Flex.Center style={{gap: 20}}>
            {energyTypeGeneration?.chart?.map((item, index) => {
              return <ChartLegend key={uuid()} color={style[index][2] as string} title={item.label} />;
            })}
          </Flex.Center>
        </Flex.Column>
      )}
    </CardShadow>
  );
};
