/**
 * @name 로그인(폼)
 * @description
 */
import {useEffect, useState} from 'react';
import {acelo} from 'assets/images';
import {Button, Flex, Image, Text, Input} from 'components';
import {API, MENU} from 'config';
import {useAxios, useObject, useRoutes} from 'lib/hooks';
import styled from 'styled-components';
import {Color} from 'styles';
import {eye_off, eye_on, IconError, sumiLogo} from 'assets/svg';
// contents
import Title from './login-title';
import {SESSION_STORAGE} from 'lib/const';
import {useAuthStore} from 'context';
import {useSearchParams} from 'react-router-dom';

const Contents: React.FC = () => {
  const {navigate} = useRoutes();
  const {set_siteId, set_accessToken, accessToken} = useAuthStore();
  const [isSoomy, setIsSoomy] = useState(false);
  const [isError, setIsError] = useState(false);
  const [visible, setVisible] = useState(false);
  const {state: signInState, update} = useObject({
    userId: '',
    password: ''
  });
  const {post} = useAxios(`${API}/auth/login`);

  async function login() {
    const res = await post(`${API}/auth/login`, signInState);
    if (res?.success) {
      sessionStorage.setItem(SESSION_STORAGE.TOKEN, res?.data?.accessToken);
      // state업데이트
      set_accessToken(res?.data?.accessToken);
      set_siteId(res?.data?.siteId);

      navigate(MENU.DASHBOARD);
    } else {
      setIsError(true);
    }
    // navigate(MENU.DASHBOARD);
  }

  // 토큰 체크
  async function checkToken() {
    const _token = await sessionStorage.getItem(SESSION_STORAGE.TOKEN);

    if (_token) {
      navigate(MENU.DASHBOARD);
    }
  }

  // useEffect
  useEffect(() => {
    if (!accessToken) checkToken();
  }, [accessToken]);

  useEffect(() => {
    const domain = window.location.hostname;
    domain.includes('soomy') && setIsSoomy(true);
  });

  return (
    <Content>
      {/* {Debug(loginState)} */}
      <Flex.Column className="wrap" style={{width: 340, marginLeft: 'auto', marginRight: 'auto'}}>
        {/* 로고 */}

        {isSoomy ? (
          <img src={sumiLogo} alt="signin_img" style={{width: 150, height: 80, marginBottom: 24}} />
        ) : (
          <img src={acelo} alt="signin_img" style={{width: 168, height: 40, marginBottom: 24}} />
        )}

        {/*============ 아이디 ======*/}
        {/* <h1>develop (develop)</h1> */}
        <Title title="아이디" />
        <Input
          name="userId"
          className="input"
          onChange={({event, name, value}) => update({[name]: value})}
          style={{marginBottom: 16}}
          placeholder="아이디를 입력해 주세요."
        />
        {/* 에러발생시메시지 */}
        {isError && (
          <Flex.Row style={{marginBottom: 16}}>
            <IconError />
            <Text mode="Caption_MB" label="존재하지 않는 아이디입니다." color={Color.red30} style={{marginLeft: 4}} />
          </Flex.Row>
        )}
        {/*============ 비밀번호 ======*/}
        <Title title="비밀번호" />
        <div className="password">
          <Input
            name="password"
            type={visible ? 'text' : 'password'}
            className="input"
            onChange={({name, value}) => update({[name]: value})}
            style={{marginBottom: 24}}
            placeholder="비밀번호를 입력해 주세요."
            onSubmit={() => login()}
          />
          <Button
            onClick={() => {
              setVisible(!visible);
            }}
            className="toggle"
            child={<Image src={visible ? eye_on : eye_off} alt="toggle" />}
          />
        </div>
        {/* 에러발생시메시지 */}
        {isError && (
          <Flex.Row style={{marginBottom: 16}}>
            <IconError />
            <Text mode="Caption_MB" label="잘못된 비밀번호 입니다." color={Color.red30} style={{marginLeft: 4}} />
          </Flex.Row>
        )}
        {/*============ 시작하기 ======*/}
        <Button
          disabled={!signInState.userId || !signInState.password}
          style={{width: '100%', marginBottom: 24}}
          theme="Primary"
          type="Filled"
          size="Large"
          label="시작하기"
          onClick={() => {
            login();
          }}
        />
        <Text mode="Caption_MR" color={Color.gray40}>
          로그인에 문제가 있다면 <a href="mailto:cs@crocusenergy.com">cs@crocusenergy.com</a>으로 문의하세요.
        </Text>
      </Flex.Column>
    </Content>
  );
};
export default Contents;

const Content = styled.div`
  display: flex;
  flex: 1;
  /*============ 비밀번호 ====== */
  .password {
    position: relative;
    width: 100%;
    .toggle {
      position: absolute;
      right: 16px;
      top: 22px;
      transform: translateY(-50%);
    }
  }
  .input {
    overflow: hidden;
    width: 100%;
    padding: 12px 16px;
    border-radius: 8px;
    border: 1px solid ${Color.gray50};
    color: ${Color.gray50};
  }
`;
