import React from "react";
import { SVGProps } from "../type";

// 닫기아이콘
export const IconClose: React.FC<SVGProps> = ({
  width,
  height,
  fill,
}: SVGProps) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke={fill || "#373737"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke={fill || "#373737"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

//-- 2024.05.02임시주석처리
// 느낌표
export const IconExclamation: React.FC<SVGProps> = ({
  width,
  height,
  fill,
}: SVGProps) => {
  return null;
  // return (
  //   <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  //     <g id="Icon_14">
  //       <circle id="Ellipse 133" cx="8" cy="8" r="8" fill="#D8D8D8" />
  //       <rect id="Rectangle 75" x="7" y="3" width="2" height="7" rx="1" fill="white" />
  //       <rect id="Rectangle 76" x="7" y="11" width="2" height="2" rx="1" fill="white" />
  //     </g>
  //   </svg>
  // );
};

// Error
export const IconError: React.FC<SVGProps> = ({
  width,
  height,
  fill,
}: SVGProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon_14">
        <circle id="Ellipse 133" cx="7" cy="7" r="7" fill="#FF4B4D" />
        <rect
          id="Rectangle 75"
          x="6.125"
          y="2.625"
          width="1.75"
          height="6.125"
          rx="0.875"
          fill="white"
        />
        <rect
          id="Rectangle 76"
          x="6.125"
          y="9.625"
          width="1.75"
          height="1.75"
          rx="0.875"
          fill="white"
        />
      </g>
    </svg>
  );
};
// 검색
export const IconSearch: React.FC<SVGProps> = ({
  width,
  height,
  fill,
}: SVGProps) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="7"
        stroke={fill || "#373737"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 21L15 15"
        stroke={fill || "#373737"}
        strokeWidth="1.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// 3선메뉴더보기
export const IconMore: React.FC<SVGProps> = ({
  width,
  height,
  fill,
}: SVGProps) => {
  return (
    <svg
      width={width || "24"}
      height={height || "36"}
      viewBox="0 0 24 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="12"
        cy="11"
        r="1"
        fill={fill || "#373737"}
        stroke={fill || "#373737"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="18"
        r="1"
        fill={fill || "#373737"}
        stroke={fill || "#373737"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12"
        cy="25"
        r="1"
        fill={fill || "#373737"}
        stroke={fill || "#373737"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

// 휴지통
export const IconDelete: React.FC<SVGProps> = ({
  width,
  height,
  fill,
}: SVGProps) => {
  return (
    <svg
      width={width || "24"}
      height={height || "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 7H20"
        stroke={fill || "#373737"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11V17"
        stroke={fill || "#373737"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 11V17"
        stroke={fill || "#373737"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 7L6 19C6 20.1046 6.89543 21 8 21H16C17.1046 21 18 20.1046 18 19L19 7"
        stroke={fill || "#373737"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 7V4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V7"
        stroke={fill || "#373737"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

/***
[사용법]
    <IconClose />
    <IconClose fill={Color.red10} />
    <IconClose fill={Color.blue10} />
 */
