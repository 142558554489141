import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { UserMe } from "./type";
import { fetchData } from "api";
import { ResponseType } from "types";
import { API } from "config";

export const Command = {
  userMe: "auth/user/me",
};
/**
 * @name 유저조회
 * @returns
 */
export const useUserMe = (): UseQueryResult<UserMe> => {
  return useQuery({
    queryKey: [Command.userMe],
    queryFn: async (): Promise<UserMe> => {
      const res: ResponseType<UserMe> = await fetchData<ResponseType<UserMe>>(
        `${API}/users/me`,
        { method: "GET" }
      );
      return res.data;
    },
  });
};
