/**
 * @name Echart
 * @returns
 */
import React from 'react';
import {App} from 'template';
// contents
import {Header, Main, Footer} from './views';
// Lazy load

const Index: React.FC = () => {
  return (
    <App>
      <Header />
      <Main />
      <Footer />
    </App>
  );
};
export default Index;
