/**
 * @name Util
 * @description
 */
import styled from "styled-components";
import { Flex } from "components";
// contents
import Weather from "./util-weather";
import Profile from "./util-profile";
import { desktop_max_width } from "styles";

const Util: React.FC = () => {
  return (
    <Content>
      <Flex.Row>
        {/* 날씨 */}
        <Weather />
        {/* 프로필 */}
        <Profile />
      </Flex.Row>
    </Content>
  );
};
export default Util;

const Content = styled.div`
  /* min-width: 210px; */
  @media all and (max-width: ${desktop_max_width}) {
    section {
      display: none;
    }
  }
`;
