import {useNavigate} from 'react-router-dom';
import {css, styled} from 'styled-components';
import {Color} from 'styles/color';

export type MenuProps = {
  name: string;
  isOpen?: boolean;
  isMenu?: boolean;
  icon?: string;
  route?: string;
  url?: string;
  onClick?: () => void;
  isRoute?: boolean;
};

const Contents = ({item}: {item: MenuProps}) => {
  const {name, isOpen, isMenu = true, icon, route, onClick, isRoute = false, url} = item;
  const navigate = useNavigate();

  return (
    <Wrapper
      $isMenu={isMenu}
      $isRoute={isRoute}
      onClick={() => {
        if (url) {
          window.open(url, '_blank', 'noopener,noreferrer');
        } else {
          route && navigate(route);
          onClick && onClick();
        }
      }}>
      {icon && <Icon src={icon} />}
      {isOpen && <Name $isMenu={isMenu}>{name}</Name>}
    </Wrapper>
  );
};

export default Contents;

const Wrapper = styled.div<{$isMenu: boolean; $isRoute: boolean}>`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 44px;
  gap: 4px;
  border-radius: 8px;
  cursor: pointer;
  ${({$isMenu}) =>
    $isMenu &&
    css`
      gap: 8px;
    `};
  ${({$isRoute}) =>
    $isRoute &&
    css`
      background-color: ${Color.blue90};
    `};
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const Name = styled.div<{$isMenu: boolean}>`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 13px;
  color: ${Color.gray60};
  ${({$isMenu}) =>
    $isMenu &&
    css`
      font-size: 17px;
      color: ${Color.black};
    `};
`;
